/* Component for rendering the view details in the requested and upcoming tab of the visits page */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  btn: {
    fontSize: "1rem",
    padding: theme.spacing(0.75, 4),
    height: "fit-content",
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

function ViewDetailsButton(props) {
  const { message, click } = props;
  const { btn } = useStyles();

  return (
    <Button className={btn} onClick={click}>
      {message || "View Details"}
    </Button>
  );
}

export default ViewDetailsButton;
