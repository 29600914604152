/* Component for rendering a caretaker's review */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import { RATING_PRECISION } from "Constants";

const useStyles = makeStyles(theme => ({
  img: {
    width: "50px",
    height: "50px",
    justifySelf: "center"
  },
  text: {
    textAlign: "center",
    fontSize: "0.8rem",
    maxWidth: "90%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  }
}));

function CaretakerReview(props) {
  const { avatar, name, rating, description } = props;
  const { img, text } = useStyles();
  return (
    <>
      <Avatar className={img} src={avatar} />
      <Box>
        <Typography className={text} color="secondary">
          {name}
        </Typography>
        <Box className={text}>
          <Rating value={rating} precision={RATING_PRECISION} size="small" readOnly />
        </Box>
        <Typography className={text} color="textSecondary">
          {description}
        </Typography>
      </Box>
    </>
  );
}

export default CaretakerReview;
