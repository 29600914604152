import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import { SEARCH_RESULTS_URL } from "Constants";

const MIN_CHAR_LIMIT = 3;

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4)
  },
  input: {
    color: theme.palette.text.secondary,
    borderRadius: "100px",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 2),
    width: "100%",
    maxWidth: "400px"
  },
  icon: {
    marginRight: theme.spacing(1),
    cursor: "pointer"
  },
  message: {
    display: "block",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  }
}));

function Searchbar(props) {
  const { history } = props;
  const { container, input, icon, message } = useStyles();

  const [error, setError] = useState("");
  const inputRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    const value = inputRef.current.value;
    if (value.replace(/\s/g, "").length < MIN_CHAR_LIMIT) {
      setError(`Please enter at least ${MIN_CHAR_LIMIT} characters`);
    } else {
      history.push(`${SEARCH_RESULTS_URL}?name=${value}`);
    }
  };

  const handleChange = () => setError("");

  return (
    <form className={container} onSubmit={handleSubmit}>
      <Input
        className={input}
        inputRef={inputRef}
        placeholder="Search"
        onChange={handleChange}
        disableUnderline
        startAdornment={<SearchIcon className={icon} color="disabled" onClick={handleSubmit} />}
      />
      <Typography className={message} variant="caption" color="error">
        {error}
      </Typography>
    </form>
  );
}

export default Searchbar;
