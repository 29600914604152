/* Component for rendering the address input in SearchCaregivers */

import React, { useMemo, useState, useEffect, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import debounce from "lodash/debounce";

const useStyles = makeStyles(theme => ({
  input: {
    color: theme.palette.text.secondary
  },
  addressOption: {
    display: "grid",
    gridTemplateColumns: "20px 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
    minHeight: "45px"
  }
}));

const DEBOUNCE_DELAY = 400;
const autocompleteService = { current: null };
const biasedLocation = { lat: () => 43.695392, lng: () => -79.403359 }; // Latitude and longitude of Toronto
const biasedRadius = 100000; // 100 km

function GeoAutocomplete(props) {
  const { error, change, mainClass, inputRef, defaultValue } = props;
  const { input, addressOption } = useStyles();

  const [address, setAddress] = useState("");
  const [options, setOptions] = useState([]);

  const call = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, DEBOUNCE_DELAY),
    []
  );

  // Fetch autocomplete results
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (address === "") {
      setOptions([]);
      return;
    }

    call({ input: address, location: biasedLocation, radius: biasedRadius }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => (active = false);
  }, [address, call]);

  const handleAddressChange = e => {
    setAddress(e.target.value);
    change();
  };

  return (
    <Autocomplete
      className={mainClass}
      getOptionLabel={option => (typeof option === "string" ? option : option.description)}
      autoComplete
      includeInputInList
      freeSolo
      defaultValue={defaultValue}
      filterOptions={x => x}
      options={options}
      noOptionsText="No addresses found"
      renderInput={({ InputProps, ...props }) => (
        <TextField
          {...props}
          fullWidth
          variant="outlined"
          label="Address of Care"
          error={error !== ""}
          helperText={error}
          onChange={handleAddressChange}
          InputProps={{ ...InputProps, className: input }}
          inputRef={inputRef}
        />
      )}
      renderOption={option => {
        const { main_text, secondary_text } = option.structured_formatting;

        return (
          <Box className={addressOption}>
            <LocationOnIcon color="secondary" />
            <Box>
              <Typography color="secondary">{main_text}</Typography>

              <Typography variant="body2" color="textSecondary">
                {secondary_text}
              </Typography>
            </Box>
          </Box>
        );
      }}
    />
  );
}

export default memo(
  React.forwardRef((props, ref) => <GeoAutocomplete inputRef={ref} {...props} />),
  (prev, next) => prev.error === next.error
);
