/* Component for rendering the dialog when the forgot password text is clicked */

//    forgot password
// @FormUrlEncoded
// @POST("users/forgetpwd")
// public Call<ResultPojo> forgotPassword(@Field("email") String email);

import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { useSnackbar } from "notistack";
import { FORGOT_PASSWORD_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4, 2, 2, 3),
    width: "100%",
    maxWidth: "400px",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 4, 2, 6)
    }
  },
  input: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  btn: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    marginLeft: "auto",
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

function ForgotPasswordDialog(props) {
  const { open, close } = props;
  const { container, input, btn } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const emailRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();

    const email = emailRef.current.value;
    const body = new FormData();
    body.set("email", email);

    fetch(FORGOT_PASSWORD_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          enqueueSnackbar(res.message, { variant: "error" });
        } else {
          enqueueSnackbar("Reset link sent to your email", { variant: "success" });
          close();
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
        close();
      });
  };

  return (
    <Dialog open={open} onClose={close} PaperProps={{ className: container, component: "form", onSubmit: handleSubmit }}>
      <Typography color="secondary">Enter Email</Typography>
      <Input className={input} placeholder="Email" required disableUnderline type="email" inputRef={emailRef} />

      <Button type="submit" className={btn}>
        Submit
      </Button>
    </Dialog>
  );
}

export default ForgotPasswordDialog;
