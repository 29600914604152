/* Component for rendering the head of the profile and edit profile page */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AvatarEdit from "components/AvatarEdit";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      margin: theme.spacing(0, 4),
      flexDirection: "row"
    }
  },
  img: {
    width: "75px",
    height: "75px",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
      width: "100px",
      height: "100px"
    }
  },
  text: {
    wordBreak: "break-word"
  },
  big_text: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem"
    }
  },
  account_text: {
    margin: theme.spacing(1, 0),
    fontWeight: 500
  },
  btn: {
    alignSelf: "center",
    fontSize: "0.8rem",
    padding: theme.spacing(0.75, 2),
    marginTop: theme.spacing(1),
    borderRadius: "500px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.bgcolor.main
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "flex-end",
      padding: theme.spacing(1.5, 4)
    }
  }
}));

function ProfileHead(props) {
  const { edit, click, isLovedOne, isRecipient, upload } = props;
  const { container, img, text, big_text, account_text, btn } = useStyles();

  const avatar = localStorage.getItem(`${isLovedOne ? "loved_" : ""}profile_pic`);
  const firstName = localStorage.getItem(`${isLovedOne ? "loved_" : ""}first_name`);
  const lastName = localStorage.getItem(`${isLovedOne ? "loved_" : ""}last_name`);

  return (
    <Box className={container}>
      {edit ? <AvatarEdit imgClass={img} src={avatar} onChange={upload} /> : <Avatar className={img} src={avatar} />}
      <Box flexGrow={1}>
        <Typography color="secondary" className={`${isRecipient ? "" : big_text} ${text} ${account_text}`}>
          {isRecipient ? "Care Recipient" : "My Account"}
        </Typography>
        <Typography color="textSecondary" className={`${isRecipient ? "" : big_text} ${text}`}>
          {firstName} {lastName}
        </Typography>
      </Box>
      <Button variant="contained" className={btn} onClick={click}>
        {edit ? "BACK" : "EDIT"}
      </Button>
    </Box>
  );
}

export default ProfileHead;
