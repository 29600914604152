/* Component for rendering the dialog when the user schedules care */

import React, { memo } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CAREGIVER_URL } from "Constants";
import CalendarIcon from "assets/calendar-icon.svg";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 8)
    }
  },
  main_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(2, 6),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  panel1: {
    display: "grid",
    gridTemplateColumns: "1fr",
    marginBottom: theme.spacing(1),
    gridGap: theme.spacing(1),
    justifyItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "40px 1fr",
      gridGap: theme.spacing(3),
      justifyItems: "initial",
      textAlign: "left"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0
    }
  },
  panel2: {
    display: "grid",
    gridTemplateRows: "auto",
    justifyItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      gridTemplateRows: "2fr 1fr",
      justifyItems: "initial",
      textAlign: "left"
    }
  },
  title: {
    fontWeight: 500
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 500
  },
  img: {
    alignSelf: "center",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      alignSelf: "initial"
    }
  },
  btn: {
    marginTop: theme.spacing(0.5),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.bgcolor.main
    }
  },
  cancel_box: {
    display: "grid",
    width: "fit-content",
    margin: `${theme.spacing(1)}px auto`,
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "initial"
    }
  },
  ok_btn: {
    marginTop: theme.spacing(0.5),
    width: "100px",
    justifySelf: "center",
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up("md")]: {
      gridColumnStart: "2",
      justifySelf: "right"
    }
  }
}));

function CareRequestDialog(props) {
  const { data, close, open, cancel } = props;
  const { id, note, name, avatar, address, province, city, zip, street, apartment, date, start, end } = data;
  const { container, main_grid, panel1, panel2, title, label, img, btn, cancel_box, ok_btn } = useStyles();

  const handleCancel = () => {
    cancel(data.appointmentId);
    close();
  };

  if (!open) {
    return <></>;
  }
  return (
    <Dialog open={true} onClose={close} PaperProps={{ className: container }} maxWidth="md">
      <Typography variant="h6" color="secondary" className={title} gutterBottom>
        Your Care Visit has been requested!
      </Typography>

      <Box className={main_grid}>
        <Box className={panel1}>
          <img src={CalendarIcon} className={img} style={{ width: "33px" }} alt="Date" />

          <Box>
            <Typography color="textSecondary">{date.toDateString()}</Typography>
            <Typography color="textSecondary">{`${start} - ${end}`}</Typography>
          </Box>

          <RoomOutlinedIcon color="primary" className={img} style={{ width: "40px", height: "40px" }} />

          <Box>
            <Typography className={label} color="secondary">
              {address}
            </Typography>
            <Typography color="textSecondary">
              {street}, {apartment ? `${apartment},` : ""}
            </Typography>
            <Typography color="textSecondary">{`${city}, ${province}, ${zip}`}</Typography>
          </Box>

          <Avatar src={avatar} className={img} />

          <Box>
            <Typography color="secondary" className={label}>
              Your Caregiver
            </Typography>
            <Typography color="textSecondary">{name}</Typography>
            <Box>
              <Link to={`${CAREGIVER_URL}/${id}`} style={{ textDecoration: "none" }}>
                <Button className={btn} startIcon={<PersonOutlineOutlinedIcon />}>
                  View Profile
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box className={panel2}>
          <Box>
            <Typography color="secondary" className={label}>
              Special Instructions
            </Typography>
            <Typography color="textSecondary" variant="body2" gutterBottom>{`${
              note.length === 0 ? "No instruction provided" : note
            }`}</Typography>
          </Box>

          <Box>
            <Typography color="secondary" variant="body2" className={label}>
              Please wait for confirmation from your Caregiver
            </Typography>
            <Box className={cancel_box}>
              <CancelOutlinedIcon color="primary" style={{ cursor: "pointer" }} fontSize="small" onClick={handleCancel} />
              <Typography color="textSecondary" variant="body2">
                Cancel Request
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button className={ok_btn} onClick={close}>
          OK
        </Button>
      </Box>
    </Dialog>
  );
}

export default memo(CareRequestDialog, (prev, next) => prev.data === next.data && prev.open === next.open);
