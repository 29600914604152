export const LOGIN_URL = "/login";
export const SIGNUP_URL = "/signup";
export const DASHBOARD_URL = "/dashboard";
export const SEARCH_RESULTS_URL = DASHBOARD_URL + "/results";
export const CAREGIVER_URL = "/caregiver";
export const MY_CAREGIVERS_URL = "/mycaregivers";
export const PAYMENT_URL = "/payment";
export const SCHEDULE_URL = "/schedule";
export const VISITS_URL = "/visits";
export const CHAT_URL = "/chat";
export const ADD_USER_TO_QB_URL = "/addchat";
export const PROFILE_URL = "/account";
export const CARE_RECIPIENT_URL = "/recipient";
export const NOTIFICATIONS_URL = "/notifications";
export const REFERRALS_URL = "/referrals";
export const POST_JOB_URL = "/postjob";
export const FAQ_URL = "/faq";
export const TOS_URL = "/terms";
export const POLICY_URL = "/policy";

export const MIN_PASSWORD_LENGTH = 6;
export const DAY_IN_MS = 1000 * 3600 * 24;
export const MAX_TIME_RANGE = 24; // 12 hours * 2 slots per hour
export const RATING_PRECISION = 0.5;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const REQUESTED_STATUS = 0;
export const UPCOMING_STATUS = 1;
export const COMPLETED_STATUS = 2;
export const CANCELLED_STATUS = 3;

export const QB = window.QB;
export const QB_ID = process.env.REACT_APP_QB_ID;
export const QB_KEY = process.env.REACT_APP_QB_KEY;
export const QB_SECRET = process.env.REACT_APP_QB_SECRET;
export const QB_PASSWORD = "test@qbuser123";
export const CHATS_PER_FETCH = 25;
export const CARETAKER_FLAG = 1;

const timeSlots = [];
timeSlots.push("12:00 AM", "12:30 AM");
for (let i = 2, even = true; i < 24; i++, even = !even) {
  timeSlots.push(`${String(i >> 1).padStart(2, 0)}:${even ? "00" : "30"} AM`);
}
timeSlots.push("12:00 PM", "12:30 PM");
for (let i = 2, even = true; i < 24; i++, even = !even) {
  timeSlots.push(`${String(i >> 1).padStart(2, 0)}:${even ? "00" : "30"} PM`);
}
export const TIME_SLOTS = timeSlots;
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Convert a time string like 13:00:00 to 1:00 PM
export function getTimeString(time) {
  time = time.split(":");
  let hour = Number(time[0]);
  const meridian = hour >= 12 ? "PM" : "AM";
  hour = hour === 0 || hour === 12 ? 12 : hour % 12;
  const minute = time[1];
  return `${hour}:${minute} ${meridian}`;
}

// Convert a date string like 1965-12-21 to December 21,1965
export function getDateString(date) {
  const [year, month, day] = date.split("-");
  return `${MONTHS[Number(month) - 1] || 0} ${Number(day)},${year}`;
}

// Make a Promise out of a chat history request
export function getChatHistoryPromise(params, id) {
  return new Promise((resolve, reject) => {
    QB.chat.message.list(params, (error, result) => {
      if (error) {
        reject(error);
      } else if (typeof result !== "object") {
        reject(result);
      } else {
        result.id = id;
        resolve(result);
      }
    });
  });
}
