/* Component for rendering the avatar and the upload UI in the edit section of the profile pages*/

import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  btn: {
    position: "absolute",
    padding: theme.spacing(0.5),
    bottom: theme.spacing(-0.5),
    right: theme.spacing(1.5)
  }
}));

function AvatarEdit(props) {
  const { src, imgClass, onChange } = props;
  const { container, btn } = useStyles();

  const [img, setImg] = useState(null);

  const fileRef = useRef();

  const handleClick = () => fileRef.current.click();

  const handleChange = () => {
    const file = fileRef.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = read => {
        setImg(read.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImg(null);
    }
    onChange(file);
  };

  return (
    <div className={container}>
      <Avatar src={img || src} className={imgClass} />
      <IconButton className={btn} color="secondary" onClick={handleClick}>
        <PhotoCameraIcon />
      </IconButton>
      <input type="file" hidden ref={fileRef} onChange={handleChange} />
    </div>
  );
}

export default AvatarEdit;
