import React, { useEffect, useState, useCallback } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainNavbar from "components/MainNavbar";
import {
  LOGIN_URL,
  DASHBOARD_URL,
  CAREGIVER_URL,
  MY_CAREGIVERS_URL,
  PAYMENT_URL,
  SCHEDULE_URL,
  VISITS_URL,
  CHAT_URL,
  ADD_USER_TO_QB_URL,
  PROFILE_URL,
  CARE_RECIPIENT_URL,
  NOTIFICATIONS_URL,
  REFERRALS_URL,
  POST_JOB_URL,
  FAQ_URL,
  POLICY_URL,
  TOS_URL,
  QB,
  QB_ID,
  QB_KEY,
  QB_SECRET,
  QB_PASSWORD,
  CARETAKER_FLAG,
  getChatHistoryPromise
} from "Constants";
import { NOTIFICATIONS_ROUTE, GET_CHATS_ROUTE } from "Routes";
import DashboardPage from "pages/SearchCaregivers";
import CaregiverPage from "pages/IndividualCaregiver";
import MyCaregiversPage from "pages/MyCaregivers";
import PaymentPage from "pages/PaymentInfo";
import ScheduleCarePage from "pages/ScheduleCare";
import MyVisitsPage from "pages/MyVisits";
import ChatPage from "pages/MyChats";
import QBPage from "pages/AddQBUsers";
import ProfilePage from "pages/Profile";
import CareRecipientPage from "pages/CareRecipient";
import NotificationsPage from "pages/Notifications";
import ReferralsPage from "pages/Referrals";
import PostJobPage from "pages/PostJob";
import StaticPages from "pages/StaticPages";
import FAQPage from "pages/FAQ";
import PolicyPage from "pages/Policy";
import TermsPage from "pages/Terms";

function MainPages(props) {
  const [navbarFields, setNavbarFields] = useState({
    name: `${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`,
    avatar: localStorage.getItem("profile_pic")
  });
  const [badgeNum, setBadgeNum] = useState(null);
  const [showChatBadge, setshowChatBadge] = useState(false);

  const path = window.location.pathname;
  const isLoggedOut = !localStorage.getItem("id") || !localStorage.getItem("authKey");

  const updateNavbarFields = () =>
    setNavbarFields({
      name: `${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`,
      avatar: localStorage.getItem("profile_pic")
    });

  const updateBadge = num => setBadgeNum(num);

  const initializeQB = (active, callback) => {
    QB.init(QB_ID, QB_KEY, QB_SECRET);
    QB.createSession({ login: localStorage.getItem("email"), password: QB_PASSWORD }, (err, res) => {
      if (err || !res || !active) {
        // User is not in chat system yet
        QB.createSession((err, res) => {
          if (err || !res) {
            return;
          }
          QB.users.create({ login: localStorage.getItem("email"), password: QB_PASSWORD }, (err, res) => {
            if (err || !res) {
              return;
            }
            callback(res.id);
          });
        });
      } else {
        callback(res.user_id);
      }
    });
  };

  const subToQBChat = useCallback(
    (active, callback) => {
      if (path === CHAT_URL || path === ADD_USER_TO_QB_URL) {
        return;
      }
      initializeQB(active, userId => {
        QB.chat.connect({ userId, password: QB_PASSWORD }, err => {
          if (err) {
            return;
          }
          if (active) {
            QB.chat.onMessageListener = () => {
              setshowChatBadge(true);
            };
            if (callback) {
              callback(userId);
            }
          }
        });
      });
    },
    [path]
  );

  // Subscribe to QB chat
  useEffect(() => {
    if (isLoggedOut || path === CHAT_URL || path === ADD_USER_TO_QB_URL) {
      return;
    }

    let active = true;
    subToQBChat(active, userId => {
      // Fetch user dialogs and check if the most recent message was read by the user
      const body = new FormData();
      body.set("uid", localStorage.getItem("id"));
      body.set("auth_key", localStorage.getItem("authKey"));
      body.set("user_id", localStorage.getItem("id"));
      body.set("flag", CARETAKER_FLAG);

      fetch(GET_CHATS_ROUTE, { method: "POST", body })
        .then(res => res.json())
        .then(res => {
          if (active && res.status !== "Fail") {
            const promises = [];

            res.data.forEach(({ TblDailog: { dialog_id } }) => {
              const params = { chat_dialog_id: dialog_id, sort_desc: "date_sent", limit: 1, skip: 0 };
              promises.push(getChatHistoryPromise(params, dialog_id));
            });

            Promise.all(promises).then(results => {
              if (!active) {
                return;
              }

              for (const result of results) {
                if (result.sender_id !== userId && result.items[0] && !result.items[0].read_ids.includes(userId)) {
                  setshowChatBadge(true);
                  break;
                }
              }
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    });

    return () => {
      active = false;
    };
  }, [path, isLoggedOut, subToQBChat]);

  // Unsubscribe from QB
  useEffect(() => {
    return () => {
      if (QB && QB.chat && QB.chat.isConnected) {
        QB.chat.disconnect();
        QB.destroySession(error => (error ? console.log(error) : null));
      }
    };
  }, []);

  // Fetch notifications
  useEffect(() => {
    if (isLoggedOut) {
      return;
    }

    let active = true;
    const headers = { Uid: localStorage.getItem("id"), Authkey: localStorage.getItem("authKey") };
    fetch(`${NOTIFICATIONS_ROUTE}/${localStorage.getItem("id")}`, { headers })
      .then(res => {
        // If unauthorized, clear local storage and force a refresh which will redirect to login
        if (res.status === 401) {
          localStorage.clear();
          window.location.reload();
          active = false;
          return res;
        }
        return res.json();
      })
      .then(res => active && res.status !== "Fail" && setBadgeNum(res.data.length))
      .catch(err => console.error(err));

    return () => (active = false);
  });

  if (isLoggedOut) {
    localStorage.clear();
    return (
      <Switch>
        <Route path={[FAQ_URL, POLICY_URL, TOS_URL]} component={StaticPages} />
        <Route path="*" render={() => <Redirect to={LOGIN_URL} />} />
      </Switch>
    );
  }

  return (
    <>
      <MainNavbar
        fields={navbarFields}
        history={props.history}
        path={path}
        badgeNum={badgeNum}
        showChatBadge={showChatBadge}
      />
      <Switch>
        <Route path={DASHBOARD_URL} component={DashboardPage} />
        <Route path={`${CAREGIVER_URL}/:id`} component={CaregiverPage} />
        <Route path={MY_CAREGIVERS_URL} component={MyCaregiversPage} />
        <Route path={PAYMENT_URL} component={PaymentPage} />
        <Route path={SCHEDULE_URL} component={ScheduleCarePage} />
        <Route path={VISITS_URL} component={MyVisitsPage} />
        <Route
          path={CHAT_URL}
          render={() => <ChatPage onMount={() => setshowChatBadge(false)} onUnMount={() => subToQBChat(true)} />}
        />
        <Route path={ADD_USER_TO_QB_URL} component={QBPage} />
        <Route path={PROFILE_URL} render={() => <ProfilePage update={updateNavbarFields} />} />
        <Route path={CARE_RECIPIENT_URL} render={props => <CareRecipientPage update={updateNavbarFields} {...props} />} />
        <Route path={NOTIFICATIONS_URL} render={() => <NotificationsPage update={updateBadge} />} />
        <Route path={REFERRALS_URL} component={ReferralsPage} />
        <Route path={POST_JOB_URL} component={PostJobPage} />
        <Route path={FAQ_URL} component={FAQPage} />
        <Route path={POLICY_URL} component={PolicyPage} />
        <Route path={TOS_URL} component={TermsPage} />
        <Route path="*" render={() => <Redirect to={LOGIN_URL} />} />
      </Switch>
    </>
  );
}

export default MainPages;
