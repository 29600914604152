import React from "react";
import { Redirect } from "react-router-dom";
import AccountNavbar from "components/AccountNavbar";
import AccountBody from "components/AccountBody";
import LoginForm from "components/LoginForm";
import { DASHBOARD_URL } from "Constants";

function Login(props) {
  if (localStorage.getItem("id") && localStorage.getItem("authKey")) {
    return <Redirect to={DASHBOARD_URL}></Redirect>;
  }
  return (
    <>
      <AccountNavbar accountMsg="Don't have an account?" linkMsg="Sign Up" link="/signup"></AccountNavbar>
      <AccountBody>
        <LoginForm history={props.history} />
      </AccountBody>
    </>
  );
}

export default Login;
