import React from "react";
import { Switch, Route } from "react-router-dom";
import { FAQ_URL, POLICY_URL, TOS_URL } from "Constants";
import FAQPage from "pages/FAQ";
import PolicyPage from "pages/Policy";
import TermsPage from "pages/Terms";

function StaticPages() {
  return (

    <div style={{ marginTop: "10vh" }}>
      <Switch>
        <Route path={FAQ_URL} component={FAQPage} />
        <Route path={POLICY_URL} component={PolicyPage} />
        <Route path={TOS_URL} component={TermsPage} />
      </Switch>
    </div>
  );
}

export default StaticPages;
