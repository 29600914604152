/* component for rendering the body of the login and signup page */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import HomeCare from "assets/homecare.png";

const useStyles = makeStyles(theme => ({
  container: {
    width: "95%",
    margin: `${theme.spacing(2)}px auto 0`,
    display: "flex",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      marginTop: theme.spacing(4)
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
      maxWidth: "900px"
    }
  },
  panel1: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      maxWidth: "500px"
    }
  },
  panel2: {
    display: "none",
    backgroundColor: "#E6F7FF",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  img: {
    alignSelf: "flex-end",
    maxHeight: "450px"
  }
}));

function AccountBody(props) {
  const { container, panel1, panel2, img } = useStyles();
  return (
    <Paper className={container} elevation={2}>
      <div className={panel1}>{props.children}</div>
      <div className={panel2}>
        <img src={HomeCare} className={img} alt="Home Care" />
      </div>
    </Paper>
  );
}

export default AccountBody;
