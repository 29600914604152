import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PaymentForm from "components/PaymentForm";
import { STRIPE_PUBLIC_KEY } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "1200px",
    margin: "auto",
    paddingBottom: theme.spacing(2)
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      textAlign: "left"
    }
  }
}));

function PaymentInfo(props) {
  const { history } = props;
  const { container, header } = useStyles();

  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  return (
    <Box className={container}>
      <Typography color="secondary" className={header}>
        Payment Information
      </Typography>
      <Elements stripe={stripePromise}>
        <PaymentForm history={history} />
      </Elements>
    </Box>
  );
}

export default PaymentInfo;
