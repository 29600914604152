/* Component for rendering the details (avatar, name, rating, etc) of a caregiver */

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import { RATING_PRECISION, CAREGIVER_URL } from "Constants";

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: "center",
    maxWidth: "90%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "none",
      textAlign: "left"
    }
  },
  details_text: {
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  caregiver_text: {
    fontSize: "0.7rem",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem"
    }
  },
  link: {
    textDecoration: "none",
    justifySelf: "center"
  },
  img: {
    width: "50px",
    height: "50px"
  }
}));

function CaregiverDetails(props) {
  const { id, avatar, name, rating, description } = props;
  const { text, details_text, caregiver_text, link, img } = useStyles();
  return (
    <>
      <Link className={link} to={`${CAREGIVER_URL}/${id}`}>
        <Avatar className={img} src={avatar} />
      </Link>
      <Box>
        <Typography variant="body2" className={`${text} ${caregiver_text}`} color="secondary">
          Caregiver
        </Typography>
        <Typography className={`${text} ${details_text}`} color={"textSecondary"}>
          {name}
        </Typography>
        <Box className={`${text} ${details_text}`}>
          <Rating value={rating} precision={RATING_PRECISION} size="small" readOnly />
        </Box>
        <Typography className={`${text} ${details_text}`} color="textSecondary">
          {description}
        </Typography>
      </Box>
    </>
  );
}

export default CaregiverDetails;
