import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { TOS_URL, POLICY_URL, SCHEDULE_URL } from "Constants";
import { PAYMENT_ROUTE } from "Routes";
import CreditCards from "assets/credit-card.svg";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4, "auto", 0),
    maxWidth: "700px",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 8)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 12)
    }
  },
  title: {
    flexGrow: 1,
    fontWeight: 600
  },
  input_grid: {
    display: "grid",
    margin: theme.spacing(2, 0),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr"
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 200px 150px"
    }
  },
  input_paper: {
    padding: theme.spacing(2)
  },
  label: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: "0.8rem"
  },
  name_box: {
    [theme.breakpoints.up("sm")]: {
      gridColumn: "span 2"
    },
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 1"
    }
  },
  name_paper: {
    padding: theme.spacing(1.5, 2, 2)
  },
  name_input: {
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: "0.9rem",
    padding: 0,
    color: theme.typography.body1.color
  },
  text: {
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  img: {
    width: "75px",
    [theme.breakpoints.up("sm")]: {
      width: "100px"
    }
  },
  btn: {
    display: "block",
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    margin: `${theme.spacing(3)}px auto 0`,
    padding: theme.spacing(1, 8),
    borderRadius: theme.spacing(1),
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  }
}));

function PaymentForm(props) {
  const { history } = props;
  const {
    paper,
    title,
    input_grid,
    input_paper,
    label,
    name_box,
    name_paper,
    name_input,
    text,
    success,
    error,
    img,
    btn
  } = useStyles();

  const [helper, setHelper] = useState({});
  const nameRef = useRef();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const res = await stripe.createToken(cardElement);
    if (res.error) {
      setHelper({ msg: res.error.message, className: error });
      return;
    }
    const token = res.token;
    const body = new FormData();
    body.set("id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("token", token.id);
    body.set("name", nameRef.current.value);

    fetch(PAYMENT_ROUTE, { method: "POST", body })
      .then(res => {
        if (!res.ok) {
          setHelper({ msg: "Something went wrong. Please try again.", className: error });
        } else {
          res.json().then(res => {
            if (res.status === "Fail") {
              setHelper({ msg: "Something went wrong. Please try again.", className: error });
            } else {
              localStorage.setItem("token", "pending"); // Backend doesn't return the token
              if (new URLSearchParams(window.location.search).get("date")) {
                history.push(`${SCHEDULE_URL}${window.location.search}`);
              } else {
                setHelper({ msg: "Payment Information Saved", className: success });
              }
            }
          });
        }
      })
      .catch(err => console.error(err));
  };

  return (
    <Paper className={paper} component="form" onSubmit={handleSubmit} elevation={2}>
      <Box display="flex">
        <Typography color="secondary" className={title}>
          Credit/Debit Card
        </Typography>
        <img className={img} src={CreditCards} alt="Valid Cards" />
      </Box>

      <Typography color="textSecondary" gutterBottom className={text}>
        By continuing, you add this payment method to your Vidal Home Care account and agree to the{" "}
        <Link to={TOS_URL} style={{ textDecoration: "none" }}>
          Terms Of Service
        </Link>{" "}
        and{" "}
        <Link to={POLICY_URL} style={{ textDecoration: "none" }}>
          Privacy Page
        </Link>
        .
      </Typography>

      <label className={label} htmlFor="card-number">
        CARD NUMBER
      </label>
      <Paper className={input_paper}>
        <CardNumberElement id="card-number" />
      </Paper>

      <Box className={input_grid}>
        <Box className={name_box}>
          <label className={label} htmlFor="card-name">
            NAME ON CARD
          </label>
          <Paper className={name_paper}>
            <input className={name_input} id="card-name" ref={nameRef} autoComplete="off" />
          </Paper>
        </Box>

        <Box>
          <label className={label} htmlFor="card-expiry">
            EXPIRY DATE
          </label>
          <Paper className={input_paper}>
            <CardExpiryElement id="card-expiry" />
          </Paper>
        </Box>

        <Box>
          <label className={label} htmlFor="card-cvv">
            CVV CODE
          </label>
          <Paper className={input_paper}>
            <CardCvcElement options={{ placeholder: "" }} id="card-cvv" />
          </Paper>
        </Box>
      </Box>

      <Typography className={helper.className} variant="h6" align="center">
        {helper.msg}
      </Typography>

      <Button className={btn} type="submit" disabled={!stripe}>
        Save
      </Button>
    </Paper>
  );
}

export default PaymentForm;
