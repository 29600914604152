/* Component for rendering a single caregiver in the my caregiver page */

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CaregiverDetails from "./CaregiverDetails";
import { SCHEDULE_URL, ADD_USER_TO_QB_URL } from "Constants";

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(3, 1),
    margin: theme.spacing(2, 0, 4),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 4)
    }
  },
  details: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "50px 1fr"
    }
  },
  btn_container: {
    display: "grid",
    marginTop: theme.spacing(2),
    gridTemplateRows: "1fr 1fr",
    justifyItems: "center",
    gridRowGap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "block",
      width: "fit-content",
      marginLeft: "auto"
    }
  },
  chat_btn: {
    padding: theme.spacing(0.5, 8),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2)
    }
  },
  request_btn: {
    padding: theme.spacing(0.5, 2),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  }
}));

function MyCaregiver(props) {
  const { id, name, description, avatar, rating } = props;
  const { card, details, btn_container, chat_btn, request_btn } = useStyles();

  return (
    <Paper className={card} elevation={2}>
      <Box className={details}>
        <CaregiverDetails id={id} name={name} description={description} avatar={avatar} rating={rating} />
      </Box>
      <Box className={btn_container}>
        <Link to={`${ADD_USER_TO_QB_URL}?cgid=${id}`} style={{ textDecoration: "none" }}>
          <Button className={chat_btn}>Chat</Button>
        </Link>
        <Link to={`${SCHEDULE_URL}?cgid=${id}`} style={{ textDecoration: "none" }}>
          <Button className={request_btn}>Request Care Now!</Button>
        </Link>
      </Box>
    </Paper>
  );
}

export default MyCaregiver;
