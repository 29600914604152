import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccountNavbar from "components/AccountNavbar";
import AccountBody from "components/AccountBody";
import SignUpForm from "components/SignupForm";

function Signup(props) {
  return (
    <>
      <AccountNavbar accountMsg="Already have an account?" linkMsg="Log in" link="/login"></AccountNavbar>
      <AccountBody>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SignUpForm history={props.history} />
        </MuiPickersUtilsProvider>
      </AccountBody>
    </>
  );
}

export default Signup;
