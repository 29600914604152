/* Component for rendering the list of caregivers in the search results page */

import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Caregiver from "components/Caregiver";

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const useStyles = makeStyles(theme => ({
  text: {
    fontSize: "1rem",
    textAlign: "center",
    margin: theme.spacing(2, 0, 1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
      textAlign: "left"
    }
  },
  day_container: {
    marginBottom: theme.spacing(6),
    "&:last-child": {
      marginBottom: 0
    }
  }
}));

function CaregiverList(props) {
  const { data, days, select } = props;
  const { text, day_container } = useStyles();

  const availabilityMap = {}; // Map day index to caregiver ids
  const caregivers = {}; // Map id to caregiver component

  days.forEach((day, index) => {
    if (day) {
      availabilityMap[index] = [];
    }
  });

  data.forEach(({ id, rating, name, availability, description, profile_pic }) => {
    availability.forEach(index => availabilityMap[(index + 6) % 7].push(id));
    caregivers[id] = (
      <Caregiver
        key={id}
        id={id}
        rating={rating}
        name={name}
        description={description}
        profile_pic={profile_pic}
        select={() => select(id)}
      />
    );
  });

  let emptyDays = [];
  let emptyDayPrefix = "";
  if (data.length !== 0) {
    Object.entries(availabilityMap).forEach(([dayIndex, ids]) => {
      if (ids.length === 0) {
        emptyDays.push(DAYS[dayIndex]);
      }
    });
  }

  if (emptyDays.length === 0) {
    emptyDays = "";
  } else if (emptyDays.length <= 2) {
    emptyDays = emptyDays.join(" or ");
    emptyDayPrefix = "There are no Caregivers available for ";
  } else {
    emptyDays[emptyDays.length - 1] = `or ${emptyDays[emptyDays.length - 1]}`;
    emptyDays = emptyDays.join(", ");
    emptyDayPrefix = "There are no Caregivers available for: ";
  }

  return (
    <>
      <Typography color="textSecondary" variant="body1" className={text}>
        {emptyDayPrefix}
        <b>{emptyDays}</b>
      </Typography>
      {data.length !== 0 && (
        <Box>
          <Typography color="textSecondary" variant="body1" className={text}>
            Here are the Caregivers who match your criteria in the order closest to the location of care.
          </Typography>
          {Object.entries(availabilityMap).map(([dayIndex, ids]) => {
            return (
              ids.length !== 0 && (
                <Box key={dayIndex} className={day_container}>
                  <Typography color="textSecondary" variant="body1" className={text}>
                    Here are the Caregivers available for <b style={{ fontWeight: 600 }}>{DAYS[dayIndex]}s</b>:
                  </Typography>
                  {ids.map(id => caregivers[id])}
                </Box>
              )
            );
          })}
        </Box>
      )}
    </>
  );
}

export default memo(CaregiverList, () => true);
