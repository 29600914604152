/* Component for rendering the footer of SignUpForm (arrows and policy message) */

import React, { memo } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { POLICY_URL, TOS_URL } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    marginBottom: theme.spacing(4),
    gridTemplateColumns: "auto 1fr auto",
    gridColumnGap: theme.spacing(1),
    alignItems: "center"
  },
  btn: {
    borderRadius: "50%",
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.secondary.dark,
    cursor: "pointer",
    padding: theme.spacing(1),
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem"
    }
  },
  policy: {
    textAlign: "center",
    gridColumn: 2
  },
  prev_btn: {
    gridColumn: 1
  },
  next_btn_container: {
    display: "contents"
  },
  next_btn: {
    gridColumn: 3
  }
}));

function SignUpNavigation(props) {
  const { container, btn, policy, prev_btn, next_btn_container, next_btn } = useStyles();
  const { elements, prev } = props;

  return (
    <Box className={container}>
      {elements[0] && <ArrowBackRoundedIcon className={`${btn} ${prev_btn}`} onClick={prev} />}
      {elements[1] && (
        <Typography variant="caption" className={policy}>
          By creating an account, you accept Vidal’s{" "}
          <Link to={TOS_URL} style={{ textDecoration: "none" }} target="_blank">
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link to={POLICY_URL} style={{ textDecoration: "none" }} target="_blank">
            Privacy Policy
          </Link>
          .
        </Typography>
      )}
      {elements[2] && (
        <button type="submit" className={next_btn_container}>
          <ArrowForwardRoundedIcon className={`${btn} ${next_btn}`} tabIndex="0" />
        </button>
      )}
    </Box>
  );
}

export default memo(SignUpNavigation, (prev, next) => {
  return (
    prev.refs.length === next.refs.length &&
    prev.refs.every((ref, index) => ref === next.refs[index]) &&
    prev.elements.every((item, index) => item === next.elements[index])
  );
});
