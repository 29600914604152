/* Component for rendering a single notification in the notification page */

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "assets/cancel.svg";
import CalendarIcon from "assets/calendar-icon.svg";
import DeleteIcon from "assets/delete.svg";
import StarIcon from "assets/star.svg";
import {
  COMPLETED_STATUS,
  UPCOMING_STATUS,
  REQUESTED_STATUS,
  CANCELLED_STATUS,
  DAYS,
  MONTHS,
  getTimeString,
  SCHEDULE_URL
} from "Constants";

const DECLINED_ACTION = 2;

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    textAlign: "center",
    gridGap: theme.spacing(2),
    padding: theme.spacing(3, 4, 4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      justifyItems: "initial",
      gridGap: 0,
      textAlign: "left",
      gridTemplateColumns: "50px 1fr auto",
      padding: theme.spacing(3, 3, 4)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 4, 4, 6)
    }
  },
  header: {
    fontSize: "1rem",
    fontWeight: 500
  },
  main_icon: {
    width: "33px",
    height: "33px"
  },
  delete_icon: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: "pointer",
    width: "20px",
    [theme.breakpoints.up("sm")]: {
      width: "18px"
    }
  },
  btn: {
    fontSize: "1.1rem",
    fontWeight: 500,
    alignSelf: "end",
    width: "175px",
    height: "fit-content",
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

function Notification(props) {
  const { id, appointment, remove, view } = props;
  const { container, header, main_icon, delete_icon, btn } = useStyles();

  const action = appointment.action;
  const status = Number(appointment.status);
  const date = new Date(appointment.date.replace(" ", "T"));
  const start = getTimeString(appointment.start.split(" ")[1]);
  const end = getTimeString(appointment.end.split(" ")[1]);

  const handleDelete = () => {
    remove(id);
  };

  const handleView = () => {
    view(id);
  };

  let mainIcon = "";
  let btnMessage = "";
  let headerMessage = "";
  if (status === COMPLETED_STATUS) {
    mainIcon = <img src={StarIcon} alt="Review Visit" className={main_icon} />;
    btnMessage = "Review Care Visit";
    headerMessage = "Leave a Care Visit Review";
  } else if (status === UPCOMING_STATUS) {
    mainIcon = <img src={CalendarIcon} alt="Confirmed Visit" className={main_icon} />;
    btnMessage = "View Details";
    headerMessage = "Care Visit Confirmed!";
  } else {
    mainIcon = <img src={CancelIcon} alt="Cancel Visit" className={main_icon} />;
    btnMessage = "Schedule Care";
    if (action === DECLINED_ACTION) {
      headerMessage = "Declined";
    }
    else {
      headerMessage = status === REQUESTED_STATUS ? "Requested" : "Cancelled";
    }
  }

  const buttonComponent =
    status === CANCELLED_STATUS || status === REQUESTED_STATUS ? (
      <Link to={SCHEDULE_URL} style={{ alignSelf: "end", textDecoration: "none" }}>
        <Button className={btn}>{btnMessage}</Button>
      </Link>
    ) : (
        <Button className={btn} onClick={handleView}>
          {btnMessage}
        </Button>
      );

  return (
    <Paper className={container} elevation={2}>
      {mainIcon}

      <Box>
        <Typography color="secondary" gutterBottom className={header}>
          {headerMessage}
        </Typography>
        <Typography color="textSecondary">
          {DAYS[date.getDay()]}, {MONTHS[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
        </Typography>
        <Typography color="textSecondary">
          {start} - {end}
        </Typography>
      </Box>

      {buttonComponent}

      <img src={DeleteIcon} alt="Delete Notification" className={delete_icon} onClick={handleDelete} />
    </Paper>
  );
}

export default Notification;
