/* Component the body of the profile page when edit mode is off */

import React, { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { GrayTextField, GrayDatePicker } from "CustomComponents";
import { EDIT_USER_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "initial"
    }
  },
  grid: {
    display: "grid",
    alignItems: "center",
    borderRadius: theme.spacing(2),
    gridTemplateColumns: "auto",
    gridGap: theme.spacing(0.75, 1),
    padding: theme.spacing(4, 3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      padding: theme.spacing(3, 10, 2, 6)
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto 350px",
      padding: theme.spacing(3, 30, 2, 6)
    }
  },
  label: {
    fontWeight: 500
  },
  btn: {
    display: "block",
    margin: "auto",
    padding: theme.spacing(1, 3),
    fontSize: "0.8rem",
    borderRadius: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bgcolor.main
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 4, 0, "auto")
    }
  }
}));

function ProfileEdit(props) {
  const { update, avatar } = props;
  const { container, grid, label, btn } = useStyles();

  let initialBirth = new Date(localStorage.getItem("birth"));
  if (initialBirth.getFullYear()) {
    initialBirth = new Date(localStorage.getItem("birth").replace(/-/g, "/"));
  } else {
    initialBirth = null;
  }
  let initialGender = localStorage.getItem("gender");
  initialGender = initialGender === "M" || initialGender === "F" ? initialGender : "";

  const { enqueueSnackbar } = useSnackbar();
  const [first, setFirst] = useState(localStorage.getItem("first_name"));
  const [last, setLast] = useState(localStorage.getItem("last_name"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [birth, setBirth] = useState(initialBirth);
  const [gender, setGender] = useState(initialGender);

  const labels = ["First Name", "Last Name", "Phone Number"];
  const content = [first, last, phone];
  const sets = [setFirst, setLast, setPhone];

  const handleChange = setState => e => setState(e.target.value);

  const handleSubmit = e => {
    e.preventDefault();
    if (!birth || !birth.getFullYear()) {
      return;
    }

    const hasChanged =
      first !== localStorage.getItem("first_name") ||
      last !== localStorage.getItem("last_name") ||
      phone !== localStorage.getItem("phone") ||
      initialBirth === null ||
      initialBirth.getTime() !== birth.getTime() ||
      gender !== initialGender ||
      avatar !== null;

    if (!hasChanged) {
      return;
    }

    const body = new FormData();
    body.set("first_name", first)
    body.set("last_name", last)
    body.set("phone", phone)
    body.set("id", localStorage.getItem("id"))
    body.set("uid", localStorage.getItem("id"))
    body.set("auth_key", localStorage.getItem("authKey"))

    const year = birth.getFullYear();
    const month = birth.getMonth() + 1;
    const day = birth.getDate();
    body.set("date_of_birth", `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`)
    if (gender === "M" || gender === "F") {
      body.set("gender", gender);
    }
    avatar && body.set("profile_pic", avatar);

    fetch(EDIT_USER_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
        } else {
          enqueueSnackbar("Account updated", { variant: "success" });
          const user = res.data.User;
          localStorage.setItem("first_name", user.first_name);
          localStorage.setItem("last_name", user.last_name);
          localStorage.setItem("phone", user.phone);
          localStorage.setItem("birth", user.date_of_birth);
          localStorage.setItem("gender", user.gender);
          localStorage.setItem("profile_pic", user.profile_pic || "")
          update();
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  };

  return (
    <form className={container} autoComplete="off" onSubmit={handleSubmit}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper elevation={2} className={grid}>
          {labels.map((labelText, index) => (
            <Fragment key={index}>
              <Typography color="secondary" className={label} component="label" htmlFor={labelText}>
                {labelText}
              </Typography>
              <GrayTextField
                required
                variant="outlined"
                value={content[index]}
                onChange={handleChange(sets[index])}
                inputProps={{ id: labelText }}
              />
            </Fragment>
          ))}
          <Typography color="secondary" className={label} component="label" htmlFor="Birth date">
            Birth date
          </Typography>
          <GrayDatePicker
            disableFuture
            disableToolbar
            okLabel={<Typography color="secondary">OK</Typography>}
            cancelLabel={<Typography color="secondary">Cancel</Typography>}
            maskChar="-"
            variant="dialog"
            InputProps={{ required: true, id: "Birth date" }}
            inputVariant="outlined"
            invalidDateMessage="Invalid Date"
            minDateMessage=""
            format="yyyy/MM/dd"
            openTo="year"
            views={["year", "month", "date"]}
            value={birth}
            onChange={setBirth}
          />

          <Typography color="secondary" className={label} component="label" htmlFor="Gender">
            Gender
          </Typography>
          <GrayTextField
            variant="outlined"
            required
            SelectProps={{ id: "Gender", displayEmpty: true }}
            select
            value={gender}
            onChange={e => setGender(e.target.value)}
          >
            <MenuItem value="" disabled>
              Unselected
            </MenuItem>
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="F">Female</MenuItem>
          </GrayTextField>
        </Paper>
      </MuiPickersUtilsProvider>

      <Button className={btn} type="submit">
        Save Changes
      </Button>
    </form>
  );
}

export default ProfileEdit;
