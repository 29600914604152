/* Component for rendering the profile tab in IndividualCaregiver */

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SoundIcon from "assets/sound-icon.svg";
import TimerIcon from "assets/timer-icon.svg";
import AwardIcon from "assets/award-icon.svg";
import { ADD_USER_TO_QB_URL, SCHEDULE_URL } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  icon: {
    marginTop: theme.spacing(1),
    width: "20px",
    height: "20px"
  },
  label: {
    margin: theme.spacing(4, 0),
    display: "grid",
    gridTemplateColumns: "20px 1fr",
    columnGap: theme.spacing(4)
  },
  title_text: {
    fontWeight: 500
  },
  btn_container: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "end",
    justifySelf: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column"
    }
  },
  btn: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    fontWeight: 500,
    fontSize: "1rem",
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "block",
      margin: `${theme.spacing(0.5)}px auto`,
      width: "80%"
    }
  },
  chat_btn: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    }
  },
  schedule_btn: {
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  }
}));

function CaregiverProfileTab(props) {
  const { id, languages, experience, specialties } = props;
  const { container, icon, label, title_text, btn_container, btn, chat_btn, schedule_btn } = useStyles();

  const languageText = languages.map(language => (
    <Typography key={language} color="textSecondary">
      {language}
    </Typography>
  ));

  const experienceText = (
    <Typography color="textSecondary">
      {experience || 0} year{experience !== "1" ? "s" : ""}
    </Typography>
  );

  const specialtiesText = specialties.map(specialty => (
    <Typography key={specialty} color="textSecondary">
      {specialty}
    </Typography>
  ));

  const titles = ["Additional Language(s)", "Years of Experience", "Specialties"];
  const texts = [languageText, experienceText, specialtiesText];
  const icons = [SoundIcon, TimerIcon, AwardIcon];
  const alts = ["Languages", "Experience", "Specialties"];
  return (
    <Box className={container}>
      <Box>
        {titles.map((title, index) => (
          <Box className={label} key={index}>
            <img src={icons[index]} className={icon} alt={alts[index]} />
            <Box>
              <Typography color="secondary" className={title_text}>
                {title}
              </Typography>
              {texts[index]}
            </Box>
          </Box>
        ))}
      </Box>

      <Box className={btn_container}>
        <Link to={`${ADD_USER_TO_QB_URL}?cgid=${id}`} style={{ textDecoration: "none" }}>
          <Button className={`${btn} ${chat_btn}`}>Chat</Button>
        </Link>
        <Link to={`${SCHEDULE_URL}/?cgid=${id}`} style={{ textDecoration: "none" }}>
          <Button className={`${btn} ${schedule_btn}`}>Request Care</Button>
        </Link>
      </Box>
    </Box>
  );
}

export default CaregiverProfileTab;
