/* Component the body of the profile page when edit mode is off */

import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PasswordDialog from "components/PasswordDialog";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(2),
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 6, 2),
      flexDirection: "row",
      justifyContent: "initial"
    }
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: theme.spacing(2, 3),
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      gridGap: theme.spacing(2, 10)
    }
  },
  labelText: {
    fontWeight: 500
  },
  contentText: {
    wordBreak: "break-word"
  },
  btn: {
    alignSelf: "center",
    borderRadius: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "flex-end"
    }
  }
}));

function ProfileDisplay() {
  const { container, grid, labelText, contentText, btn } = useStyles();

  const [open, setOpen] = useState(false);

  const labels = ["First Name", "Last Name", "Email", "Phone Number", "Birth date", "Gender"];
  const firstName = localStorage.getItem("first_name");
  const lastName = localStorage.getItem("last_name");
  const email = localStorage.getItem("email");
  const phone = localStorage.getItem("phone");
  const date = localStorage.getItem("birth");
  let gender = localStorage.getItem("gender");
  if (gender === "M") {
    gender = "Male";
  } else if (gender === "F") {
    gender = "Female";
  } else {
    gender = "Not listed";
  }
  const content = [firstName, lastName, email, phone, date, gender];

  return (
    <Paper className={container} elevation={2}>
      <Box className={grid}>
        {labels.map((label, index) => (
          <Fragment key={index}>
            <Typography color="secondary" className={labelText}>
              {label}
            </Typography>
            <Typography color="textSecondary" className={contentText}>
              {content[index]}
            </Typography>
          </Fragment>
        ))}
      </Box>
      <Button className={btn} onClick={() => setOpen(true)}>
        Change Password
      </Button>
      <PasswordDialog open={open} close={() => setOpen(false)} />
    </Paper>
  );
}

export default ProfileDisplay;
