/* Component for rendering the chat sidebar and box in the chat page */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ChatSidebar from "components/ChatSidebar";
import ChatBox from "components/ChatBox";
import { QB } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateRows: "auto 500px",
    marginTop: theme.spacing(2),
    gridGap: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "400px 1fr",
      gridTemplateRows: "500px"
    }
  }
}));

function getInitialChatId(messages) {
  const entries = Object.entries(messages);
  let id = entries[0][0]
  let maxDate = 0
  entries.forEach(([key, [firstMessage]]) => {
    if (firstMessage?.date && firstMessage.date > maxDate) {
      maxDate = firstMessage.date;
      id = key;
    }
  })
  return id;
}

function getInitialNewMessages(messages, currentDialogId) {
  const newMessages = {};
  Object.entries(messages).forEach(([dialogId, messages]) => {
    if (dialogId !== currentDialogId && messages[0] && !messages[0].read) {
      newMessages[dialogId] = true;
    } else {
      newMessages[dialogId] = false;
    }
  });
  return newMessages;
}

function Chats(props) {
  const { data, userId, caregivers, favourite } = props;
  const { container } = useStyles();

  const [messages, setMessages] = useState(props.messages);
  const [chatId, setChatId] = useState(() => getInitialChatId(messages));
  const [newMessages, setNewMessages] = useState(() => getInitialNewMessages(props.messages, chatId));

  const handleChatChange = id => {
    newMessages[id] = false;
    setChatId(id);
  };

  // Update state and mark message as read if needed when a different chatbox is selected
  const handleRead = messageId => {
    const params = {
      messageId,
      userId,
      chatId,
    };
    QB.chat.sendReadStatus(params);
    newMessages[chatId] = false;
    setNewMessages({ ...newMessages });
  };

  // Update state when chat history is fetched
  const handleFetch = (id, items) => {
    items.forEach(({ _id, message, sender_id, date_sent }) => messages[id].push({ id: _id, message, isMyself: sender_id === userId, date: date_sent }));
    setMessages({ ...messages });
    return true;
  };

  // Update state when either participant sends a message
  const handleSend = (id, messageId, message, isMyself = true) => {
    messages[id].unshift({ id: messageId, message, isMyself, date: Date.now() });
    setMessages({ ...messages });
  };

  // Update read status
  QB.chat.onMessageListener = (sender, { id, dialog_id, body }) => {
    if (dialog_id !== chatId) {
      newMessages[dialog_id] = true;
    } else {
      // Mark new message as read
      const params = {
        messageId: id,
        userId,
        dialogId: dialog_id
      };
      QB.chat.sendReadStatus(params);
    }
    handleSend(dialog_id, id, body, false);
  };

  useEffect(() => {
    return () => (QB.chat.onMessageListener = null);
  }, []);

  const lastMessages = {};
  Object.keys(messages).forEach(key => (lastMessages[key] = messages[key][0] || {}));

  const chatHistory = messages[chatId];

  return (
    <Box className={container}>
      <ChatSidebar
        data={data}
        click={handleChatChange}
        messages={lastMessages}
        chatId={chatId}
        newMessages={newMessages}
      />
      <ChatBox
        id={chatId}
        data={data[chatId]}
        caregivers={caregivers}
        messages={chatHistory}
        send={handleSend}
        setResults={handleFetch}
        favourite={favourite}
        read={handleRead}
      />
    </Box>
  );
}

export default Chats;
