/* Component for rendering the change password dialog in the account details page */

import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { useSnackbar } from "notistack";
import { MIN_PASSWORD_LENGTH } from "Constants";
import { CHANGE_PASSWORD_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4, 2, 2, 3),
    width: "100%",
    maxWidth: "400px",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 4, 2, 6)
    }
  },
  input: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  btn: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(4),
    marginLeft: "auto",
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

function PasswordDialog(props) {
  const { open, close } = props;
  const { container, input, btn } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const oldRef = useRef();
  const newRef = useRef();
  const confirmRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    let fail = false;

    const oldValue = oldRef.current.value;
    const newValue = newRef.current.value;
    const confirmValue = confirmRef.current.value;

    if (newValue.length < MIN_PASSWORD_LENGTH) {
      enqueueSnackbar(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`, { variant: "error" });
      fail = true;
    }
    if (newValue !== confirmValue) {
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      fail = true;
    }
    if (oldValue === newValue) {
      enqueueSnackbar("Passwords have not changed", { variant: "error" });
      fail = true;
    }
    if (fail) {
      return;
    }

    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("id", localStorage.getItem("id"));
    body.set("current_password", oldValue);
    body.set("password", newValue);

    fetch(CHANGE_PASSWORD_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "fail") {
          enqueueSnackbar("Incorrect old password", { variant: "error" });
        } else {
          enqueueSnackbar("Password updated", { variant: "success" });
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });
  };

  return (
    <Dialog open={open} onClose={close} PaperProps={{ className: container, component: "form", onSubmit: handleSubmit }}>
      <Typography color="secondary">Enter Old Password</Typography>
      <Input className={input} placeholder="******" required disableUnderline type="password" inputRef={oldRef} />

      <Typography color="secondary">Enter New Password</Typography>
      <Input className={input} placeholder="******" required disableUnderline type="password" inputRef={newRef} />

      <Typography color="secondary">Re-Enter New Password</Typography>
      <Input className={input} placeholder="******" required disableUnderline type="password" inputRef={confirmRef} />

      <Button type="submit" className={btn}>
        Update Password
      </Button>
    </Dialog>
  );
}

export default PasswordDialog;
