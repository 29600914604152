import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    paddingBottom: theme.spacing(2)
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      textAlign: "left"
    }
  },
  paper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 4, 2, 8)
    }
  },
  sub_header: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem"
    }
  },
  text: {
    fontSize: "0.8rem",
    lineHeight: 2,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem"
    }
  }
}));

function Terms() {
  const { container, header, paper, sub_header, text } = useStyles();

  return (
    <Box className={container}>
      <Typography color="secondary" className={header}>
        Terms of Use
      </Typography>

      <Paper className={paper} elevation={2}>
        <Typography color="secondary" className={sub_header} gutterBottom>
          These Terms do not apply to organizations (ie health care facilities, etc) that engage with us for staffing
          services.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          Last updated May 26, 2020
        </Typography>
        <Typography color="textSecondary" className={text}>
          Please read these Terms of Use (“Terms”) and our Privacy Policy located at www.vidalhomecare.com/privacy carefully
          because they govern your access to and use of services made available by Vidal Home Care (“Vidal,” “we” or “us”) or
          through the Vidal Platform, our website located at www.vidalhomecare.com (the “Site”), our mobile device and web
          applications (“Apps”) and the Vidal Display (defined below). To make these Terms easier to read, the Site, our Apps
          and services are collectively called the “Platform”.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          1. About the Vidal Platform
        </Typography>
        <Typography color="textSecondary" className={text}>
          As a referral agency, Vidal offers the Platform to enable people who need non-medical, in-home personal care (the
          “Care Services”) to be connected with third-party providers of Care Services (“Caregivers”) through the Platform.
          The person receiving the Care Services (the “Recipient”) may be you or another person for whom you have the
          authority to order Care Services, such as a parent or other relative. As a referral agency, we merely act as an
          intermediary between you and the Caregiver and our responsibilities under these Terms are limited to facilitating
          the availability of the Platform and collecting payments on behalf of the Caregiver. You and the Caregiver
          establish a direct relationship with respect to the Care Services.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Vidal checks the background and experience of the Caregivers via third party background check services and other
          means; however, you must decide whether a Caregiver is suited to your needs and whether to work with a particular
          Caregiver. The Care Services are provided solely by the Caregivers, and without limiting any other terms of these
          Terms, Vidal itself does not provide or warrant the Care Services. Vidal is not responsible for any personal
          injury, damage or harm resulting from your scheduling or use of Care Services or your interaction with any
          Caregiver. Vidal is not the employer of the Caregivers – See Section 4.
        </Typography>

        <Typography color="textSecondary" className={text}>
          No Professional or Medical Advice
        </Typography>

        <Typography color="textSecondary" className={text}>
          The Care Services and the Platform are not medical service or advice and are not intended to replace or substitute
          any professional or medical advice. Vidal expressly disclaims, and you expressly release Vidal from, any and all
          liability concerning any treatment, action by, or effect on any person following the information offered or
          provided within or through the Platform or in connection with the Care Services. You should consult with an
          appropriately trained professional or medical provider for all concerns that require professional or medical
          advice.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          2. Our Agreement
        </Typography>
        <Typography color="textSecondary" className={text}>
          These Terms constitute an agreement between Vidal and you, on behalf of yourself and any Recipient. You represent
          and warrant that (a) you and the Recipient (if not yourself) are at least 18 years old and the age of legal
          majority in your jurisdiction; and (b) if the Recipient is someone other than yourself, you have the full power and
          authority to bind that person to these Terms. In that case that person shall also be bound by these Terms, and
          every reference in these Terms to “you” and “your” shall also include the Recipient.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Changes to Terms or Platform
        </Typography>

        <Typography color="textSecondary" className={text}>
          Vidal may modify these Terms at any time, in its sole discretion. If we do so, we will let you know either by
          posting the modified Terms on the Platform or through other communications in accordance with Section 9. It is
          important that you review the Terms whenever we modify them because if you continue to use the Platform after we
          have made the modified Terms available via the Platform, you are indicating to us that you agree to be bound by the
          modified Terms. If you do not agree to be bound by the modified Terms, then you may not use the Platform anymore.
          Because our Platform is evolving over time we may change or discontinue all or any part of the Platform, at any
          time and without notice, at our sole discretion.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          3. Scheduling And Payment
        </Typography>
        <Typography color="textSecondary" className={text}>
          Scheduling Appointments
        </Typography>

        <Typography color="textSecondary" className={text}>
          Appointments for Care Services with Caregivers can be scheduled through the Platform or by calling 1-888-97 VIDAL.
          Your request for Care Services is only accepted when you receive a confirmation of the scheduled appointment times.
        </Typography>

        <Typography color="textSecondary" className={text}>
          General Payment Terms
        </Typography>

        <Typography color="textSecondary" className={text}>
          You agree to keep a current credit card on file with Vidal at all times. You agree to pay Vidal (on behalf of the
          Caregiver) for any transactions made in connection with your account by one of the payment methods described on the
          Platform. We will charge your credit card according to the agreed rates for Care Services. Any changes in rates
          will be communicated to you in accordance with these Terms of Use. All rates are inclusive of the fees of the
          Caregivers for the Care Services, which will be held in a trust account until paid, and Vidal’s fees for usage of
          the Platform (“Service Fees”). All Service Fees are due immediately upon completion of the scheduled Care Service
          appointment and you hereby authorize Vidal to charge the card on file following completion. We retain the right to
          place a hold on your credit card for an ordered or completed Care Service appointment. In addition, you agree that
          Vidal may charge your payment method for verification and pre-authorization purposes and you agree to bear any
          additional charges that your bank or other financial service may levy on you. We will use third party services to
          process credit card information.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Incidental Expenses
        </Typography>

        <Typography color="textSecondary" className={text}>
          Service Fees do not include any reimbursement costs or expenses associated with the Care Services, such as food for
          the Recipient or costs associated with any outing. Payment or reimbursement arrangements for those expenses must be
          made between you and the Caregiver directly.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Cancellation by You
        </Typography>

        <Typography color="textSecondary" className={text}>
          You may cancel any appointment for Care Services on the Platform (mobile or web app) or by calling Vidal at
          1-888-97 VIDAL no later than 24 hours prior to the scheduled appointment. You authorize us to charge your credit
          card for any scheduled Care Service appointments that you do not cancel in accordance with this paragraph,
          including if the Caregiver is unable to deliver the services due to the unavailability of or refusal by the
          Recipient. Certain discounted or promotional rates may be subject to additional restrictions and penalties for
          cancellation, which will be outlined in connection with the discount or promotion.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Cancellation or Substitution by Caregiver
        </Typography>

        <Typography color="textSecondary" className={text}>
          If a Caregiver is unable to make any scheduled appointment and Vidal receives sufficient advance notification,
          Vidal will make the appointment available to another Caregiver through the Platform. Vidal cannot guarantee that a
          replacement will accept the appointment in sufficient time, and if no replacement is available, you will not be
          charged for the appointment.
        </Typography>

        <Typography color="textSecondary" className={text}>
          No Show
        </Typography>

        <Typography color="textSecondary" className={text}>
          If a Caregiver shows up for a scheduled Care Visit and no one is home, s/he will try contacting the Care Recipient
          by phone at the phone number saved to the Care Recipient profile. The Caregiver will attempt to make contact for 10
          minutes, after which, s/he will mark the Care Visit as a No Show. You will be charged for the duration of the
          scheduled Care Visit if it is marked as a No Show.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Discounts and Promotion
        </Typography>

        <Typography color="textSecondary" className={text}>
          From time to time, you may be offered discounted or promotional rates (in an amount subject to Vidal’s sole
          discretion), which will be subject to any additional terms outlined in connection with such discount or promotion.
        </Typography>

        <Typography color="textSecondary" className={text}>
          No Refunds
        </Typography>

        <Typography color="textSecondary" className={text}>
          No refunds or credits will be provided once your credit card has been charged, except that at our sole discretion,
          refunds or credits may be granted in extenuating circumstances, as a result of specific promotions, or to correct
          any errors we have made, or as provided under “Discounts.”
        </Typography>

        <Typography color="textSecondary" className={text}>
          Fraud Penalty
        </Typography>

        <Typography color="textSecondary" className={text}>
          If we determine that you have used the Platform under false pretenses, by providing false information, or for
          purposes other than a bona fide intention to receive Care Services from a Caregiver, we may charge your card on
          file a fee equal to $300, representing damages suffered by us from the fraudulent usage. If we determine that our
          damages exceed $300, we may bring an action against you.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          4. Caregivers and Care Services
        </Typography>
        <Typography color="textSecondary" className={text}>
          Direct Engagement
        </Typography>

        <Typography color="textSecondary" className={text}>
          You acknowledge that Vidal has invested significant resources in recruiting its Caregivers. In the event that you
          hire any Caregiver that has been contracted through Vidal within the past twelve (12) months, you will pay Vidal a
          placement fee equal to $10,000. You acknowledge that this is a reasonable representation of damages suffered by
          Vidal. If you hire a Caregiver directly you will assume responsibility for all screening, taxes, insurance,
          overtime and other requirements. Vidal may also terminate its Agreement with you immediately.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Transportation
        </Typography>

        <Typography color="textSecondary" className={text}>
          If you have elected to have the Caregiver provide transportation in your (or the Recipient’s) car, you will be
          responsible for all necessary insurance to cover any accidents or liability that may occur. If you have elect to be
          transported in the Caregiver’s car, you understand that the Recipient will ride in the Caregiver’s personal car as
          a passenger of the Caregiver. Vidal expressly disclaims, and you hereby expressly release Vidal, from any
          responsibility or liability for any damages resulting from your use of transportation services from a Caregiver.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Gratuities
        </Typography>

        <Typography color="textSecondary" className={text}>
          Vidal does not designate any portion of your payment as a tip or gratuity to the Caregiver. While you are free to
          provide additional payment as a gratuity to any Caregiver, you are under no obligation to do so. You are
          responsible for determining whether any gratuity is reportable to any taxing authority and for making such report
          or paying any applicable taxes.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Ratings
        </Typography>

        <Typography color="textSecondary" className={text}>
          Whenever you or the Recipient is asked to provide ratings of the Caregiver, you agree that all ratings will
          represent the independent, honest and genuine opinion of the person providing the rating.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Good Samaritan
        </Typography>

        <Typography color="textSecondary" className={text}>
          The Care Services do not include medical care of any kind, including emergency care, and no part of the Service
          Fees constitute compensation for any medical or emergency care. If an emergency occurs while a Caregiver is with a
          Recipient, neither the Caregiver nor Vidal shall be liable for any damages resulting from any act or omission of
          the Caregiver. Whether a Caregiver renders emergency care is at the discretion of the Caregiver. Caregivers and, if
          applicable, Vidal, shall be entitled to the full benefit of any “Good Samaritan” laws.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Safety
        </Typography>

        <Typography color="textSecondary" className={text}>
          You are responsible for providing a safe and secure working environment for the Caregiver, and for obtaining all
          necessary insurance to protect against harm to the Caregiver. If a Caregiver detects violence or abuse affecting
          the Recipient, the Caregiver may be required by law to report that abuse to law enforcement.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          5. Your Use Of The Platform
        </Typography>
        <Typography color="textSecondary" className={text}>
          Your Account
        </Typography>

        <Typography color="textSecondary" className={text}>
          If you want to use the Platform, you will have to create an account. You are responsible for all activity that
          occurs under your account, whether or not you know about it, and you agree to maintain the security and secrecy of
          your account username and password at all times. Please notify us immediately of any unauthorized use of your
          account. If you communicate with us over the phone or in person, such as by ordering or changing services,
          providing information, or rating a Caregiver, you authorize us to update your account on your behalf to reflect
          such communications.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Information You Provide Us
        </Typography>

        <Typography color="textSecondary" className={text}>
          When you create a Vidal account, you submit personal information about you and the Recipient, such as your name,
          address, mobile phone number and age, as well as information about the Recipient that may be relevant to the Care
          Services, such as physical or mental condition, preferences, diet, etc. You agree to provide accurate, complete and
          up-to-date information, and your failure to do so may result in adverse consequences, including the inability of
          the Caregiver to provide the Care Services, your inability to access and use the Platform or suspension of your
          account. We may share the information you give Vidal the Platform with any Caregiver who provides Care Services to
          the Recipient in accordance with our Privacy Policy. Please refer to our Privacy Policy for information on how we
          collect, use and disclose information from our users.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Communications from Us
        </Typography>

        <Typography color="textSecondary" className={text}>
          You consent to the receipt by both you and the Recipient of calls to your mobile phone number and text (SMS)
          messages from both Vidal and the Caregivers who are providing Care Services to the Recipient. Standard voice and
          data charges from your phone carrier may apply to these calls and texts. You may also receive email communications
          from Vidal or Caregivers, such as information about the services, newsletters, special offers, and account
          reminders and updates. You can remove yourself from communications that are not related to the delivery of services
          by following the instructions in the email. If you do not want to receive communication of sensitive or
          confidential information by email, please notify us at support@vidalhomecare.com.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Rights and Terms for Apps
        </Typography>

        <Typography color="textSecondary" className={text}>
          Subject to your compliance with these Terms, Vidal grants you a limited, non-exclusive, revocable,
          non-sublicensable and non-transferrable license to download and install a copy of the App on a mobile device or
          computer that you own or control and to run such copy of the App solely for your own personal non-commercial
          purposes. You may not copy the App, except for making a reasonable number of copies for backup or archival
          purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works
          based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii)
          reverse engineer, decompile or disassemble the App; or (iv) make the functionality of the App available to multiple
          users through any means. Vidal reserves all rights in and to the App not expressly granted to you under these
          Terms.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Use of the Platform
        </Typography>

        <Typography color="textSecondary" className={text}>
          You agree to comply with all applicable laws when using the Platform, and you may only use the Platform for lawful
          purposes. You will not in your use of the Platform cause nuisance, annoyance, inconvenience, or property damage,
          whether to the Caregiver or any other user or third party. You will not take any of the following actions with
          respect to the Platform: reproduce, distribute, license, resell, copy, reverse engineer, mirror, frame, scrape,
          data mine, spam, access in an unauthorized manner, interfere with, or use to build competitive or similar products.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          6. Disclaimers; Limitation Of Liability; Indemnity
        </Typography>
        <Typography color="textSecondary" className={text}>
          Disclaimer
        </Typography>

        <Typography color="textSecondary" className={text}>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. VIDAL MAKES NO REPRESENTATION, WARRANTY, OR
          GUARANTEE (I) REGARDING THE RELIABILITY, TIMELINESS, SAFETY, SUITABILITY, OR AVAILABILITY OF THE PLATFORM, CONTENT
          OR AN VIDAL DISPLAY OR ANY SERVICES REQUESTED THROUGH THE USE OF THE PLATFORM, OR (II) THAT THE PLATFORM WILL MEET
          YOUR REQUIREMENTS OR WILL BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS.
        </Typography>

        <Typography color="textSecondary" className={text}>
          VIDAL EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY CAREGIVER OR OTHER THIRD PARTY. WE DO NOT,
          AND CANNOT CONTROL YOUR OR ANY RECIPIENT’S INTERACTION WITH ANY CAREGIVER; WE EXPRESSLY DISCLAIM ANY LIABILITY
          ARISING FROM SUCH INTERACTION. VIDAL ASSUMES NO RESPONSIBILITY FOR ANY CAREGIVER’S FAILURE TO COMPLY WITH ANY
          APPLICABLE LAWS AND REGULATIONS.
        </Typography>

        <Typography color="textSecondary" className={text}>
          VIDAL’S RESPONSIBILITIES UNDER THESE TERMS ARE LIMITED SOLELY TO FACILITATING THE AVAILABILITY OF THE PLATFORM AND
          COLLECTING PAYMENTS ON BEHALF OF THE CAREGIVER. THE CAREGIVER, NOT VIDAL, IS RESPONSIBLE FOR THE CARE SERVICES. WE
          EXPRESSLY DISCLAIM ANY LIABILITY ARISING FROM THE CARE SERVICES.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Limitation of Liability
        </Typography>

        <Typography color="textSecondary" className={text}>
          YOU ACKNOWLEDGE AND AGREE THAT, AS BETWEEN YOU AND VIDAL, TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU ASSUME THE
          ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE PLATFORM. VIDAL SHALL NOT BE LIABLE FOR INDIRECT,
          INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, COST OF
          SUBSTITUTE SERVICES, PERSONAL INJURY, OR PROPERTY LOSS OR DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE
          RESULTING FROM YOUR USE OF OR INABILITY TO USE THE PLATFORM, ANY RELATIONSHIP BETWEEN YOU AND/OR ANY RECIPIENT, AND
          ANY CAREGIVER, EVEN IF VIDAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. VIDAL SHALL NOT BE LIABLE FOR
          DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND VIDAL’S REASONABLE CONTROL. SOME JURISDICTIONS DO NOT
          ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY
          NOT APPLY TO YOU.
        </Typography>

        <Typography color="textSecondary" className={text}>
          IN NO EVENT SHALL VIDAL’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
          INABILITY TO USE THE PLATFORM, CONTENT OR A VIDAL DISPLAY EXCEED THE AMOUNTS YOU HAVE PAID TO VIDAL FOR CARE
          SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THE CLAIM OR ACTION. THE EXCLUSIONS AND LIMITATIONS OF DAMAGES
          SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN VIDAL AND YOU.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Indemnity
        </Typography>

        <Typography color="textSecondary" className={text}>
          You agree to indemnify and hold Vidal and its officers, directors, employees, and agents harmless from any and all
          claims, demands, losses, liabilities, and expenses (including attorneys’ fees), arising out of or in connection
          with your use of the Platform, the Care Services or the Vidal Display, your lack of authority to bind the Recipient
          to these Terms, the breach or violation of any of these Terms, or your violation of the rights of any third party,
          including any Caregiver.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          7. Dispute Resolution; Arbitration
        </Typography>
        <Typography color="textSecondary" className={text}>
          Governing Law
        </Typography>

        <Typography color="textSecondary" className={text}>
          These Terms and any action related thereto will be governed by the laws of Ontario without regard to its conflict
          of laws provisions.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Agreement to Arbitrate
        </Typography>

        <Typography color="textSecondary" className={text}>
          You and Vidal agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach,
          termination, enforcement, interpretation or validity thereof or the use of the Services or Content (collectively,
          “Disputes”) will be settled by binding arbitration, except that each party retains the right to bring an individual
          action in small claims court. You acknowledge and agree that you and Vidal are each waiving the right to a trial by
          jury or to participate as a plaintiff or class member in any purported class action or representative proceeding.
          Further, unless both you and Vidal otherwise agree in writing, the arbitrator may not consolidate more than one
          person’s claims, and may not otherwise preside over any form of any class or representative proceeding. If this
          specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed
          void. Except as provided in the preceding sentence, this “Dispute Resolution” section will survive any termination
          of these Terms.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          8. General
        </Typography>
        <Typography color="textSecondary" className={text}>
          Termination
        </Typography>

        <Typography color="textSecondary" className={text}>
          Vidal may immediately terminate our agreement, as evidenced by these Terms, or the offering of the Platform or any
          portion thereof, at any time for any reason, through notice as provided below. You may cancel your account at any
          time by calling us or by emailing us at support@vidalhomecare.com. Upon any termination, discontinuation or
          cancellation of the offering of the Platform, the following provisions will survive: “About the Vidal Platform”,
          “Scheduling and Payment”, “Vidal Display,” “Content and Ownership,” “Caregivers and Care Services” “Disclaimers;
          Limitation of Liability; Indemnity”; “Dispute Resolution; Arbitration” and “General.”
        </Typography>

        <Typography color="textSecondary" className={text}>
          Notice
        </Typography>

        <Typography color="textSecondary" className={text}>
          Vidal may give notice by means of a general notice on the Platform, electronic mail to your email address in your
          account, or by written communication sent by first class mail or pre-paid post to your address in your account.
          Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by
          first class mail or pre-paid post) or 12 hours after sending (if sent by email). You may give notice to Vidal, with
          such notice deemed given when received by Vidal, at any time by first class mail or pre-paid post to Vidal Home
          Care, 1 Yonge St #1801, Toronto, ON M5E 1W7, or upon receipt when sent by email to Vidal at
          support@vidalhomecare.com
        </Typography>

        <Typography color="textSecondary" className={text}>
          Assignment; Construction
        </Typography>

        <Typography color="textSecondary" className={text}>
          You may not assign these Terms without Vidal’s prior written approval. Vidal may assign these Terms without your
          consent to: (i) a subsidiary or affiliate; (ii) an acquirer of Vidal’s equity, business or assets; or (iii) a
          successor by merger. Any purported assignment in violation of this section shall be void. If any provision of these
          Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be
          enforced to the fullest extent under law. Vidal’s failure to enforce any right or provision in these Terms shall
          not constitute a waiver of such right or provision unless acknowledged and agreed to by Vidal in writing.
        </Typography>

        <Typography color="secondary" className={sub_header} gutterBottom>
          9. Links To Other Web Sites
        </Typography>
        <Typography color="textSecondary" className={text}>
          Our Service may contain links to third-party web sites or services that are not owned or controlled by Vidal Home
          Care.
        </Typography>

        <Typography color="textSecondary" className={text}>
          Vidal Home Care has no control over, and assumes no responsibility for, the content, privacy policies, or practices
          of any third party web sites or services. You further acknowledge and agree that Vidal Home Care shall not be
          responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or services available on or through any such web
          sites or services.
        </Typography>

        <Typography color="textSecondary" className={text}>
          We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
          services that you visit.
        </Typography>
      </Paper>
    </Box>
  );
}

export default Terms;
