/* Component for rendering the location inputs in the care recipient edit page */

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { GrayTextField as TextField } from "CustomComponents";
import DeleteIcon from "assets/delete.svg";

function FastTextField(props) {
  const { name, required, location, classes, placeholder } = props;
  const [update, setUpdate] = useState(true);

  const handleChange = e => {
    location[name] = e.target.value;
    setUpdate(!update); // Force update
  };

  return (
    <TextField
      variant="outlined"
      required={required}
      value={location[name]}
      className={classes}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
}

function LocationField(props) {
  const { index, remove, location } = props;
  const { location_zip, input, label } = props.classes;

  return (
    <>
      <Typography color="secondary" className={label}>
        Name Your Location
      </Typography>
      <FastTextField name="name" required={true} location={location} classes={input} placeholder="Location Name" />
      <img src={DeleteIcon} style={{ cursor: "pointer", width: "100%" }} onClick={remove(index)} alt="Delete Location" />
      <Typography color="secondary" className={label}>
        Address of Care
      </Typography>
      <FastTextField name="street" required={true} location={location} classes={input} placeholder="Street Address" />
      <FastTextField
        name="apartment"
        required={false}
        location={location}
        classes={input}
        placeholder="Apartment Number (optional)"
      />
      <FastTextField name="city" required={true} location={location} classes={input} placeholder="City" />
      <FastTextField name="province" required={true} location={location} classes={input} placeholder="Province" />
      <FastTextField
        name="zip"
        required={true}
        location={location}
        classes={`${input} ${location_zip}`}
        placeholder="Postal Code"
      />
    </>
  );
}

export default LocationField;
