/* Component for rendering the form of the login page */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { LOGIN_ROUTE } from "Routes";
import { DASHBOARD_URL } from "Constants";
import ForgotPasswordDialog from "components/ForgotPasswordDialog";

const CARETAKER_ROLE = "caretakerRole";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6)
    }
  },
  header: {
    color: theme.palette.secondary.dark
  },
  input_container: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(4, 0)
  },
  inputs: {
    width: "100%"
  },
  error_msg: {
    margin: theme.spacing(2, 0)
  },
  pswd_helper: {
    color: "#46637A",
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer"
  },
  btn: {
    display: "block",
    border: `1.5px solid ${theme.palette.secondary.dark}`,
    color: theme.palette.secondary.dark,
    fontSize: "1.25rem",
    fontWeight: 700,
    width: "250px",
    margin: `${theme.spacing(3)}px auto 0`,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.dark
    }
  },
  visibility_btn: {
    padding: 0
  }
}));

function LoginForm(props) {
  const { container, header, input_container, inputs, error_msg, pswd_helper, btn, visibility_btn } = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleEmailChange = e => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    setError("");
  };

  const handleSubmit = e => {
    e.preventDefault();
    fetch(LOGIN_ROUTE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, password })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            if (data.status === "Fail") {
              if (data.message.startsWith("Username or P")) {
                setError("Email and/or password is incorrect");
              } else {
                setError("Email and/or password is incorrect or your email has not yet been validated");
              }
            } else if (data.data.User.role !== CARETAKER_ROLE) {
              setError("Caregivers cannot sign in through this app");
            } else {
              const {
                id,
                auth_key,
                first_name,
                last_name,
                profile_pic,
                refrel_code,
                tokenpayment,
                email,
                phone,
                date_of_birth,
                gender,
                care_myself,
                spoken_language,
                additional_note,
                is_vip
              } = data.data.User;
              localStorage.setItem("id", id);
              localStorage.setItem("authKey", auth_key);
              localStorage.setItem("first_name", first_name);
              localStorage.setItem("last_name", last_name);
              localStorage.setItem("profile_pic", profile_pic || "");
              localStorage.setItem("referralCode", refrel_code);
              localStorage.setItem("token", tokenpayment || "");
              localStorage.setItem("email", email);
              localStorage.setItem("phone", phone || "");
              localStorage.setItem("birth", date_of_birth);
              localStorage.setItem("gender", gender);
              localStorage.setItem("language", spoken_language || "");
              localStorage.setItem("note", additional_note || "");
              localStorage.setItem("is_vip", is_vip);
              if (!care_myself) {
                // Store loved one data
                data = data.data.LovedOne;
                localStorage.setItem("loved_first_name", data.first_name);
                localStorage.setItem("loved_last_name", data.last_name);
                localStorage.setItem("loved_birth", data.date_of_birth);
                localStorage.setItem("loved_gender", data.gender);
                localStorage.setItem("loved_profile_pic", data.profile_pic || "");
                localStorage.setItem("loved_phone", data.phone || "");
                localStorage.setItem("language", data.language || "");
                localStorage.setItem("note", data.additional_note || "");
                localStorage.setItem("loved_id", data.id);
              }
              props.history.push(DASHBOARD_URL);
            }
          });
        }
      })
      .catch(err => console.error("Error:", err));
  };

  return (
    <>
      <form className={container} onSubmit={handleSubmit}>
        <Typography variant="h6" className={header}>
          Log in to your account
        </Typography>
        <Paper className={input_container}>
          <Input
            className={inputs}
            disableUnderline
            value={email}
            type="email"
            placeholder="Email"
            onChange={handleEmailChange}
            required
          />
        </Paper>
        <Paper className={input_container}>
          <Input
            type={visible ? "text" : "password"}
            value={password}
            className={inputs}
            disableUnderline
            placeholder="Password"
            onChange={handlePasswordChange}
            endAdornment={<IconButton
              className={visibility_btn}
              onClick={() => setVisible(!visible)}
              disableTouchRipple
            >
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>}
            required
          />
        </Paper>
        <Typography className={error_msg} color="error">
          {error}
        </Typography>
        <Typography className={pswd_helper} variant="subtitle2" onClick={() => setForgotPassword(true)}>
          Forgot your password?
        </Typography>
        <Button className={btn} type="submit">
          Sign In
        </Button>
      </form>
      <ForgotPasswordDialog open={forgotPassword} close={() => setForgotPassword(false)} />
    </>
  );
}

export default LoginForm;
