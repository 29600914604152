import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaginateNotifications from "components/PaginateNotifications";
import { SCHEDULE_URL } from "Constants";
import { NOTIFICATIONS_ROUTE, NOTFICATION_DELETE_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    paddingBottom: theme.spacing(2)
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
      textAlign: "left"
    }
  },
  sub_header: {
    fontSize: "1.1rem",
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem"
    }
  },
  paper: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(20, 0)
    }
  },
  btn: {
    padding: theme.spacing(1, 2),
    fontSize: "1.2rem",
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 8)
    }
  }
}));

function Notifications(props) {
  const { update } = props;
  const { container, header, sub_header, paper, btn } = useStyles();

  const [notifications, setNotifications] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [cancel, setCancel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let active = true;

    const headers = { Uid: localStorage.getItem("id"), Authkey: localStorage.getItem("authKey") };
    fetch(`${NOTIFICATIONS_ROUTE}/${localStorage.getItem("id")}`, { headers })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.status !== "Fail") {
            setNotifications(
              res.data.map(({ Notification, Appointment, Receipent, Location }) => {
                const {
                  id,
                  appointment_date,
                  appointment_status,
                  action,
                  start_time,
                  end_time,
                  client_rating,
                  client_feedback,
                  special_instruction,
                  care_note
                } = Appointment;
                const appointment = {
                  id,
                  date: appointment_date,
                  status: Number(appointment_status),
                  action: Number(action),
                  start: start_time,
                  end: end_time,
                  rating: client_rating,
                  feedback: client_feedback,
                  instruction: special_instruction,
                  note: care_note
                };
                const { profile_pic, first_name, last_name } = Receipent;
                const caregiver = { id: Receipent.id, avatar: profile_pic, name: `${first_name} ${last_name}` };
                const { address_label, apartment_name, city, postal_code, province, street_address } = Location;
                const location = {
                  name: address_label,
                  apartment: apartment_name,
                  city,
                  zip: postal_code,
                  province,
                  street: street_address
                };
                return { id: Notification.id, appointment, caregiver, location };
              })
            );
          }
          setLoaded(true);
        }
      })
      .catch(err => {
        console.error(err);
        setLoaded(null);
      });

    return () => (active = false);
  }, [cancel]);

  // When user deletes a notification return whether or not it succeeded
  const handleDelete = async notificationId => {
    const headers = { Uid: localStorage.getItem("id"), Authkey: localStorage.getItem("authKey") };
    let res = await fetch(`${NOTFICATION_DELETE_ROUTE}/${notificationId}`, { headers });
    if (!res.ok) {
      console.error(res);
      enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
      return null;
    } else {
      res = await res.json();
      if (res.status !== "Fail") {
        notifications.splice(
          notifications.findIndex(({ id }) => id === notificationId),
          1
        );
        update(notifications.length);
        setNotifications([...notifications]);
        enqueueSnackbar("Notification deleted", { variant: "success" });
        return true;
      }
      enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
      return false;
    }
  };

  let body = "";
  if (notifications.length === 0 || !loaded) {
    if (loaded === null) {
      body = (
        <Typography color="error" className={sub_header}>
          Something went wrong, Please try refreshing the page
        </Typography>
      );
    } else if (loaded === false) {
      body = (
        <>
          <Typography color="textSecondary" className={sub_header}>
            Retrieving your notifications
          </Typography>
          <CircularProgress />
        </>
      );
    } else {
      body = (
        <>
          <Typography color="textSecondary" className={sub_header}>
            You currently have <br></br> no new notifications
          </Typography>
          <Link style={{ textDecoration: "none" }} to={SCHEDULE_URL}>
            <Button className={btn} color="secondary">
              Request Care Now
            </Button>
          </Link>
        </>
      );
    }
    body = (
      <Paper className={paper} elevation={2}>
        {body}
      </Paper>
    );
  } else {
    body = <PaginateNotifications data={notifications} remove={handleDelete} cancel={() => setCancel(!cancel)} />;
  }

  return (
    <Box className={container}>
      <Typography color="secondary" className={header} gutterBottom>
        Notifications
      </Typography>
      {body}
    </Box>
  );
}

export default Notifications;
