/* Component for rendering the body of the care recipient page when edit mode is off */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { getDateString } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(2, 4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  card: {
    padding: theme.spacing(1, 2),
    justifySelf: "center",
    textAlign: "center",
    minWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      padding: theme.spacing(2, 3)
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "initial",
      justifySelf: "initial",
      padding: theme.spacing(3, 5)
    }
  },
  labelText: {
    fontWeight: 500
  },
  note_card: {
    [theme.breakpoints.up("md")]: {
      gridRow: "span 2",
      height: "fit-content"
    }
  },
  location_card: {
    display: "grid",
    width: "100%",
    gridGap: theme.spacing(1)
  },
  location_label: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginLeft: theme.spacing(3)
    }
  }
}));

function CareRecipientDisplay(props) {
  const { birth, gender, language, phone, note, locations } = props;
  const { container, card, labelText, note_card, location_card, location_label } = useStyles();

  let genderText = "Not listed";
  if (gender === "M") {
    genderText = "Male";
  } else if (gender === "F") {
    genderText = "Female";
  }

  return (
    <Box className={container}>
      <Paper className={card}>
        <Typography className={labelText} color="secondary">
          Care Recipient Details
        </Typography>
        <Typography color="textSecondary">Birthday: {getDateString(birth)}</Typography>
        <Typography color="textSecondary">Gender: {genderText}</Typography>
      </Paper>

      <Paper className={card}>
        <Typography className={labelText} color="secondary">
          Language Preference(s)
        </Typography>
        <Typography color="textSecondary">{language}</Typography>
      </Paper>

      <Paper className={card}>
        <Typography className={labelText} color="secondary">
          Phone Number
        </Typography>
        <Typography color="textSecondary">{phone}</Typography>
      </Paper>

      <Paper className={`${card} ${note_card}`}>
        <Typography className={labelText} color="secondary">
          Additional Notes
        </Typography>
        <Typography color="textSecondary">{note}</Typography>
      </Paper>

      <Box className={location_card}>
        <Typography className={`${labelText} ${location_label}`} color="secondary">
          Locations
        </Typography>

        {locations.map(({ id, street, apartment, name, city, province, zip }) => (
          <Paper key={id} className={card}>
            <Typography className={labelText} color="secondary">
              {name}
            </Typography>
            <Typography color="textSecondary">
              {street}
              {apartment && apartment !== "" ? `, ${apartment}` : ""}
            </Typography>
            <Typography color="textSecondary">
              {city}, {province} {zip}
            </Typography>
          </Paper>
        ))}

        {locations.length === 0 && (
          <Paper className={card}>
            <Typography color="textSecondary">You have no locations</Typography>
          </Paper>
        )}
      </Box>
    </Box>
  );
}

export default CareRecipientDisplay;
