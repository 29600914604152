import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    paddingBottom: theme.spacing(2)
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      textAlign: "left"
    }
  },
  paper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 4, 2, 8)
    }
  },
  sub_header: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem"
    }
  },
  text: {
    fontSize: "0.8rem",
    lineHeight: 2,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem"
    }
  },
  dense: {
    marginBottom: theme.spacing(5)
  }
}));

function Policy() {
  const { container, header, paper, sub_header, text, dense } = useStyles();

  return (
    <Box className={container}>
      <Typography color="secondary" className={header}>
        Privacy Policy
      </Typography>

      <Paper className={paper} elevation={2}>
        <Typography color="secondary" className={sub_header} gutterBottom>
          Last updated May 26, 2020
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          This Privacy Policy explains how Vidal Home Care (“Vidal”, “we”, or “us”) collects, uses, shares and protects
          information collected through our technology, applications, websites, content, products, and services (the
          “Platform”). The Platform enables people seeking non-medical, in-home personal care (the “Care Services”) to be
          connected with independent, third-party providers of Care Services (“Independent Caregivers”). The person receiving
          the Care Services (the “Recipient”) may be you or another person for whom you have the authority to order Care
          Services, such as a parent or other relative.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          We may collect the following kinds of information:
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          1.1. Information you provide Vidal
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          collects information from you when you provide it to us. This happens when you register for our website, use the
          Platform, or communicate with us, such as through our website, applications, email, SMS, over the telephone or in
          person. The information you provide us may include information that personally identifies you (“Personal
          Information” or “PII”), such as name, email address, phone number or your mailing address. We also collect your
          credit card information in order to process payments for Care Services.
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          You may also provide us information about a person whose care is being managed through the Platform, such as that
          person’s Personal Information, conditions relative to the Care Services, special needs and preferences. You agree
          not to provide us information about any other person unless you have that person’s express permission.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          1.2. Information provided to the Independent Care Professionals
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          You or the Recipient may provide information to the Independent Caregivers. The Independent Caregivers will use the
          Platform to manage and communicate with you about the Care Services that they are providing, including by entering
          information about the Care Services and the recipient of the services into the Vidal application and websites,
          which will make it available to us.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          1.3. Information from cookies and similar devices
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          Vidal uses cookies, web beacons, embedded scripts and similar methods to collect information about how users use
          and interact with our services through our website and applications. Cookies can also be used to remember you and
          your preferences. You can disable cookies through your browser settings, in which case some of the features of our
          services may not function properly.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          1.4. Information from mobile devices
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          If you are interacting with our website or applications on a mobile device, your mobile carrier may automatically
          send us your device and hardware ID, device type, carrier, carrier user ID, the content of your request and basic
          usage statistics about your device and usage of our website or applications. We do not store this information or
          use it for any purpose. If you provide it to us or send us a message from your mobile device, we may also collect
          your phone number. Your device may also store information about your usage of our website and applications. Please
          consult your device documentation on how to manage local storage.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          1.5. Information based on standard web protocols
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          When you access our website through a browser, application or other client, like other web providers, our servers
          automatically record certain information. This data is recorded in server logs and may include information such as
          your web request, your interaction with the website, the website that you were visiting when you came to our
          website, your Internet Protocol (IP) address, your browser type and language, the date and time of your request,
          and your cookie data.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          2. Information Usage
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          Here are the ways that we use the information that we collect: to provide the Platform to you; to connect you with
          Independent Caregivers; to the Independent Caregivers and determine their eligibility to participate in the
          Platform; to improve our services and develop new services; to perform statistical, demographic and marketing
          analyses, including to determine; how users interact with the Platform; the level of interest in the Platform; to
          customize products and services for you; to contact you about the Platform, the Care Services and the Independent
          Caregivers, and send you information that you have requested; to notify you of any changes to the Platform; to
          protect the rights, safety or property of Vidal, our partners and our users. In addition, the Independent
          Caregivers will use the information to provide and communicate about the Care Services. Unless you ask us not to,
          we may contact you via email in the future to tell you about specials, new products or services, or changes to this
          privacy policy.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          3. Sharing of Personal Information
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          Vidal may share your Personal Information with third parties as follows: With Independent Caregivers, so that they
          may provide and communicate about the Care Services; With individuals involved in the Recipient’s care, such as
          family members; With people or organizations that you authorize, including people you have engaged to provide you
          services such as doctors or other care providers; With companies with whom we have contracted to provide services
          on our behalf, such as web hosting services, application development, analytics, security, and data processing.
          These companies are obligated to maintain the confidentiality of any Personal Information they receive from us and
          are restricted from using it for any purpose that is incompatible with the uses set forth in this Privacy Policy.
          With our corporate subsidiaries or affiliates. Any Personal Information provided to Vidal’s subsidiaries or
          affiliates will be treated in accordance with the terms of this Privacy Policy. Where we have a good faith belief
          that it is necessary to (a) satisfy any applicable law, regulation, legal process or governmental requirement; (b)
          enforce any applicable terms of our Platform, (c) detect, prevent or otherwise address fraud, security or technical
          issues or (d) protect against harm to the rights, property or safety of Vidal, its partners, its users or the
          public. If Vidal undergoes any merger or sale of its stock or some or all of its assets, we may transfer your
          Personal Information to the successor entity so long as it continues to maintain that information in accordance
          with the Privacy Policy under which it was collected. Other than as described in the preceding paragraphs, Vidal
          will not (a) sell, rent, swap or authorize any third party to use your Personal Information or (b) use your
          Personal Information for any purpose other than those described in this Privacy Policy, in each case without your
          consent.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          4. Changes to this Privacy Policy
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          Any changes to this policy will be posted at this URL: vidalhomecare.com/privacy. We will not use data we collect
          in a manner that is materially different from promises made in the version of the Privacy Policy under which it was
          collected without your consent.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          5. Updating Your Personal Information
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          You can contact us by emailing us at support@vidalhomecare.com and we will make good faith efforts to provide you
          with access to your Personal Information in order to correct, amend or delete it unless prohibited by law. We may
          decline to process requests if it would require effort disproportionate to the privacy risk, would violate the
          rights of others or would prejudice an ongoing legal proceeding or investigation. Because of the way we make
          back-up copies of our data, residual copies of the information may remain in our backup systems. We also may not be
          able to delete Personal Information that you have submitted through public activities, and even after removal and
          it may remain viewable in cached and archived locations or on other websites not within our control. We may charge
          a reasonable fee to access your Personal Information if to do so would require disproportionate effort. Updating or
          deletion of your Personal Information will not have any effect on other information that Vidal maintains.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          6. Security
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          We recognize that security is important and take reasonable measures to protect your Personal Information from
          loss, misuse or unauthorized access, disclosure, alteration or destruction. If you believe that the Personal
          Information you have provided us has been compromised, or if you become aware of a potential security vulnerability
          in our Platform, please contact us at support@vidalhomecare.com.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          7. Communicating with You
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          7.1. Emails and other notifications
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          You agree that Vidal may send you communications relating to your account and the Vidal services. These
          communications may be through email, telephone, SMS or notifications through applications. You can elect not to
          receive certain marketing messages from Vidal by email by following the instructions in the email or by contacting
          us, but you cannot elect not to receive administrative messages relating to your account or legal matters. You
          consent to receiving all legally-required notifications from Vidal through display on our website, through the
          email you provide to us, or through the email associated with your Vidal account, which may be the email associated
          with any of your third-party IM Websites or social networks through which you connect to Vidal (such as Facebook).
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          7.2. Comments and Questions
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          Vidal periodically reviews its privacy practices and this Privacy Policy. We encourage interested persons to raise
          any concerns using the contact information provided below and we will investigate and attempt to resolve any
          concerns or complaints.
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          Vidal Home Care 1-1801 Yonge St. Toronto, ON M5E 1W7
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          support@vidalhomecare.com
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          8. Children's Online Privacy Protection Act
        </Typography>
        <Typography color="textSecondary" className={`${text} ${dense}`}>
          This website is intended for use by persons over the age of 18. Vidal does not seek to collect information about
          children under the age of 18. No information should be submitted to or posted on this website by users under 18
          years of age. If you believe that we might have any information from a child under age 18, please contact us at
          support@vidalhomecare.com.
        </Typography>
        <Typography color="secondary" className={sub_header} gutterBottom>
          9. Do Not Track
        </Typography>
        <Typography color="textSecondary" className={text} gutterBottom>
          Vidal does not respond to Do Not Track (DNT) signals.
        </Typography>
      </Paper>
    </Box>
  );
}

export default Policy;
