/* Component for rendering the header and footer text for the search results page */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  header_container: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  header_text: {
    fontSize: "1.2rem",
    textAlign: "center",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem",
      textAlign: "left"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6rem"
    }
  },
  body_text: {
    fontSize: "1rem",
    textAlign: "center",
    margin: theme.spacing(4, 0, 8),
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
      textAlign: "left"
    }
  },
  day_container: {
    marginBottom: theme.spacing(5),
    "&:last-child": {
      marginBottom: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(7)
    }
  },
  btn: {
    margin: "auto",
    border: `1px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.bgcolor.main,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.bgcolor.main
    }
  },
  helper_text: {
    fontSize: "1.1rem",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem"
    }
  }
}));

function SearchResultsText(props) {
  const { length, reset, children } = props;
  const { container, header_container, header_text, body_text, btn, helper_text } = useStyles();

  return (
    <Box className={container}>
      <Box className={header_container}>
        <Typography variant="h6" color="primary" className={header_text}>
          {length} Match{length !== 1 ? "es" : ""}
        </Typography>
        <Button color="secondary" variant="outlined" size="large" className={btn} onClick={reset} startIcon={<SearchIcon />}>
          Refine Search
        </Button>
      </Box>
      {length === 0 && (
        <Typography color="textSecondary" className={body_text}>
          Unfortunately there is no Caregiver that meets your needs.
        </Typography>
      )}
      {children}
      <Typography className={helper_text} color="secondary">
        Still need assistance? <br></br> Call us at 1-888-97 VIDAL
      </Typography>
    </Box>
  );
}

export default SearchResultsText;
