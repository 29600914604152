import React from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";

const SHARE_LINK = "https://share.vidalhomecare.com/Ie4Eb/Rzp9cFrr9J";
const SHARE_BODY = `${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}
 has given you a FREE hour of Care (with 3 hour minimum Care Visit).%0D%0A
To claim your gift, sign up with Vidal Home Care now!%0D%0A
${SHARE_LINK}%0D%0A
Referral Code: ${localStorage.getItem("referralCode")}`;
const FB_ID = 315432065609284;

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    paddingBottom: theme.spacing(2)
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      textAlign: "left"
    }
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 5, 3, 4),
      marginBottom: theme.spacing(4)
    }
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      gridGap: theme.spacing(1, 3)
    }
  },
  info_box: {
    gridColumnStart: 2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(5)
    }
  },
  bold: {
    fontWeight: 700
  },
  text: {
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem"
    }
  },
  dark: {
    color: theme.palette.secondary.dark
  },
  input: {
    padding: theme.spacing(1.75),
    width: "80px",
    [theme.breakpoints.up("sm")]: {
      width: "200px"
    }
  },
  explain_text: {
    gridColumnStart: 2
  },
  dot: {
    marginTop: theme.spacing(1),
    width: "10px",
    height: "10px",
    backgroundColor: "#D8D8D8",
    borderRadius: "50%"
  },
  share_text: {
    marginBottom: theme.spacing(1),
    fontSize: "1.2rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
      fontSize: "1.5rem"
    }
  },
  btn_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      maxWidth: "580px",
      margin: "auto"
    }
  },
  btn: {
    width: "230px",
    height: "50px",
    borderRadius: "7px",
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      width: "280px",
      height: "70px",
      borderRadius: "15px",
      fontSize: "1.3rem"
    }
  },
  email: {
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.primary.main
    }
  },
  facebook: {
    color: "white",
    backgroundColor: "#3B5998",
    "&:hover": {
      color: "white",
      backgroundColor: "#3B5998"
    }
  }
}));

function Referrals() {
  const {
    container,
    header,
    paper,
    grid,
    info_box,
    bold,
    text,
    dark,
    input,
    explain_text,
    dot,
    share_text,
    btn_container,
    btn,
    email,
    facebook
  } = useStyles();

  const theme = useTheme();
  const CustomButton = withStyles({
    iconSizeMedium: {
      "& > *:first-child": {
        fontSize: "1.8rem",
        [theme.breakpoints.up("md")]: {
          fontSize: "2.7rem"
        }
      }
    }
  })(Button);

  return (
    <Box className={container}>
      <Typography color="secondary" className={header}>
        Referrals
      </Typography>
      <Paper className={paper}>
        <Box className={grid}>
          <Box className={info_box}>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <Typography className={`${bold} ${text} ${dark}`} style={{ marginRight: "10px" }}>
                Your Referral Code:
              </Typography>
              <Input disabled inputProps={{ className: `${input} ${text}` }} value={localStorage.getItem("referralCode")} />
            </Box>
            <Box display="flex">
              <Typography className={`${bold} ${text} ${dark}`} style={{ marginRight: "20px" }}>
                Free Care Hours Earned:
              </Typography>
              <Typography className={`${text} ${dark}`}>[#]</Typography>
            </Box>
          </Box>
          <Typography className={`${text} ${dark} ${explain_text}`}>How Referrals Work</Typography>
          <div className={dot}></div>
          <Typography color="textSecondary" className={text}>
            Every time a new Vidal user places a new Care Request with your referral code, they get 1 hour free (- deducted
            off of a Care Visit of 3 or more hours)
          </Typography>
          <div className={dot}></div>
          <Typography color="textSecondary" className={text}>
            You’ll accumulate 1 free hour in your account that will automatically apply toward your next Care Visit of 3 or
            more hours.
          </Typography>
          <div className={dot}></div>
          <Typography color="textSecondary" className={text}>
            Only 1 free hour will be applied per Care Visit. Accumulated free hours do not expire.
          </Typography>
        </Box>
      </Paper>
      <Typography className={share_text} color="primary" align="center">
        Share your Code
      </Typography>
      <Box className={btn_container}>
        <a href={`mailto:?body=${SHARE_BODY}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
          <Button variant="contained" className={`${btn} ${email}`}>
            Email Invite
          </Button>
        </a>
        <a
          href={`https://www.facebook.com/dialog/share?app_id=${FB_ID}&display=popup&href=${SHARE_LINK}&quote=${SHARE_BODY}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <CustomButton variant="contained" className={`${btn} ${facebook}`} startIcon={<FacebookIcon />}>
            Share on Facebook
          </CustomButton>
        </a>
      </Box>
    </Box>
  );
}

export default Referrals;
