/* Component for rendering the first visit upcoming visits page*/
import React from "react";
import { DAYS, MONTHS, UPCOMING_STATUS, DAY_IN_MS, getTimeString } from "Constants";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CalendarIcon from "assets/calendar-icon.svg";
import ViewDetailsButton from "./ViewDetailsButton";

const MINUTE_IN_MS = 1000 * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      alignItems: "initial"
    }
  },
  header: {
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: 600,
    textTransform: "uppercase",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left"
    }
  },
  time: {
    fontSize: "1.5rem"
  },
  icon: {
    color: theme.palette.primary.main,
    width: "40px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2)
    }
  },
  details: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  textbox: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0
    }
  },
  btn_container: {
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      alignSelf: "flex-end"
    }
  }
}));

function ImmediateVisit(props) {
  const { id, view } = props;
  const { container, header, time, icon, details, textbox, btn_container } = useStyles();

  const date = new Date(props.date.replace(" ", "T"));
  const start = getTimeString(props.start.split(" ")[1]);
  const end = getTimeString(props.end.split(" ")[1]);

  let timeRemaining = new Date(props.start.replace(" ", "T")) - Date.now();
  let timePeriod = "day";
  if (timeRemaining < MINUTE_IN_MS) {
    timeRemaining = "Less than 1 minute left";
  } else {
    if (timeRemaining < DAY_IN_MS) {
      if (timeRemaining < HOUR_IN_MS) {
        timeRemaining = Math.floor(timeRemaining / MINUTE_IN_MS);
        timePeriod = "minute";
      } else {
        timeRemaining = Math.floor(timeRemaining / HOUR_IN_MS);
        timePeriod = "hour";
      }
    } else {
      timeRemaining = Math.floor(timeRemaining / DAY_IN_MS);
    }
    timeRemaining = `${timeRemaining} more ${timePeriod}${timeRemaining !== 1 ? "s" : ""} left`;
  }

  return (
    <Box className={container}>
      <Typography className={header} color="secondary" gutterBottom>
        {timeRemaining}
      </Typography>
      <img src={CalendarIcon} className={icon} alt="Date" />
      <Box className={details}>
        <Box className={textbox}>
          <Typography color="secondary" className={time}>
            {DAYS[date.getDay()]}, {MONTHS[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
          </Typography>
          <Typography color="secondary" className={time}>
            {start} - {end}
          </Typography>
        </Box>
        <Box className={btn_container}>
          <ViewDetailsButton click={() => view(id, UPCOMING_STATUS)} message="View Care Details" />
        </Box>
      </Box>
    </Box>
  );
}

export default ImmediateVisit;
