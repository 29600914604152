import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import { RATING_PRECISION } from "Constants";

const useStyles = makeStyles(theme => ({
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2)
  },
  name_text: {
    fontWeight: 400
  },
  pic: {
    width: "125px",
    height: "125px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0, 2),
    [theme.breakpoints.up("md")]: {
      width: "200px",
      height: "200px",
      marginBottom: theme.spacing(4)
    }
  },
  rating_count: {
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
  snippet: {
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  }
}));

function IndividualCaregiverDetails(props) {
  const { click, avatar, name, rating, ratingCount, description, container } = props;
  const { details, name_text, pic, rating_count, snippet } = useStyles();

  return (
    <Box className={`${details} ${container}`}>
      <Avatar className={pic} src={avatar} />

      <Typography color="secondary" variant="h6">
        Caregiver
      </Typography>
      <Typography color="textSecondary" variant="h6" className={name_text}>
        {name}
      </Typography>

      <Box display="flex">
        <Box title={rating}>
          <Rating value={rating} readOnly precision={RATING_PRECISION} size="small" />
        </Box>
        <Typography color="secondary" className={rating_count} onClick={click}>
          ({ratingCount})
        </Typography>
      </Box>

      <Typography color="textSecondary" className={snippet}>
        {description}
      </Typography>
    </Box>
  );
}

export default IndividualCaregiverDetails;
