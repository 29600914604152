/* Component for rendering the form of the signup page */

import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import SignUpNavigation from "components/SignUpNavigation";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { SIGNUP_ROUTE, ADD_CARE_MYSELF_ROUTE, ADD_LOVED_ONE_ROUTE } from "Routes";
import { DASHBOARD_URL, MIN_PASSWORD_LENGTH } from "Constants";
import { IconButton } from "@material-ui/core";

const LAST_PAGE = 2;
const ACCOUNT_PAGE = 1;

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2),
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 6)
    }
  },

  form_container: {
    padding: "10% 0 5%",
    flexGrow: 1
  },

  header: {
    color: theme.palette.secondary.dark,
    margin: theme.spacing(2, 0)
  },
  input_container: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(3, 0, 1)
  },
  inputs: {
    width: "100%"
  },
  keyboard_btn: {
    color: theme.palette.secondary.dark
  },
  btn_group: {
    margin: `${theme.spacing(3)}px auto`,
    display: "block"
  },
  btn: {
    border: `2px solid ${theme.palette.secondary.dark}`
  },
  btn_active: {
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.secondary.dark,
    width: "50%",
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.dark
    }
  },
  btn_inactive: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.bgcolor.main,
    width: "50%",
    "&:hover": {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.bgcolor.main
    }
  },
  submit_btn: {
    display: "block",
    width: "80%",
    fontSize: "1.5rem",
    margin: `${theme.spacing(4)}px auto ${theme.spacing(1)}px`,
    padding: theme.spacing(1, 0),
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.bgcolor.main
    }
  },
  visibility_btn: {
    padding: 0
  }
}));

function SignUpForm(props) {
  const {
    container,
    form_container,
    header,
    input_container,
    inputs,
    keyboard_btn,
    btn,
    btn_group,
    btn_active,
    btn_inactive,
    submit_btn,
    visibility_btn
  } = useStyles();

  const [page, setPage] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmError] = useState("");
  const [isMyself, setMyself] = useState(null);
  const [lovedOneFirstName, setLovedFirstName] = useState("");
  const [lovedOneLastName, setLovedLastName] = useState("");
  const [DOB, setDOB] = useState(null);
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [id, setId] = useState(0);
  const [authKey, setAuthKey] = useState("");

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const lovedOneFirstNameRef = useRef();
  const lovedOneLastNameRef = useRef();

  const handleGenderChange = e => {
    setGender(e.target.value);
    setGenderError("");
  };

  const handleNext = (refs, setStates, e) => {
    e.preventDefault();

    setStates.forEach((set, index) => set(refs[index].current.value));
    if (page === ACCOUNT_PAGE) {
      let fail = false;
      if (passwordRef.current.value.length < MIN_PASSWORD_LENGTH) {
        setPasswordError(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
        fail = true;
      }
      if (confirmPasswordRef.current.value !== passwordRef.current.value) {
        setConfirmError("Passwords do not match");
        fail = true;
      }
      if (!fail) {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            repeat_password: confirmPasswordRef.current.value,
            phone: phoneRef.current.value
          })
        };

        fetch(SIGNUP_ROUTE, options)
          .then(res => {
            if (res.ok) {
              res.json().then(res => {
                if (res.status === "Fail") {
                  // Assume error was taken email
                  setEmailError("That email is already being used");
                } else {
                  const {
                    id,
                    auth_key,
                    first_name,
                    last_name,
                    profile_pic,
                    refrel_code,
                    tokenpayment,
                    email,
                    phone,
                    date_of_birth,
                    gender,
                    additional_note,
                    spoken_language,
                    is_vip
                  } = res.data.User;
                  localStorage.setItem("id", id);
                  localStorage.setItem("authKey", auth_key);
                  localStorage.setItem("first_name", first_name);
                  localStorage.setItem("last_name", last_name);
                  localStorage.setItem("profile_pic", profile_pic || "");
                  localStorage.setItem("referralCode", refrel_code);
                  localStorage.setItem("token", tokenpayment || "");
                  localStorage.setItem("email", email);
                  localStorage.setItem("phone", phone || "");
                  localStorage.setItem("birth", date_of_birth);
                  localStorage.setItem("gender", gender);
                  localStorage.setItem("note", additional_note || "");
                  localStorage.setItem("language", spoken_language || "");
                  localStorage.setItem("is_vip", is_vip);
                  setId(id);
                  setAuthKey(auth_key);
                  setPage(page + 1);
                }
              });
            }
          })
          .catch(err => console.error("Error:", err));
      }
      return;
    }
    setPage(page + 1);
  };

  const handlePrev = (page, refs, setStates) => () => {
    setStates.forEach((set, index) => set(refs[index].current.value));
    setPage(page - 1);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (gender === "") {
      setGenderError("Please select a gender");
      return;
    }

    const year = DOB.getFullYear();
    const month = DOB.getMonth() + 1;
    const day = DOB.getDate();
    if (day && month && year) {
      const url = isMyself ? ADD_CARE_MYSELF_ROUTE : ADD_LOVED_ONE_ROUTE;
      const body = {
        uid: id,
        auth_key: authKey,
        gender,
        date_of_birth: `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`
      };

      if (isMyself) {
        body["id"] = id;
        body["care_myself"] = 1;
      } else {
        body["user_id"] = id;
        body["first_name"] = lovedOneFirstNameRef.current.value;
        body["last_name"] = lovedOneLastNameRef.current.value;
      }

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };

      fetch(url, options)
        .then(res => {
          if (res.ok) {
            res.json().then(({ data }) => {
              localStorage.setItem("language", data.User.spoken_language || "");
              if (isMyself) {
                data = data.User;
                localStorage.setItem("birth", data.date_of_birth);
                localStorage.setItem("gender", data.gender);
              } else {
                data = data.LovedOne;
                localStorage.setItem("loved_first_name", data.first_name);
                localStorage.setItem("loved_last_name", data.last_name);
                localStorage.setItem("loved_birth", data.date_of_birth);
                localStorage.setItem("loved_gender", data.gender);
                localStorage.setItem("loved_profile_pic", data.profile_pic);
                localStorage.setItem("language", data.language || "");
                localStorage.setItem("loved_phone", data.phone || "");
                localStorage.setItem("note", data.additional_note || "");
                localStorage.setItem("loved_id", data.id);
              }
              props.history.push(DASHBOARD_URL);
            });
          }
        })
        .catch(err => console.error("Error:", err));
    }
  };

  useEffect(() => {
    if (page === ACCOUNT_PAGE) {
      emailRef.current.focus();
    }
  }, [page]);

  const createHeader = head => (
    <Typography variant="h6" className={header}>
      {head}
    </Typography>
  );

  const surroundInput = (input, key) => (
    <Paper key={key} className={input_container}>
      {input}
    </Paper>
  );

  const createInputs = (formInputs, types, refs, states, errors, setErrors, setVisibility, visibility) => {
    return formInputs.map((input, index) =>
      surroundInput(
        <TextField
          type={types[index]}
          inputRef={refs[index]}
          className={inputs}
          placeholder={input}
          defaultValue={states[index]}
          error
          helperText={errors && errors[index] ? errors[index] : ""}
          onChange={setErrors && setErrors[index] ? () => setErrors[index]("") : undefined}
          InputProps={{
            disableUnderline: true,
            endAdornment:
              setVisibility && setVisibility[index] ? (
                <IconButton
                  className={visibility_btn}
                  onClick={() => setVisibility[index](!visibility[index])}
                  disableTouchRipple
                >
                  {visibility[index] ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ) : undefined
          }}
          required
        />,
        input
      )
    );
  };

  let formInputs = [];
  let types = [];
  let refs = [];
  let states = [];
  let setStates = [];
  let body = [];

  switch (page) {
    case 1:
      formInputs = ["Email", "Phone Number", "Password", "Confirm Password"];
      types = ["email", "tel", showPassword ? "text" : "password", showConfirmPassword ? "text" : "password"];
      refs = [emailRef, phoneRef, passwordRef, confirmPasswordRef];
      states = [email, phone, password, confirmPassword];
      setStates = [setEmail, setPhone, setPassword, setConfirmPassword];
      const errors = [emailError, undefined, passwordError, confirmPasswordError];
      const setErrors = [setEmailError, undefined, setPasswordError, setConfirmError];
      const setVisibility = [false, false, setShowPassword, setShowConfirmPassword];
      const visibility = [undefined, undefined, showPassword, showConfirmPassword];
      body = (
        <>
          {createHeader("Your Vidal account details")}
          {createInputs(formInputs, types, refs, states, errors, setErrors, setVisibility, visibility)}
        </>
      );
      break;
    case 2:
      const buttons = (
        <ButtonGroup className={btn_group}>
          <Button className={`${btn} ${isMyself ? btn_active : btn_inactive}`} onClick={() => setMyself(true)}>
            Myself
          </Button>
          ,
          <Button className={`${btn} ${isMyself === false ? btn_active : btn_inactive}`} onClick={() => setMyself(false)}>
            A Loved One
          </Button>
        </ButtonGroup>
      );

      const DOBInput = surroundInput(
        <KeyboardDatePicker
          disableFuture
          disableToolbar
          KeyboardButtonProps={{ className: keyboard_btn }}
          okLabel={<Typography color="secondary">OK</Typography>}
          cancelLabel={<Typography color="secondary">Cancel</Typography>}
          maskChar="-"
          variant="dialog"
          className={inputs}
          label={DOB ? "YYYY/MM/DD" : ""}
          InputProps={{ disableUnderline: true, required: true, placeholder: "DOB" }}
          invalidDateMessage="Invalid Date"
          format="yyyy/MM/dd"
          openTo="year"
          views={["year", "month", "date"]}
          value={DOB}
          onChange={setDOB}
        />,
        "DOB"
      );

      const genderSelect = surroundInput(
        <TextField
          className={inputs}
          InputLabelProps={{ disableAnimation: true, shrink: false }}
          InputProps={{ disableUnderline: true }}
          SelectProps={{ displayEmpty: true }}
          select
          value={gender}
          error
          onChange={handleGenderChange}
          helperText={genderError}
          required
        >
          <MenuItem value="" disabled>
            <p style={{ margin: 0, color: "#B8B8B8" }}>Gender</p>
          </MenuItem>
          <MenuItem value="M">Male</MenuItem>
          <MenuItem value="F">Female</MenuItem>
        </TextField>,
        "Gender"
      );

      if (isMyself === false) {
        formInputs = ["First Name", "Last Name"];
        types = ["text", "text"];
        refs = [lovedOneFirstNameRef, lovedOneLastNameRef];
        states = [lovedOneFirstName, lovedOneLastName];
        setStates = [setLovedFirstName, setLovedLastName];
      }

      body = (
        <>
          {createHeader("Who will be receiving care:")}
          {buttons}
          {isMyself !== null && (
            <>
              {createHeader(`A few details about ${isMyself ? "you" : "your loved one"}:`)}
              {createInputs(formInputs, types, refs, states)}
              {DOBInput}
              {genderSelect}
              <Button className={`${btn} ${submit_btn}`} type="submit">
                Get Started Now
              </Button>
            </>
          )}
        </>
      );
      break;
    default:
      formInputs = ["First Name", "Last Name"];
      types = ["text", "text"];
      refs = [firstNameRef, lastNameRef];
      states = [firstName, lastName];
      setStates = [setFirstName, setLastName];
      body = (
        <>
          {createHeader("Create an account")}
          {createInputs(formInputs, types, refs, states)}
        </>
      );
  }

  return (
    <form className={container} onSubmit={e => (page !== LAST_PAGE ? handleNext(refs, setStates, e) : handleSubmit(e))}>
      <Box className={form_container}>{body}</Box>
      <SignUpNavigation
        elements={[page === ACCOUNT_PAGE, page === ACCOUNT_PAGE, page < LAST_PAGE]}
        refs={refs}
        prev={handlePrev(page, refs, setStates)}
      />
    </form>
  );
}

export default SignUpForm;
