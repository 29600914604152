/* Component for rendering the upcoming appointments in the my visits page */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Visit from "components/Visit";
import ViewDetailsButton from "components/ViewDetailsButton";
import { COMPLETED_STATUS } from "Constants";

const useStyles = makeStyles(theme => ({
  btn: {
    fontSize: "1rem",
    padding: theme.spacing(0.75, 4),
    marginTop: theme.spacing(0.75),
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    pointerEvents: "none"
  }
}));

function CompletedVisits(props) {
  const { data, view } = props;
  const { btn } = useStyles();

  return (
    <>
      {data.map(({ appointment: { id, rating, date, start, end } }) => (
        <Visit key={id} date={date} start={start} end={end} status="Completed">
          {rating && rating.length !== 0 ? (
            <Box display="flex" flexDirection="column">
              <ViewDetailsButton click={() => view(id, COMPLETED_STATUS)} />
              <Button className={btn}>Reviewed!</Button>
            </Box>
          ) : (
            <ViewDetailsButton click={() => view(id, COMPLETED_STATUS)} message="Leave Review" />
          )}
        </Visit>
      ))}
    </>
  );
}

export default CompletedVisits;
