const ROOT_URL = process.env.REACT_APP_API_URL;

export const LOGIN_ROUTE = ROOT_URL + "users/login";
export const SIGNUP_ROUTE = ROOT_URL + "users/caretaker";
export const ADD_CARE_MYSELF_ROUTE = ROOT_URL + "users/myself";
export const EDIT_USER_ROUTE = ROOT_URL + "users/edit";
export const ADD_LOVED_ONE_ROUTE = ROOT_URL + "users/addloveone";
export const EDIT_LOVED_ONE_ROUTE = ROOT_URL + "users/editloveone";
export const LOCATION_ROUTE = ROOT_URL + "users/locationlist";
export const ADD_LOCATION_ROUTE = ROOT_URL + "users/locationadd";
export const EDIT_LOCATION_ROUTE = ROOT_URL + "users/locationedit";
export const INDIVIDUAL_CAREGIVER_ROUTE = ROOT_URL + "users/view";
export const CAREGIVER_NAME_ROUTE = ROOT_URL + "users/caregiverName";
export const UPDATE_QB_ID_ROUTE = ROOT_URL + "users/updateqbid";
export const CHANGE_PASSWORD_ROUTE = ROOT_URL + "users/change_pwd";
export const FORGOT_PASSWORD_ROUTE = ROOT_URL + "users/forgetpwd";

export const LANGUAGES_ROUTE = ROOT_URL + "languages/lists";
export const SPECIALTIES_ROUTE = ROOT_URL + "specialists/lists";

export const SEARCH_CAREGIVERS_ROUTE = ROOT_URL + "appointments/suggestion_availability";
export const REVIEWS_ROUTE = ROOT_URL + "appointments/feedback_list";
export const MY_CAREGIVERS_ROUTE = ROOT_URL + "appointments/favouritelist";
export const FAVOURITE_CAREGIVER_ROUTE = ROOT_URL + "appointments/addfavourite";
export const UNFAVOURITE_CAREGIVER_ROUTE = ROOT_URL + "appointments/removefavourite";
export const PAYMENT_ROUTE = ROOT_URL + "appointments/saveCustomerStripeToken";
export const SCHEDULE_CARE_ROUTE = ROOT_URL + "appointments/create";
export const CANCEL_CARE_ROUTE = ROOT_URL + "appointments/cancel";
export const VISITS_ROUTE = ROOT_URL + "appointments/lists";
export const ADD_REVIEW_ROUTE = ROOT_URL + "appointments/feedback";
export const NOTIFICATIONS_ROUTE = ROOT_URL + "appointments/notificationlist";
export const NOTFICATION_DELETE_ROUTE = ROOT_URL + "appointments/notificationdelete";
export const POST_JOB_ROUTE = ROOT_URL + "appointments/post_job";

export const ADD_CHAT_ROUTE = ROOT_URL + "messages/chatadd";
export const GET_CHATS_ROUTE = ROOT_URL + "messages/lists";
