import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { INDIVIDUAL_CAREGIVER_ROUTE, REVIEWS_ROUTE, MY_CAREGIVERS_ROUTE } from "Routes";
import { CAREGIVER_URL } from "Constants";
import IndividualCaregiverDetails from "components/IndividualCaregiverDetails";
import CaregiverProfileTab from "components/CaregiverProfileTab";
import CaregiverReviewTab from "components/CaregiverReviewTab";
import FavouriteDialog from "components/FavouriteDialog";

const useStyles = makeStyles(theme => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    width: "95%",
    maxWidth: "1000px",
    margin: `${theme.spacing(3)}px auto`,
    columnGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "2fr 3fr"
    }
  },
  panel1: {
    maxHeight: "500px",
    maxWidth: "400px",
    margin: "0 auto",
    overflowY: "auto"
  },
  panel2: {
    display: "grid",
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
    gridRowGap: theme.spacing(1),
    gridTemplateRows: `50px ${450 - theme.spacing(1)}px`
  },
  paper_container: {
    height: "fit-content",
    maxHeight: "100%",
    overflowY: "auto",
    padding: theme.spacing(0, 2, 1, 2),
    boxSizing: "border-box"
  },
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    borderRadius: theme.spacing(3),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 2, 1, 5)
    }
  },
  label: {
    fontSize: "1.2rem"
  },
  btn: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    display: "block",
    width: "200px",
    fontWeight: 500,
    fontSize: "1rem",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(3, "auto"),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 2, 2, 5),
    },
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  }
}));

function IndividualCaregiver(props) {
  const id = props.match.params.id;
  const { grid, panel1, panel2, paper_container, paper, label, btn } = useStyles();

  const [userExists, setExists] = useState(null);
  const [caregiverInfo, setCaregiverInfo] = useState({});
  const [favouriteInfo, setFavouriteInfo] = useState({ loaded: false, id: null });
  const [tab, setTab] = useState(0);
  const [reviews, setReviews] = useState(null);
  const { name, rating, ratingCount, description, languages, experience, specialties, avatar } = caregiverInfo;

  // Get user details
  useEffect(() => {
    let active = true;
    fetch(`${INDIVIDUAL_CAREGIVER_ROUTE}/${id}`, {
      headers: { Uid: localStorage.getItem("id"), AuthKey: localStorage.getItem("authKey") }
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === "fail" && active) {
          setExists(false);
        } else if (active) {
          setExists(true);
          const { User, Languagemain, Specialistmain, RatingCount, AverageRating } = res.data;
          setCaregiverInfo({
            name: `${User.first_name} ${User.last_name}`,
            rating: Number(AverageRating),
            ratingCount: RatingCount,
            description: User.interesting_facts_about_your_self,
            languages: Languagemain ? Languagemain.map(({ Language }) => Language.language_name) : [],
            experience: User.years_of_experience,
            specialties: Specialistmain ? Specialistmain.map(({ Specialist }) => Specialist.name) : [],
            avatar: User.profile_pic || ""
          });
        }
      })
      .catch(err => console.error(err));
    return () => (active = false);
  }, [id]);

  // Get favourite status
  useEffect(() => {
    let active = true;
    if (!userExists) {
      return;
    }
    const body = new FormData();
    body.set("patient_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(MY_CAREGIVERS_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          const data = res.data.find(({ Doctor }) => Doctor.id === id);
          setFavouriteInfo({ loaded: true, id: data ? data.Favourite.id : null });
        }
      });
    return () => (active = false);
  }, [userExists, id]);

  // Get user reviews
  useEffect(() => {
    let active = true;
    if (!userExists) {
      return;
    }
    const body = new FormData();
    body.set("recepient_id", id);
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("uid", localStorage.getItem("id"));
    fetch(REVIEWS_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.status === "Fail") {
            // Caregiver has no reviews
            setReviews([]);
          } else {
            setReviews(
              res.data.map(
                ({ Appointment: { id, client_feedback, client_rating }, User: { first_name, last_name, profile_pic } }) => ({
                  id,
                  feedback: client_feedback,
                  rating: Number(client_rating),
                  name: `${first_name} ${last_name}`,
                  avatar: profile_pic
                })
              )
            );
          }
        }
      })
      .catch(err => console.error(err));
    return () => (active = false);
  }, [userExists, id]);

  if (userExists === null || !favouriteInfo.loaded) {
    return (
      <Typography variant="h4" color="textSecondary" align="center">
        Loading user
      </Typography>
    );
  } else if (!userExists) {
    return (
      <Typography variant="h4" color="textSecondary" align="center">
        User does not exist
      </Typography>
    );
  } else {
    return (
      <Box className={grid}>
        <IndividualCaregiverDetails
          click={() => setTab(1)}
          container={panel1}
          avatar={avatar}
          name={name}
          rating={rating}
          ratingCount={ratingCount}
          description={description}
        />

        <Box className={panel2}>
          <Tabs
            value={tab}
            indicatorColor="secondary"
            variant="fullWidth"
            textColor="secondary"
            onChange={(e, v) => setTab(v)}
          >
            <Tab label="Profile" className={label} />
            <Tab label="Reviews" className={label} />
          </Tabs>

          <Box className={paper_container}>
            <Paper className={paper} elevation={2}>
              {tab === 0 ? (
                <CaregiverProfileTab
                  id={id}
                  languages={languages}
                  experience={experience}
                  specialties={specialties}
                />
              ) : (
                  <CaregiverReviewTab reviews={reviews} name={name} />
                )}
            </Paper>
            {tab === 0 && <Link to={`${CAREGIVER_URL}/${id}/favourite`} style={{ textDecoration: "none" }}>
              <Button className={btn}>{favouriteInfo.id === null ? "Add to Favourites" : "Unfavourite"}</Button>
            </Link>}
          </Box>
        </Box>
        <Route
          path={`${CAREGIVER_URL}/${id}/favourite`}
          render={({ history }) => (
            <FavouriteDialog
              id={id}
              avatar={avatar}
              name={name}
              close={history.goBack}
              back={() => history.go(-2)}
              favouriteId={favouriteInfo.id}
              onFavourite={id => setFavouriteInfo({ loaded: true, id })}
              onUnFavourite={() => setFavouriteInfo({ loaded: true, id: null })}
            />
          )}
        />
      </Box>
    );
  }
}

export default IndividualCaregiver;
