/* Component for rendering a caregiver card in the search results page */

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CheckIcon from "@material-ui/icons/Check";
import { CAREGIVER_URL, RATING_PRECISION } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  head: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: theme.spacing(1),
      alignItems: "initial"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  name_container: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2)
    }
  },
  text: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left"
    }
  },
  head_text: {
    fontSize: "1.2rem"
  },
  body: {
    maxWidth: "450px",
    fontSize: "0.9rem",
    lineHeight: 1.8,
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "none",
      fontSize: "1rem",
      margin: 0
    }
  },
  btn: {
    margin: theme.spacing(1, 1, 0)
  },
  view_btn: {
    border: `1.5px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.bgcolor.main,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.bgcolor.main
    }
  },
  select_btn: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.primary.main
    }
  }
}));

function Caregiver(props) {
  const { id, rating, name, description, profile_pic, select } = props;
  const { container, head, avatar, body, name_container, text, head_text, btn, view_btn, select_btn } = useStyles();
  return (
    <Paper className={container} elevation={3}>
      <Box className={head}>
        <Avatar alt={name} src={profile_pic} className={avatar} />
        <Box className={name_container}>
          <Typography className={`${text} ${head_text}`} color="secondary">
            Caregiver
          </Typography>
          <Typography className={`${text} ${head_text}`} color="textSecondary">
            {name}
          </Typography>
        </Box>
        <Box title={Number(Number(rating).toFixed(2))}>
          <Rating value={Number(rating)} readOnly precision={RATING_PRECISION} />
        </Box>
      </Box>

      <Typography className={`${text} ${body}`}>{description}</Typography>

      <Box className={text}>
        <Link to={`${CAREGIVER_URL}/${id}`} style={{ textDecoration: "none" }}>
          <Button className={`${view_btn} ${btn}`} startIcon={<PersonOutlineOutlinedIcon />}>
            View Profile
          </Button>
        </Link>

        <Button className={`${select_btn} ${btn}`} onClick={select} startIcon={<CheckIcon />}>
          Select Caregiver
        </Button>
      </Box>
    </Paper>
  );
}

export default Caregiver;
