import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LOCATION_ROUTE, POST_JOB_ROUTE } from "Routes";
import { MAX_TIME_RANGE, TIME_SLOTS, CARE_RECIPIENT_URL } from "Constants";
import ScheduleIcon from "@material-ui/icons/Schedule";

// Ids of certain items in the location menu
const LOAD_ID = -1; // For loading locations...
const ADD_ID = -2; // For add location

const useStyles = makeStyles(theme => ({
  container: {
    width: "95%",
    maxWidth: "500px",
    margin: "auto",
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      maxWidth: "1000px"
    }
  },
  main_header: {
    marginBottom: theme.spacing(4),
    fontSize: "1.5rem",
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      fontSize: "2.4rem"
    }
  },
  success_msg: {
    fontSize: "calc(10px + 2vw)",
    textAlign: "center",
    marginTop: "20vh",
    padding: theme.spacing(2, 0)
  },
  wide: {
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 2"
    }
  },
  label: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginLeft: theme.spacing(4)
    }
  },
  input_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(4, 2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  textbox: {
    padding: theme.spacing(2, 4),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  textarea: {
    resize: "none",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "inherit",
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: "1rem"
  },
  link: {
    textDecoration: "none",
    width: "100%",
    color: theme.palette.primary.main
  },
  btn: {
    display: "block",
    width: "200px",
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(1.5, 0),
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

function ScheduleCare() {
  const {
    container,
    main_header,
    success_msg,
    wide,
    label,
    input_container,
    textbox,
    textarea,
    link,
    btn,
    icon
  } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [start, setStart] = useState(20);
  const [end, setEnd] = useState(22);
  const [timeError, setTimeError] = useState("");
  const [locationList, setLocationList] = useState([{ id: LOAD_ID, name: "Loading your Locations..." }]);
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const [isPosted, setIsPosted] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const dateRef = useRef();
  const descriptionRef = useRef();

  // Populate locations
  useEffect(() => {
    let active = true;
    const body = new FormData();
    body.set("patient_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("user_id", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(LOCATION_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (!res.data || res.data.length === 0) {
            setLocation(ADD_ID);
            setLocationList([]);
            return;
          }
          setLocation("");
          setLocationList(
            res.data.Location.map(({ id, address_label, street_address }) => ({
              id,
              name: address_label,
              address: street_address
            }))
          );
        }
      })
      .catch(err => console.error(err));

    return () => (active = false);
  }, []);

  const handleChange = (setState, setError) => e => {
    setState(e.target.value);
    setError("");
  };

  const handleTimeChange = isStart => e => {
    setTimeError("");
    isStart ? setStart(e.target.value) : setEnd(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const timeDifference = start < end ? end - start : TIME_SLOTS.length - start + end;
    let fail = false;
    if (timeDifference > MAX_TIME_RANGE) {
      setTimeError("The time period cannot be more than 12 hours");
      fail = true;
    }
    if (location === "" || location < 0) {
      setLocationError("Please select a location");
      fail = true;
    }
    if (fail) {
      return;
    }

    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("address", locationList.find(({ id }) => location === id).address);
    body.set("date", dateRef.current.value);
    body.set("start", `${start >> 1}:${start % 2 === 0 ? "00" : "30"}`);
    body.set("end", `${end >> 1}:${end % 2 === 0 ? "00" : "30"}`);
    body.set("description", descriptionRef.current.value);

    setIsPosting(true);

    fetch(POST_JOB_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "success") {
          setIsPosted(true);
        } else {
          setIsPosting(false);
          enqueueSnackbar("Something went wrong, please refresh the page and try again", { variant: "error" });
        }
      })
      .catch(err => {
        setIsPosting(false);
        console.error(err);
        enqueueSnackbar("Something went wrong, please refresh the page and try again", { variant: "error" });
      });
  };

  if (isPosted) {
    return (
      <Box className={container}>
        <Typography className={success_msg} color="secondary">
          Your job has been sucessfully posted!
        </Typography>
      </Box>
    );
  }

  return (
    <form className={container} onSubmit={handleSubmit}>
      <Typography variant="h4" className={main_header} color="primary">
        Post Job
      </Typography>

      <Box className={input_container}>
        <TextField
          className={wide}
          select
          variant="outlined"
          helperText={locationError}
          error={locationError.length !== 0}
          label="Location of Care"
          value={location}
          onChange={handleChange(setLocation, setLocationError)}
        >
          {locationList.map(({ id, name }) => (
            <MenuItem key={id} value={id} disabled={id === LOAD_ID}>
              {name}
            </MenuItem>
          ))}

          <MenuItem value={ADD_ID}>
            <Link to={`${CARE_RECIPIENT_URL}?rtsc=1`} className={link}>
              Add Location
            </Link>
          </MenuItem>
        </TextField>

        <TextField
          className={wide}
          variant="outlined"
          InputProps={{ required: true }}
          label="Days/Date"
          inputRef={dateRef}
        />

        <TextField
          select
          variant="outlined"
          label="Start Time"
          value={start}
          InputProps={{ startAdornment: <ScheduleIcon className={icon} color="disabled" fontSize="small" /> }}
          helperText={timeError}
          error={timeError.length !== 0}
          onChange={handleTimeChange(true)}
        >
          {TIME_SLOTS.map((slot, index) => (
            <MenuItem key={index} value={index}>
              {slot}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          variant="outlined"
          helperText={timeError}
          error={timeError.length !== 0}
          label="End Time"
          value={end}
          InputProps={{ startAdornment: <ScheduleIcon className={icon} color="disabled" fontSize="small" /> }}
          onChange={handleTimeChange(false)}
        >
          {TIME_SLOTS.map((slot, index) => (
            <MenuItem key={index} value={index}>
              {slot}
            </MenuItem>
          ))}
        </TextField>

        <Box className={wide}>
          <Typography color="primary" className={label} variant="h6">
            Description
          </Typography>

          <Paper className={textbox} elevation={2}>
            <TextareaAutosize
              className={textarea}
              rowsMin={5}
              required
              ref={descriptionRef}
              placeholder="Tell us about the care you need or leave special instructions."
            />
          </Paper>
        </Box>
      </Box>

      <Typography color="error" align="center">
        {timeError !== "" || locationError !== "" ? "Please correct your inputs and try again" : ""}
      </Typography>

      {isPosting ? (
        <Box marginX="auto" marginY={1} width="fit-content">
          <CircularProgress />
        </Box>
      ) : (
        <Button type="submit" className={btn}>
          Post Job
        </Button>
      )}
    </form>
  );
}

export default ScheduleCare;
