/* Component for rendering the side bar in the chat page */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { Dot } from "CustomComponents";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    overflowX: "auto",
    width: "100%",
    paddingBottom: theme.spacing(1),
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      margin: 0,
      maxHeight: "700px",
      overflowY: "auto"
    }
  },
  card: {
    padding: theme.spacing(1),
    cursor: "pointer",
    position: "relative",
    minWidth: "100px",
    maxWidth: "150px",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      minWidth: "initial",
      maxWidth: "none"
    }
  },
  active: {
    boxShadow: `0 0 3px 2px ${theme.palette.primary.main}`
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "initial"
    }
  },
  img: {
    width: "40px",
    height: "40px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: "60px",
      height: "60px",
      margin: theme.spacing(0, 2, 2, 0)
    }
  },
  nameText: {
    fontSize: "0.8rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem"
    }
  },
  chatText: {
    display: "none",
    maxHeight: "80px",
    overflow: "hidden",
    wordBreak: "break-word",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  }
}));

function ChatSidebar(props) {
  const { data, messages, click, chatId, newMessages } = props;
  const { container, card, active, content, img, nameText, chatText } = useStyles();

  const sortedIds = Object.entries(messages).sort(([_, m1], [__, m2]) => {
    const [date1, date2] = [m1.date, m2.date];
    if (!date1) {
      return 1;
    }
    if (!date2) {
      return -1;
    }
    return date2 - date1;
  }).map(([id]) => id);

  return (
    <Box className={container}>
      {sortedIds.map(id => (
        <Box key={id} paddingY={1} paddingX={2}>
          <Paper className={`${card} ${id === chatId ? active : ""}`} elevation={2} onClick={() => click(id)}>
            {newMessages[id] && <Dot top={-2} right={-2} />}
            <Box className={content}>
              <Avatar src={data[id].avatar} className={img} />
              <Box>
                <Typography color="secondary" className={nameText} noWrap>
                  {data[id].name}
                </Typography>
                <Typography variant="body2" color="textSecondary" className={chatText}>
                  {(messages[id] && messages[id].message) || ""}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
    </Box>
  );
}

export default ChatSidebar;
