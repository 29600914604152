/* Component for rendering the body of the chat page */

import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GET_CHATS_ROUTE, MY_CAREGIVERS_ROUTE, FAVOURITE_CAREGIVER_ROUTE } from "Routes";
import {
  QB,
  QB_ID,
  QB_KEY,
  QB_SECRET,
  QB_PASSWORD,
  CHATS_PER_FETCH,
  CARETAKER_FLAG,
  getChatHistoryPromise
} from "Constants";
import Chats from "components/Chats";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "1100px",
    margin: "auto",
    paddingBottom: theme.spacing(6)
  },
  header: {
    textAlign: "center",
    fontSize: "1.6rem",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
      textAlign: "left"
    }
  },
  sub_header: {
    fontSize: "1.1rem",
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem"
    }
  },
  empty_paper: {
    margin: theme.spacing(2, 2, 0),
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(20, 0)
    }
  }
}));

function MyChats(props) {
  const { onMount, onUnMount } = props;
  const { container, header, sub_header, empty_paper } = useStyles();

  const [chats, setChats] = useState({});
  const [userId, setUserId] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [messages, setMessages] = useState({});
  const [caregivers, setCaregivers] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  // When user favourites a caregiver
  const handleFavourite = id => () => {
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("patient_id", localStorage.getItem("id"));
    body.set("doctor_id", id);
    body.set("auth_key", localStorage.getItem("authKey"));
    fetch(FAVOURITE_CAREGIVER_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          // Assume Failure means already favourited
          enqueueSnackbar("Already Favourited", { variant: "error" });
        } else {
          enqueueSnackbar("Caregiver Favourited", { variant: "success" });
        }
        setCaregivers({ ...caregivers, [id]: true });
      })
      .catch(err => console.error(err));
  };

  // Returns true if the error is not empty or the result is undefined and sets loaded to null to indicate something went wrong
  const checkError = (active, error, result) => {
    if (error || !result) {
      console.log(error);
      if (active) {
        setLoaded(null);
      }
      return true;
    }
    return false;
  };

  // Update chat badge in navbar
  useEffect(() => {
    let active = true;
    if (active) {
      onMount();
    }
    return () => {
      active = false;
      onUnMount();
    };
  }, [onMount, onUnMount]);

  // Fetch caregivers
  useEffect(() => {
    let active = true;
    const body = new FormData();
    body.set("patient_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(MY_CAREGIVERS_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.data && res.data.length !== 0) {
            const caregiverIds = {};
            res.data.forEach(({ Doctor: { id } }) => (caregiverIds[id] = true));
            setCaregivers(caregiverIds);
          }
        }
      })
      .catch(err => console.error(err));
    return () => (active = false);
  }, []);

  // Fetch user dialogs
  useEffect(() => {
    let active = true;

    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("user_id", localStorage.getItem("id"));
    body.set("flag", CARETAKER_FLAG);

    fetch(GET_CHATS_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.status !== "Fail") {
            setChats(
              res.data.reduce((acc, { Doctor: { id, first_name, last_name, profile_pic, email, qb_id }, TblDailog: { dialog_id } }) => {
                acc[dialog_id] = {
                  caregiverId: id,
                  email,
                  name: `${first_name} ${last_name}`,
                  avatar: profile_pic,
                  receiver_id: Number(qb_id)
                };
                return acc;
              }, {}));
          }
          setLoaded(true);
        }
      })
      .catch(err => {
        console.error(err);
        setLoaded(null);
      });

    return () => (active = false);
  }, []);

  // Fetch chat history for each dialog
  useEffect(() => {
    if (Object.keys(chats).length === 0) {
      return;
    }

    let active = true;

    QB.init(QB_ID, QB_KEY, QB_SECRET);
    QB.createSession({ login: localStorage.getItem("email"), password: QB_PASSWORD }, (err, res) => {
      if (checkError(active, err, res)) {
        return;
      }

      QB.chat.connect({ userId: res.user_id, password: QB_PASSWORD }, err => {
        if (checkError(active, err, true)) {
          return;
        }

        const messageMap = {}; // Map dialog id to array of messages
        const promises = [];

        Object.keys(chats).forEach(id => {
          const params = { chat_dialog_id: id, sort_desc: "date_sent", limit: CHATS_PER_FETCH, skip: 0 };
          promises.push(getChatHistoryPromise(params, id));
        });

        Promise.all(promises)
          .then(results => {
            if (!active) {
              return;
            }
            results.forEach(result => {
              messageMap[result.id] = [];
              result.items.forEach(({ _id, message, sender_id, read_ids, recipient_id, date_sent }) =>
                messageMap[result.id].push({
                  id: _id,
                  message,
                  isMyself: sender_id === res.user_id,
                  read: sender_id === res.user_id ? true : read_ids.includes(recipient_id),
                  date: date_sent
                })
              );
            });
            setMessages(messageMap);
            setUserId(res.user_id);
          })
          .catch(err => {
            checkError(active, err, true);
          });
      });
    });
    return () => (active = false);
  }, [chats]);

  let body = "";
  if (Object.keys(chats).length === 0 || Object.keys(messages).length === 0) {
    if (loaded === null) {
      body = (
        <Typography color="error" align="center" className={sub_header}>
          Something went wrong, Please try refreshing the page
        </Typography>
      );
    } else if (loaded && Object.keys(chats).length === 0) {
      body = (
        <Typography color="textSecondary" align="center" className={sub_header}>
          You currently have no recent chats
        </Typography>
      );
    } else {
      body = (
        <Box textAlign="center">
          <Typography color="textSecondary" className={sub_header}>
            Loading your chats
          </Typography>
          <CircularProgress />
        </Box>
      );
    }
    body = (
      <Paper className={empty_paper} elevation={2}>
        {body}
      </Paper>
    );
  } else {
    body = <Chats data={chats} userId={userId} messages={messages} caregivers={caregivers} favourite={handleFavourite} />;
  }

  return (
    <Box className={container}>
      <Typography color="secondary" className={header}>
        Chat
      </Typography>
      {body}
    </Box>
  );
}

export default MyChats;
