import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    paddingBottom: theme.spacing(2)
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      textAlign: "left"
    }
  },
  paper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 4, 2, 8)
    }
  },
  sub_header: {
    fontSize: "1.1rem",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem"
    }
  },
  text: {
    fontSize: "0.9rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  }
}));

function FAQ() {
  const { container, header, paper, sub_header, text } = useStyles();

  return (
    <Box className={container}>
      <Typography color="secondary" className={header}>
        FAQs
      </Typography>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          1. What are the rates for Care Visits?
        </Typography>
        <Typography color="textSecondary" className={text}>
          The rates for Care Visits are: $29/hr for visits of 1 hour, $25/hr for visits of 2 hours, and $22/hr for visits for
          3 or more hours. Please note that the minimum charge for a Care Visit is 1 hour.
        </Typography>
      </Paper>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          2. What are the qualifications and screening requirements of the Caregivers?
        </Typography>
        <Typography color="textSecondary" className={text}>
          All Caregivers: Personal Support Workers and hold a certificate from a recognized Ontario PSW program that meets
          the educational standards established by the Ministry of Training, Colleges and Universities (MTCU),​ National
          Association of Career Colleges (NACC) or Ministry of and Long-Term Care (MOHLTC)/Ontario Community Support
          Association (OCSA) <br></br> <br></br>
        </Typography>
        <Typography color="textSecondary" align="left" className={text}>
          - Criminal Background and Vulnerable Sector Checks<br></br>
          <br></br>
        </Typography>
        <Typography color="textSecondary" align="left" className={text}>
          - Professional and General Liability Insurance<br></br>
          <br></br>
        </Typography>
        <Typography color="textSecondary" align="left" className={text}>
          - First Aid and CPR certified<br></br>
          <br></br>
        </Typography>
      </Paper>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          3. What if I need to cancel my Care Visit?
        </Typography>
        <Typography color="textSecondary" className={text}>
          If you give more than 24 hours notice, there is no penalty and you will not be charged for the Visit. However, if
          you give less than 24 hours notice you will be charged for the Care Visit.
        </Typography>
      </Paper>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          4. What if I forgot about my Care Visit and no one is home when the Caregiver arrives?
        </Typography>
        <Typography color="textSecondary" className={text}>
          The Caregiver will wait 10 minutes when arriving at your home. She/he will attempt to contact you and/or the Care
          Recipient before leaving. If there is still no response, the Caregiver will mark the Visit as a No Show and you
          will be charged for the Care Visit.
        </Typography>
      </Paper>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          5. What if I need to cancel the free consultation I requested?
        </Typography>
        <Typography color="textSecondary" className={text}>
          The same cancellation policy applies to the consultation as it does to the Care Visits; there are no penalties if
          you give us at least 24 hours notice. However, if you cancel with less than 24 hours notice, we will charge your
          credit card $50.
        </Typography>
      </Paper>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          6. How soon do I need to place a Care Request if I need care sooner rather than later?
        </Typography>
        <Typography color="textSecondary" className={text}>
          You will be able to place a Care Request as soon as 2 hours before the start time of the Care Visit. However, as
          the request is only sent to the Caregivers that you select, we encourage that you place your requests as soon as
          possible as the Caregiver’s availability may be limited. If this is the case, planning ahead of time will allow you
          to find another available Caregiver.
        </Typography>
      </Paper>

      <Paper className={paper} elevation={2}>
        <Typography color="primary" className={sub_header} gutterBottom>
          7. What if my Caregiver cancels on me?
        </Typography>
        <Typography color="textSecondary" className={text}>
          In the unlikely event that a Caregiver needs to cancel a Care Visit, we will notify you and work with you to send
          another suitable Caregiver.
        </Typography>
      </Paper>
    </Box>
  );
}

export default FAQ;
