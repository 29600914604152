import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        transition: "color, background-color 0.3s cubic-bezier(0.4, 0, 1, 1)",
        fontWeight: "normal"
      }
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          color: "",
          backgroundColor: ""
        }
      }
    },
    MuiSelect: {
      selectMenu: {
        "&:focus": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#70C3EC"
          },
          "&:hover fieldset": {
            borderColor: "#008DBC"
          },
          "&.Mui-focused fieldset": {
            borderWidth: 1
          }
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: "#70C3EC",
        "&$checked": {
          color: "#008DBC"
        }
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: "16px"
      }
    },
    MuiTab: {
      root: {
        textTransform: "none"
      }
    },
    MuiBadge: {
      badge: {
        fontSize: "10px",
        padding: "0 3px",
        minWidth: "12px",
        height: "12px"
      }
    },
    MuiAvatar: {
      root: {
        boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.2)"
      }
    }
  },
  typography: {
    fontFamily: '"Poppins"'
  },
  palette: {
    primary: {
      main: "#70C3EC"
    },
    secondary: {
      main: "#008CBE",
      dark: "#00678B"
    },
    success: {
      main: "#27C927"
    },
    error: {
      main: "#D8000C"
    },
    bgcolor: {
      main: "#FFFFFF"
    }
  }
});
