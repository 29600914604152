/* Component for rendering the body of the care recipient page when edit mode is on */

import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import { GrayTextField as TextField, GrayDatePicker } from "CustomComponents";
import LocationField from "components/LocationField";
import { EDIT_USER_ROUTE, EDIT_LOVED_ONE_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto"
  },
  paper: {
    padding: theme.spacing(4, 2, 3),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, "auto", 0),
      padding: theme.spacing(4, 5, 3)
    }
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 15px",
    alignItems: "center",
    gridGap: theme.spacing(0.75, 1),
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2, 4)
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto 400px 18px",
      margin: theme.spacing(4, 6)
    }
  },
  label: {
    fontWeight: 500,
    gridColumnStart: 1,
    justifySelf: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      justifySelf: "left",
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.up("md")]: {
      justifySelf: "left",
      marginLeft: 0,
      marginTop: 0
    }
  },
  input: {
    gridColumnStart: 1,
    [theme.breakpoints.up("md")]: {
      gridColumnStart: 2
    }
  },
  text_area: {
    resize: "none",
    padding: "14px",
    outline: "none",
    borderRadius: "5px",
    fontFamily: theme.typography.fontFamily,
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    "&:focus": {
      color: "black",
      borderColor: "black"
    }
  },
  location_text: {
    fontSize: "1.3rem",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 2"
    }
  },
  location_zip: {
    marginBottom: theme.spacing(4)
  },
  btn: {
    borderRadius: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.primary.main
    }
  },
  submit_btn: {
    fontSize: "0.8rem",
    margin: theme.spacing(4, "auto", 0),
    padding: theme.spacing(0.75, 3),
    display: "block",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4, 8, 0, "auto")
    }
  }
}));

function CareRecipientEdit(props) {
  const { head, avatar, isLovedOne, updateLocations, updateProfile } = props;
  const { container, paper, grid, label, input, text_area, location_text, location_zip, btn, submit_btn } = useStyles();

  let initialBirth = new Date(props.birth);
  if (initialBirth.getFullYear()) {
    initialBirth = new Date(props.birth.replace(/-/g, "/"));
  } else {
    initialBirth = null;
  }
  let initialGender = props.gender;
  initialGender = initialGender === "M" || initialGender === "F" ? initialGender : "";

  const getDeepCopy = array => {
    const newArray = [];
    array.forEach(entry => newArray.push({ ...entry }));
    return newArray;
  };

  const { enqueueSnackbar } = useSnackbar();
  const [birth, setBirth] = useState(initialBirth);
  const [gender, setGender] = useState(initialGender);
  const phoneRef = useRef();
  const languageRef = useRef();
  const noteRef = useRef();
  const [locations, setLocations] = useState(getDeepCopy(props.locations));

  useEffect(() => {
    let active = true;
    if (active && props.locations.length !== 0) {
      setLocations(getDeepCopy(props.locations));
    }
    return () => (active = false);
  }, [props.locations]);

  const addLocation = () => {
    const newLocation = { id: null, name: "", street: "", zip: "", province: "", city: "", apartment: "" };
    setLocations([...locations, newLocation]);
  };

  const deleteLocation = index => () => {
    setLocations(locations.slice(0, index).concat(locations.slice(index + 1)));
  };

  const handleUserEdit = () => {
    const body = new FormData();
    const url = isLovedOne ? EDIT_LOVED_ONE_ROUTE : EDIT_USER_ROUTE;
    if (!birth || !birth.getFullYear()) {
      return;
    }
    // Check if any values have changed and set them if they have
    if (birth.getTime() !== initialBirth.getTime()) {
      const year = birth.getFullYear();
      const month = birth.getMonth() + 1;
      const day = birth.getDate();
      body.set("date_of_birth", `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`);
    }
    gender !== initialGender && (gender === "M" || gender === "F") && body.set("gender", gender);
    const phone = phoneRef.current.value;
    phone !== props.phone && body.set("phone", phone);
    const language = languageRef.current.value;
    language !== props.language && body.set(isLovedOne ? "language" : "spoken_language", language);
    const note = noteRef.current.value;
    note !== props.note && body.set("additional_note", note);
    avatar && body.set("profile_pic", avatar);

    // Check if form data is non empty
    let hasChanged = false;
    for (const key of body.keys()) {
      if (key) {
        hasChanged = true;
        break;
      }
    }

    if (!hasChanged) {
      return true;
    }

    body.set("id", localStorage.getItem(isLovedOne ? "loved_id" : "id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(url, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          enqueueSnackbar("Account update failed, please try again", { variant: "error" });
          return;
        }

        // Update local storage
        const data = isLovedOne ? res.data.LovedOne : res.data.User;
        const prefix = isLovedOne ? "loved_" : "";
        localStorage.setItem(prefix + "birth", data.date_of_birth);
        localStorage.setItem(prefix + "gender", data.gender);
        localStorage.setItem(prefix + "phone", data.phone);
        localStorage.setItem("note", data.additional_note || "");
        localStorage.setItem("language", isLovedOne ? data.language || "" : data.spoken_language || "");
        localStorage.setItem(prefix + "profile_pic", data.profile_pic || "");
        enqueueSnackbar("Account updated", { variant: "success" });
        updateProfile();
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Account update failed, please try again", { variant: "error" });
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const userChanged = handleUserEdit();
    updateLocations(locations, userChanged);
  };

  return (
    <form onSubmit={handleSubmit} className={container}>
      <Paper className={paper} elevation={2}>
        {head}

        <Box className={grid}>
          <Typography color="secondary" className={label}>
            Birthday
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GrayDatePicker
              className={input}
              disableFuture
              disableToolbar
              okLabel={<Typography color="secondary">OK</Typography>}
              cancelLabel={<Typography color="secondary">Cancel</Typography>}
              maskChar="-"
              variant="dialog"
              InputProps={{ required: true, id: "Birth date" }}
              inputVariant="outlined"
              invalidDateMessage="Invalid Date"
              minDateMessage=""
              format="yyyy/MM/dd"
              openTo="year"
              views={["year", "month", "date"]}
              value={birth}
              onChange={setBirth}
            />
          </MuiPickersUtilsProvider>

          <Typography color="secondary" className={label}>
            Gender
          </Typography>
          <TextField
            className={input}
            variant="outlined"
            SelectProps={{ id: "Gender", displayEmpty: true }}
            select
            value={gender}
            onChange={e => setGender(e.target.value)}
          >
            <MenuItem value="" disabled>
              Unselected
            </MenuItem>
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="F">Female</MenuItem>
          </TextField>

          <Typography color="secondary" className={label}>
            Phone Number
          </Typography>
          <TextField variant="outlined" defaultValue={props.phone} inputRef={phoneRef} className={input} />

          <Typography color="secondary" className={label}>
            Language Preference(s)
          </Typography>
          <TextField variant="outlined" defaultValue={props.language} inputRef={languageRef} className={input} />

          <Typography color="secondary" className={label}>
            Additional Notes
          </Typography>
          <TextareaAutosize
            className={`${input} ${text_area}`}
            rowsMin={3}
            rowsMax={6}
            defaultValue={props.note}
            ref={noteRef}
          />

          <Typography color="secondary" className={`${label} ${location_text}`}>
            Locations
          </Typography>

          {locations.map((location, index) => (
            <LocationField
              key={index}
              index={index}
              location={location}
              remove={deleteLocation}
              classes={{ location_zip, input, label }}
            />
          ))}

          <Button className={`${btn} ${input}`} onClick={addLocation}>
            Add Care Visit Location
          </Button>
        </Box>
      </Paper>

      <Button className={`${btn} ${submit_btn}`} type="submit">
        Save Changes
      </Button>
    </form>
  );
}

export default CareRecipientEdit;
