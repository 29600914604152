/* Component that renders one of the visit tabs and sends the data in a paginated manner */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ImmediateVisit from "components/ImmediateVisit";
import RequestedVisits from "components/RequestedVisits";
import UpcomingVisits from "components/UpcomingVisits";
import CompletedVisits from "components/CompletedVisits";
import VisitDialog from "components/VisitDialog";
import { REQUESTED_STATUS, UPCOMING_STATUS } from "Constants";

const VISITS_PER_PAGE = 3;
// Controls the size of the page buttons
// Works best when even for now
const MAX_PAGE_OFFSET = 2;

const useStyles = makeStyles(theme => ({
  btn: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    fontSize: "1.25rem",
    fontFamily: theme.typography.fontFamily,
    padding: 0,
    margin: theme.spacing(0, 1),
    "&:hover": {
      color: "",
      backgroundColor: ""
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 2)
    }
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.bgcolor.main
  },
  arrow_btn: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.bgcolor.main,
    color: theme.palette.secondary.dark
  }
}));

function PaginateVisits(props) {
  const { data, variant, cancel, view, close, submit, dialogInfo } = props;
  const { btn, active, arrow_btn } = useStyles();

  const [page, setPage] = useState(0);

  // Reset page on tab change
  useEffect(() => {
    setPage(0);
  }, [variant]);

  const handleNext = () => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const handleCancel = async (id) => {
    const oldLength = data.length;
    const status = await cancel(id);
    if (status) {
      // User is on last page with one visit only in the page
      if (maxPage !== 0 && page === maxPage && oldLength === page * VISITS_PER_PAGE + 1) {
        setPage(page - 1);
      }
    }
  };

  const maxPage = Math.ceil(data.length / VISITS_PER_PAGE) - 1;

  // Compute page buttons to render
  const pageBtns = [page];
  for (let i = 1; i <= MAX_PAGE_OFFSET && pageBtns.length <= MAX_PAGE_OFFSET; i++) {
    if (page + i <= maxPage) {
      pageBtns.push(page + i);
    }
    if (page - i >= 0) {
      pageBtns.unshift(page - i);
    }
  }

  const pagedData = data.slice(page * VISITS_PER_PAGE, (page + 1) * VISITS_PER_PAGE);

  let visits = "";
  if (variant === REQUESTED_STATUS) {
    visits = <RequestedVisits data={pagedData} cancel={handleCancel} view={view} />;
  } else if (variant === UPCOMING_STATUS) {
    // Render the first visit with the special component
    if (page === 0) {
      const [
        {
          appointment: { id: firstId, date: firstDate, start: firstStart, end: firstEnd }
        },
        ...rest
      ] = pagedData;
      visits = (
        <>
          <ImmediateVisit id={firstId} date={firstDate} start={firstStart} end={firstEnd} view={view} />
          <UpcomingVisits data={[...rest]} view={view} />
        </>
      );
    } else {
      visits = <UpcomingVisits data={pagedData} view={view} />;
    }
  } else {
    visits = <CompletedVisits data={pagedData} view={view} />;
  }

  // Notice I put index as key instead of pageNum because it makes the render transition more smooth
  return (
    <>
      <VisitDialog {...dialogInfo} close={close} cancel={handleCancel} submit={submit} />
      {visits}
      <Box marginLeft="auto" width="fit-content">
        {pageBtns.map((pageNum, index) => (
          <IconButton
            key={index}
            onClick={() => setPage(pageNum)}
            className={`${btn} ${pageNum === page ? active : ""}`}
            disableTouchRipple
          >
            {pageNum + 1}
          </IconButton>
        ))}
        {maxPage !== 0 && (
          <IconButton className={`${btn} ${arrow_btn}`} disabled={page === maxPage} onClick={handleNext}>
            <KeyboardArrowRightIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}

export default PaginateVisits;
