/* Component for rendering the navbar on every page except login/signup */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Logo from "assets/logo-small.png";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BellIcon from "assets/bell.svg";
import BellActiveIcon from "assets/bell-active.svg";
import {
  DASHBOARD_URL,
  LOGIN_URL,
  MY_CAREGIVERS_URL,
  PAYMENT_URL,
  SCHEDULE_URL,
  VISITS_URL,
  CHAT_URL,
  PROFILE_URL,
  CARE_RECIPIENT_URL,
  NOTIFICATIONS_URL,
  REFERRALS_URL,
  POST_JOB_URL,
  FAQ_URL
} from "Constants";

const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor: theme.palette.bgcolor.main,
    padding: theme.spacing(1.5, 3, 3)
  },
  menu: {
    padding: theme.spacing(1)
  },
  expandIcon: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
      marginRight: theme.spacing(2)
    }
  },
  menuBadge: {
    display: "initial",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  menuIcon: {
    fontSize: "1.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem"
    }
  },
  name_text: {
    maxWidth: "80px",
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      maxWidth: "120px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
      marginLeft: theme.spacing(2),
      maxWidth: "200px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "400px"
    }
  },
  profile: {
    width: "30px",
    height: "30px",
    [theme.breakpoints.up("sm")]: {
      width: "40px",
      height: "40px"
    },
    [theme.breakpoints.up("md")]: {
      width: "50px",
      height: "50px"
    }
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main
  },
  nav_link: {
    textDecoration: "none",
    padding: theme.spacing(1, 2),
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "initial"
    }
  },
  nav_content: {
    color: "#767676",
    fontWeight: 500
  },
  badge: {
    margin: "4px 3px 0 0"
  },
  bell_icon: {
    padding: theme.spacing(0, 2)
  },
  active: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`
  },
  img: {
    width: "15px",
    height: "15px",
    [theme.breakpoints.up("sm")]: {
      width: "20px",
      height: "20px"
    }
  }
}));

function MainNavbar(props) {
  const {
    toolbar,
    link,
    nav_link,
    nav_content,
    badge,
    bell_icon,
    active,
    img,
    menu,
    expandIcon,
    menuBadge,
    menuIcon,
    name_text,
    profile
  } = useStyles();
  const { fields, history, path, badgeNum, showChatBadge } = props;
  const { name, avatar } = fields;

  const [expandAnchor, setExpandAnchor] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleLogout = () => {
    localStorage.clear();
    history.push(LOGIN_URL);
  };

  const navLinks = [SCHEDULE_URL, VISITS_URL];
  const navLinkContent = ["Get Care", "My Visits"];

  const links = [
    DASHBOARD_URL,
    MY_CAREGIVERS_URL,
    CARE_RECIPIENT_URL,
    PROFILE_URL,
    PAYMENT_URL,
    POST_JOB_URL,
    REFERRALS_URL,
    FAQ_URL
  ];
  const linkContent = [
    "Find Caregivers",
    "My Caregivers",
    "My Care Recipient",
    "My Account Details",
    "My Payment Information",
    "Post Job",
    "Referrals",
    "FAQs"
  ];

  const disabledNavLinks = navLinks.map(link => link === path);
  const disabledLinks = links.map(link => link === path);

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Toolbar className={toolbar}>
        <Box flexGrow={1} display="flex" alignSelf="center">
          <img src={Logo} className={profile} alt="Logo" />
        </Box>

        {navLinks.map((link, index) => (
          <Link key={index} to={link} className={`${nav_link} ${path === link ? active : ""}`}>
            <Typography className={nav_content}>{navLinkContent[index]}</Typography>
          </Link>
        ))}

        <Link to={CHAT_URL} className={`${nav_link} ${path === CHAT_URL ? active : ""}`}>
          <Badge badgeContent="new" color="error" invisible={!showChatBadge}>
            <Box>
              <Typography className={nav_content}>Chat</Typography>
            </Box>
          </Badge>
        </Link>

        <Badge variant="dot" color="error" overlap="circle" className={menuBadge} invisible={!showChatBadge}>
          <MenuIcon
            color="secondary"
            className={menuIcon}
            onClick={e => setMenuAnchor(e.currentTarget)}
            aria-haspopup="true"
          />
        </Badge>

        <Popover
          classes={{ paper: menu }}
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          open={menuAnchor != null}
          onClose={() => setMenuAnchor(null)}
        >
          {navLinkContent.map((content, index) => (
            <Link key={index} to={navLinks[index]} className={link} onClick={() => setMenuAnchor(null)}>
              <MenuItem disabled={disabledNavLinks[index]}>{content}</MenuItem>
            </Link>
          ))}

          <Link to={CHAT_URL} className={link} onClick={() => setMenuAnchor(null)}>
            <MenuItem disabled={path === CHAT_URL}>
              <Badge badgeContent="new" color="error" invisible={!showChatBadge}>
                Chat
              </Badge>
            </MenuItem>
          </Link>
        </Popover>

        <Link to={NOTIFICATIONS_URL} className={bell_icon}>
          <Badge badgeContent={badgeNum} color="error" classes={{ badge }}>
            <img src={path === NOTIFICATIONS_URL ? BellActiveIcon : BellIcon} className={img} alt="Notification" />
          </Badge>
        </Link>

        <Typography variant="h6" className={name_text} color="secondary" noWrap>
          {name}
        </Typography>

        <ExpandMoreIcon
          color="secondary"
          className={expandIcon}
          onClick={e => setExpandAnchor(e.currentTarget)}
          aria-haspopup="true"
        />

        <Avatar className={profile} src={avatar} />

        <Popover
          classes={{ paper: menu }}
          anchorEl={expandAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={expandAnchor != null}
          onClose={() => setExpandAnchor(null)}
        >
          {linkContent.map((content, index) => (
            <Link key={index} to={links[index]} className={link} onClick={() => setExpandAnchor(null)}>
              <MenuItem disabled={disabledLinks[index]}>{content}</MenuItem>
            </Link>
          ))}

          <MenuItem onClick={handleLogout} className={link}>
            Sign Out
          </MenuItem>
        </Popover>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(
  MainNavbar,
  (prev, next) =>
    prev.path === next.path &&
    prev.fields === next.fields &&
    prev.badgeNum === next.badgeNum &&
    prev.showChatBadge === next.showChatBadge
);
