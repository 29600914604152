/* Component for rendering the find compatible caregiver form in the dashboard */

import React, { useState, useRef, useEffect } from "react";
import { Route } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Field from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { LANGUAGES_ROUTE, SPECIALTIES_ROUTE } from "Routes";
import { MAX_TIME_RANGE, TIME_SLOTS, SEARCH_RESULTS_URL } from "Constants";
import SearchResults from "components/SearchResults";
import GeoAutoComplete from "components/GeoAutocomplete";
import Searchbar from "components/Searchbar";

const ANY_LANGUAGE_ID = 13;
const geocoder = new window.google.maps.Geocoder();

const useStyles = makeStyles(theme => ({
  container: {
    width: "95%",
    maxWidth: "800px",
    margin: theme.spacing(2, "auto", 0),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: "80%"
    }
  },
  input_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  main_header: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.6rem",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      fontSize: "2.5rem"
    }
  },
  sub_header: {
    fontSize: "1.2rem",
    margin: theme.spacing(2, 0),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem"
    }
  },
  wide_input: {
    gridColumn: "span 1",
    [theme.breakpoints.up("sm")]: {
      gridColumn: "span 2"
    }
  },
  specialties_container: {
    display: "flex",
    marginTop: theme.spacing(-4),
    flexDirection: "column"
  },
  days_container: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    justifySelf: "center",
    [theme.breakpoints.up("sm")]: {
      columnGap: "min(40px, 5vw)"
    }
  },
  checkbox: {
    width: "fit-content",
    margin: 0,
    color: theme.palette.text.secondary
  },
  primary: {
    color: theme.palette.primary.main
  },
  btn: {
    display: "block",
    width: "200px",
    margin: `${theme.spacing(2)}px auto`,
    padding: theme.spacing(1, 0),
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  }
}));

const TextField = withStyles(theme => ({
  root: {
    "& .MuiOutlinedInput-root": {
      color: theme.palette.text.secondary
    }
  }
}))(Field);

function SearchCaregivers(props) {
  const { history } = props;
  const {
    container,
    main_header,
    sub_header,
    wide_input,
    input_container,
    specialties_container,
    days_container,
    checkbox,
    primary,
    btn
  } = useStyles();

  const [languageList, setLanguageList] = useState([]);
  const [language, setLanguage] = useState("");
  const [gender, setGender] = useState("B");
  const [specialties, setSpecialties] = useState({});
  const [specialtyList, setSpecialtyList] = useState([]);
  const [days, setDays] = useState([false, false, false, false, false, false, false]);
  const [dayError, setDayError] = useState("");
  const [start, setStart] = useState(20);
  const [end, setEnd] = useState(22);
  const [timeError, setTimeError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const addressRef = useRef();

  // Populate languages and specialties
  useEffect(() => {
    let active = true;
    const headers = { Uid: localStorage.getItem("id"), AuthKey: localStorage.getItem("authKey") };
    fetch(LANGUAGES_ROUTE, { headers }).then(res =>
      res.json().then(({ data }) => {
        if (active) {
          setLanguageList(
            data
              .map(({ Language: { id, language_name } }) => [id, language_name])
              .sort((lang1, lang2) => {
                if (Number(lang1[0]) === ANY_LANGUAGE_ID) {
                  return -1;
                } else if (Number(lang2[0]) === ANY_LANGUAGE_ID) {
                  return 1;
                }
                return lang1[1] > lang2[1] ? 1 : -1;
              })
          );
          setLanguage(ANY_LANGUAGE_ID);
        }
      })
    );

    fetch(SPECIALTIES_ROUTE, { headers }).then(res =>
      res.json().then(({ data }) => {
        if (active) {
          setSpecialtyList(
            data.map(({ Specialist: { id, name } }) => [id, name]).sort((spec1, spec2) => (spec1[1] > spec2[1] ? 1 : -1))
          );
        }
      })
    );
    return () => (active = false);
  }, []);

  const handleChange = setState => e => setState(e.target.value);

  const handleSpecialtyChange = illnessID => () => setSpecialties({ ...specialties, [illnessID]: !specialties[illnessID] });

  const handleDayChange = index => () => {
    setDayError("");
    setDays(days.slice(0, index).concat(!days[index], days.slice(index + 1)));
  };

  const handleTimeChange = isStart => e => {
    setTimeError("");
    isStart ? setStart(e.target.value) : setEnd(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let fail = false;
    let lat = 0;
    let lng = 0;

    const address = addressRef.current.value;
    if (address === "") {
      setAddressError("Please fill out an address");
      fail = true;
    }
    if (days.findIndex(day => day) === -1) {
      setDayError("Please select at least one day");
      fail = true;
    }
    const timeDifference = start < end ? end - start : TIME_SLOTS.length - start + end;
    if (timeDifference > MAX_TIME_RANGE) {
      setTimeError("The time period cannot be more than 12 hours");
      fail = true;
    }
    if (fail) {
      return;
    }

    // Preserve autocomplete value
    setAddress(address);

    // Geocode and fetch results
    geocoder.geocode({ address }, (place, status) => {
      if (status !== "OK" || place.length === 0) {
        setAddressError("Address not found");
      } else if (place.length > 1) {
        setAddressError("Multiple addresses found. Please include city, province/state, and country");
      } else if (!place[0].geometry) {
        setAddressError("Address not found");
      } else {
        lat = place[0].geometry.location.lat();
        lng = place[0].geometry.location.lng();
        const specialtiesString = Object.keys(specialties)
          .filter(illness => specialties[illness])
          .join(",");
        const params = `lat=${lat}&lng=${lng}&lang=${language}&gender=${gender}&special=${specialtiesString}&avail=${days
          .map((value, index) => (days[(index + 6) % 7] ? 1 : 0))
          .join(",")}&start=${start / 2}&end=${end / 2}`;

        history.push(`${SEARCH_RESULTS_URL}?${params}`);
      }
    });
  };

  const showResults = window.location.pathname === SEARCH_RESULTS_URL;

  return (
    <Box className={container}>
      <Searchbar history={history} />
      <Route path={SEARCH_RESULTS_URL} component={SearchResults} />
      {!showResults && (
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" className={main_header} color="primary">
            Find a
          </Typography>
          <Typography variant="h4" className={main_header} color="primary">
            compatible Caregiver
          </Typography>

          <Typography varaint="p" className={sub_header}>
            Let us match you with a Caregiver who best suits your needs. Please use filters below.
          </Typography>

          <Box className={input_container}>
            <GeoAutoComplete
              inputRef={addressRef}
              error={addressError}
              change={() => setAddressError("")}
              mainClass={wide_input}
              defaultValue={address}
            />

            <TextField select variant="outlined" label="Language" value={language} onChange={handleChange(setLanguage)}>
              {languageList.map(([id, name]) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>

            <TextField select variant="outlined" label="Gender" value={gender} onChange={handleChange(setGender)}>
              <MenuItem value="B">Male or Female</MenuItem>
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </TextField>

            <Box>
              <Typography variant="h6" className={wide_input} color="primary">
                Days
              </Typography>

              <Typography variant="caption" color="error">
                {dayError}
              </Typography>
            </Box>

            <Box className={`${days_container} ${wide_input}`}>
              {["M", "T", "W", "T", "F", "S", "S"].map((day, index) => (
                <FormControlLabel
                  key={index}
                  labelPlacement="top"
                  className={`${checkbox} ${primary}`}
                  control={
                    <Checkbox checked={days[index] === true} name={day} color="primary" onChange={handleDayChange(index)} />
                  }
                  label={day}
                />
              ))}
            </Box>

            <TextField
              select
              variant="outlined"
              label="Start Time"
              value={start}
              helperText={timeError}
              error={timeError.length !== 0}
              onChange={handleTimeChange(true)}
            >
              {TIME_SLOTS.map((slot, index) => (
                <MenuItem key={index} value={index}>
                  {slot}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              variant="outlined"
              helperText={timeError}
              error={timeError.length !== 0}
              label="End Time"
              value={end}
              onChange={handleTimeChange(false)}
            >
              {TIME_SLOTS.map((slot, index) => (
                <MenuItem key={index} value={index}>
                  {slot}
                </MenuItem>
              ))}
            </TextField>

            <Typography variant="h6" className={wide_input} color="primary">
              Specialties
            </Typography>

            <Box className={`${specialties_container} ${wide_input}`}>
              {specialtyList.map(([id, name]) => (
                <FormControlLabel
                  key={id}
                  className={checkbox}
                  control={
                    <Checkbox
                      checked={specialties[id] === true}
                      name={name}
                      color="primary"
                      onChange={handleSpecialtyChange(id)}
                    />
                  }
                  label={name}
                />
              ))}
            </Box>
          </Box>

          <Typography color="error" align="center">
            {addressError !== "" || dayError !== "" || timeError !== "" ? "Please correct your filters and try again" : ""}
          </Typography>

          <Button type="submit" className={btn}>
            Explore Caregivers
          </Button>
        </form>
      )}
    </Box>
  );
}

export default SearchCaregivers;
