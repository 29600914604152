/* Component that renders the list of notifications in a paginated manner in the notifications page */

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Notification from "components/Notification";
import VisitDialog from "components/VisitDialog";
import { CANCEL_CARE_ROUTE } from "Routes";

const NOTIFICATIONS_PER_PAGE = 3;
// Controls the size of the page buttons
// Works best when even for now
const MAX_PAGE_OFFSET = 2;

const useStyles = makeStyles(theme => ({
  btn: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    fontSize: "1.25rem",
    fontFamily: theme.typography.fontFamily,
    padding: 0,
    margin: theme.spacing(0, 1),
    "&:hover": {
      color: "",
      backgroundColor: ""
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 2)
    }
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.bgcolor.main
  },
  arrow_btn: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.bgcolor.main,
    color: theme.palette.secondary.dark
  }
}));

function PaginateNotifications(props) {
  const { data, remove, cancel } = props;
  const { btn, active, arrow_btn } = useStyles();

  const [page, setPage] = useState(0);
  const [dialogInfo, setDialogInfo] = useState({ open: false });
  const [update, setUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const maxPage = Math.ceil(data.length / NOTIFICATIONS_PER_PAGE) - 1;

  // Compute page buttons to render
  const pageBtns = [page];
  for (let i = 1; i <= MAX_PAGE_OFFSET && pageBtns.length <= MAX_PAGE_OFFSET; i++) {
    if (page + i <= maxPage) {
      pageBtns.push(page + i);
    }
    if (page - i >= 0) {
      pageBtns.unshift(page - i);
    }
  }

  const pagedData = data.slice(page * NOTIFICATIONS_PER_PAGE, (page + 1) * NOTIFICATIONS_PER_PAGE);

  const handleNext = () => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  // When user deletes a notification
  const handleDelete = async id => {
    const oldLength = data.length;
    const status = await remove(id);
    if (status) {
      // User is on last page with one notification only in the page
      if (maxPage !== 0 && page === maxPage && oldLength === page * NOTIFICATIONS_PER_PAGE + 1) {
        setPage(page - 1);
      }
    }
  };

  // When user clicks on the view button
  const handleView = notificationId => {
    const visit = pagedData.find(({ id }) => notificationId === id);
    setDialogInfo({ open: true, data: visit });
  };

  // When user closes visit dialog
  const handleClose = () => setDialogInfo({ open: false });

  // When user submits a review in visit dialog
  const handleSubmit = (id, rating, feedback) => {
    const { appointment } = pagedData.find(({ appointment }) => appointment.id === id);
    appointment.rating = String(rating);
    appointment.feedback = feedback;
    setUpdate(!update); // Force update
  };

  // When a user cancels a requested appointment
  const handleCancel = async appointmentId => {
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("client_id", localStorage.getItem("id"));
    body.set("id", appointmentId);

    let res = await fetch(CANCEL_CARE_ROUTE, { method: "POST", body });
    if (!res.ok) {
      console.error(res);
      enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
      return;
    }
    res = await res.json();
    if (res.status !== "Fail") {
      cancel();
      enqueueSnackbar("Appointment has been cancelled", { variant: "success" });
      return;
    }
    enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
  };

  // Notice I put index as key instead of pageNum because it makes the render transition more smooth
  return (
    <>
      <VisitDialog {...dialogInfo} close={handleClose} submit={handleSubmit} cancel={handleCancel} />
      {pagedData.map(({ id, ...rest }) => (
        <Notification key={id} id={id} {...rest} remove={handleDelete} view={handleView} />
      ))}
      <Box marginLeft="auto" width="fit-content">
        {pageBtns.map((pageNum, index) => (
          <IconButton
            key={index}
            onClick={() => setPage(pageNum)}
            className={`${btn} ${pageNum === page ? active : ""}`}
            disableTouchRipple
          >
            {pageNum + 1}
          </IconButton>
        ))}
        {maxPage !== 0 && (
          <IconButton className={`${btn} ${arrow_btn}`} disabled={page === maxPage} onClick={handleNext}>
            <KeyboardArrowRightIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}

export default PaginateNotifications;
