import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ProfileHead from "components/ProfileHead";
import ProfileEdit from "components/ProfileEdit";
import ProfileDisplay from "components/ProfileDisplay";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: theme.spacing(2, "auto", 0),
    paddingBottom: theme.spacing(5)
  }
}));

function Profile(props) {
  const { update } = props;
  const { container } = useStyles();

  const [edit, setEdit] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const handleUpdate = () => {
    setEdit(false);
    update();
  };

  return (
    <Box className={container}>
      <ProfileHead edit={edit} click={() => setEdit(!edit)} upload={setAvatar} />
      {edit ? <ProfileEdit update={handleUpdate} avatar={avatar} /> : <ProfileDisplay />}
    </Box>
  );
}

export default Profile;