/* Component for rendering the schedule care form */

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { MY_CAREGIVERS_ROUTE, LOCATION_ROUTE, SCHEDULE_CARE_ROUTE, CANCEL_CARE_ROUTE } from "Routes";
import { MAX_TIME_RANGE, TIME_SLOTS, DAY_IN_MS, CARE_RECIPIENT_URL, DASHBOARD_URL, PAYMENT_URL } from "Constants";
import Dialog from "components/CareRequestDialog";
import ScheduleIcon from "@material-ui/icons/Schedule";

const MAX_DATE = DAY_IN_MS * 30 * 3; // 3 months in ms assuming 30 days = 1 month

// Ids of certain items in the location/caregiver menu
const LOAD_ID = -1; // For loading locations/caregivers...
const ADD_ID = -2; // For add location/caregiver

const useStyles = makeStyles(theme => ({
  container: {
    width: "95%",
    maxWidth: "350px",
    margin: "auto",
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      maxWidth: "1000px"
    }
  },
  main_header: {
    marginBottom: theme.spacing(4),
    fontSize: "1.5rem",
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      fontSize: "2.4rem"
    }
  },
  wide: {
    marginTop: theme.spacing(-1),
    [theme.breakpoints.up("md")]: {
      gridColumn: "span 2"
    }
  },
  label: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginLeft: theme.spacing(4)
    }
  },
  input_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(4, 2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr"
    }
  },
  empty_cell: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "initial"
    }
  },
  textbox: {
    padding: theme.spacing(2, 4),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  textarea: {
    resize: "none",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "inherit",
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: "1rem"
  },
  input: {
    textAlign: "inherit",
    width: "100%"
  },
  link: {
    textDecoration: "none",
    width: "100%",
    color: theme.palette.primary.main
  },
  btn: {
    display: "block",
    width: "200px",
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(1.5, 0),
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

function getDateString(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
}

function ScheduleCare(props) {
  const { history } = props;
  const {
    container,
    main_header,
    wide,
    label,
    input_container,
    empty_cell,
    textbox,
    textarea,
    input,
    link,
    btn,
    icon
  } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(new Date());
  const [start, setStart] = useState(20);
  const [end, setEnd] = useState(22);
  const [timeError, setTimeError] = useState("");
  const [locationList, setLocationList] = useState([{ id: LOAD_ID, name: "Loading your Locations..." }]);
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const [caregiverList, setCaregiverList] = useState([{ id: LOAD_ID, name: "Loading your Caregivers..." }]);
  const [caregiver, setCaregiver] = useState("");
  const [caregiverError, setCaregiverError] = useState("");
  const [dialogInfo, setDialogInfo] = useState({ open: false });

  const instructionRef = useRef();
  const referralRef = useRef();

  // Populate caregivers and locations
  useEffect(() => {
    let active = true;
    const body = new FormData();
    body.set("patient_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("user_id", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(MY_CAREGIVERS_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (!res.data || res.data.length === 0) {
            setCaregiver(ADD_ID);
            setCaregiverList([]);
            return;
          }
          setCaregiverList(
            res.data.map(({ Doctor: { id, first_name, last_name } }) => ({
              id,
              name: `${first_name} ${last_name}`
            }))
          );
        }
      })
      .catch(err => console.error(err));

    fetch(LOCATION_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (!res.data || res.data.length === 0) {
            setLocation(ADD_ID);
            setLocationList([]);
            return;
          }
          setLocation("");
          setLocationList(
            res.data.Location.map(({ id, address_label }) => ({
              id,
              name: address_label
            }))
          );
        }
      })
      .catch(err => console.error(err));

    return () => (active = false);
  }, []);

  // Populate inputs based on url parameters after caregiver and location list has been populated
  useEffect(() => {
    if (locationList[0]?.id === LOAD_ID || caregiverList[0]?.id === LOAD_ID) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const data = {};
    for (const [key, value] of params) {
      data[key] = value;
    }

    // Only set parameters if they all exist and are valid
    if (
      !TIME_SLOTS[data["start"]] ||
      !TIME_SLOTS[data["end"]] ||
      locationList.findIndex(({ id }) => id === data["location"]) === -1 ||
      caregiverList.findIndex(({ id }) => id === data["caregiver"]) === -1 ||
      !new Date(Number(data["date"])).getTime() ||
      !data["instruction"]
    ) {
      return;
    }
    setStart(data["start"]);
    setEnd(data["end"]);
    setLocation(data["location"]);
    setCaregiver(data["caregiver"]);
    setDate(new Date(Number(data["date"])));
    instructionRef.current.value = data["instruction"];
    referralRef.current.value = data["referral"] || "";
  }, [locationList, caregiverList]);

  // Set caregiver through url parameters
  useEffect(() => {
    const caregiverId = new URLSearchParams(window.location.search).get("cgid");
    if (caregiverId && caregiverList.find(({ id }) => id === caregiverId)) {
      setCaregiver(caregiverId);
    }
  }, [caregiverList]);

  const handleChange = (setState, setError) => e => {
    setState(e.target.value);
    setError("");
  };

  const handleDateChange = date => {
    setDate(date);
    // Sometimes a change in date validates the time values
    setTimeError("");
  };

  const handleTimeChange = isStart => e => {
    setTimeError("");
    isStart ? setStart(e.target.value) : setEnd(e.target.value);
  };

  const handleCancel = id => {
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("client_id", localStorage.getItem("id"));
    body.set("id", id);

    fetch(CANCEL_CARE_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          enqueueSnackbar(res.message, { variant: "error" });
        } else {
          enqueueSnackbar("Your Care Visit has been cancelled", { variant: "success" });
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Cancellation failed, Please try again in the My Visits section.", {
          variant: "error"
        });
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const timeDifference = start < end ? end - start : TIME_SLOTS.length - start + end;
    let fail = false;
    if (timeDifference > MAX_TIME_RANGE) {
      setTimeError("The time period cannot be more than 12 hours");
      fail = true;
    }
    if (location === "" || location < 0) {
      setLocationError("Please select a location");
      fail = true;
    }
    if (caregiver === "" || caregiver < 0) {
      setCaregiverError("Please select a Caregiver");
      fail = true;
    }
    // Check if date is valid and is today but startime has already passed
    if (date.getDate()) {
      const startDate = date;
      startDate.setHours(start >> 1, start % 2 === 0 ? 0 : 30, 0, 0);
      if (startDate < Date.now()) {
        setTimeError("The start time has already passed");
        fail = true;
      }
    } else {
      // Invalid date case
      fail = true;
    }
    if (fail) {
      return;
    }
    // Check if user's payment information is recorded
    if (
      localStorage.getItem("is_vip") !== "true" &&
      (!localStorage.getItem("token") || localStorage.getItem("token") === "")
    ) {
      history.push(
        `${PAYMENT_URL}?date=${date.getTime()}&start=${start}&end=${end}&location=${location}&caregiver=${caregiver}&instruction=${
          instructionRef.current.value
        }&referral=${referralRef.current.value}`
      );
      return;
    }

    // Create date stirng
    const dateString = getDateString(date);
    const startString = `${dateString} ${start >> 1}:${start % 2 === 0 ? "00" : "30"}:00`;
    const endDateString = start < end ? dateString : getDateString(new Date(date.getTime() + DAY_IN_MS)); // Get date string of the next day
    const endString = `${endDateString} ${end >> 1}:${end % 2 === 0 ? "00" : "30"}:00`;

    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("appointment_date", dateString);
    body.set("location_id", location);
    body.set("appointment_location", locationList.find(({ id, name }) => id === location).name);
    body.set("client_id", localStorage.getItem("id"));
    body.set("recepient_id", caregiver);
    body.set("special_instruction", instructionRef.current.value);
    if (referralRef.current && referralRef.current.value !== "") {
      body.set("refrel_code", referralRef.current.value);
    }
    body.set("start_time", startString);
    body.set("end_time", endString);

    fetch(SCHEDULE_CARE_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          enqueueSnackbar(res.message, { variant: "error" });
        } else {
          enqueueSnackbar("Your Care Visit has been requested", { variant: "success" });
          const {
            Appointment,
            Location: { city, postal_code, street_address, province, address_label, apartment_name },
            Receipent: { id, first_name, last_name, profile_pic }
          } = res.data;
          setDialogInfo({
            open: true,
            id,
            appointmentId: Appointment.id,
            date,
            start: TIME_SLOTS[start],
            end: TIME_SLOTS[end],
            address: address_label,
            city,
            zip: postal_code,
            street: street_address,
            apartment: apartment_name,
            province,
            name: `${first_name} ${last_name}`,
            avatar: profile_pic,
            note: instructionRef.current.value
          });
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, Please try again.", { variant: "error" });
      });
  };

  const showNextYear = new Date().getYear() !== new Date(Date.now() + MAX_DATE).getYear();

  return (
    <form className={container} onSubmit={handleSubmit}>
      <Typography variant="h4" className={main_header} color="primary">
        Schedule Care
      </Typography>
      <Box className={input_container}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disablePast
            disableToolbar
            okLabel={<Typography color="secondary">OK</Typography>}
            cancelLabel={<Typography color="secondary">Cancel</Typography>}
            maskChar="-"
            variant="dialog"
            label="Date of Care"
            InputProps={{ placeholder: "YYYY/MM/DD" }}
            inputVariant="outlined"
            invalidDateMessage="Invalid Date"
            minDate={Date.now()}
            maxDate={new Date(Date.now() + MAX_DATE)}
            minDateMessage="Appointment cannot be in the past"
            maxDateMessage="Appointment must be scheduled within 3 months"
            format="yyyy/MM/dd"
            openTo={showNextYear ? "year" : "month"}
            views={showNextYear ? ["year", "month", "date"] : ["month", "date"]}
            value={date}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>

        <div className={empty_cell}></div>

        <TextField
          select
          variant="outlined"
          label="Start Time"
          value={start}
          InputProps={{ startAdornment: <ScheduleIcon className={icon} color="disabled" fontSize="small" /> }}
          helperText={timeError}
          error={timeError.length !== 0}
          onChange={handleTimeChange(true)}
        >
          {TIME_SLOTS.map((slot, index) => (
            <MenuItem key={index} value={index}>
              {slot}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          variant="outlined"
          helperText={timeError}
          error={timeError.length !== 0}
          label="End Time"
          value={end}
          InputProps={{ startAdornment: <ScheduleIcon className={icon} color="disabled" fontSize="small" /> }}
          onChange={handleTimeChange(false)}
        >
          {TIME_SLOTS.map((slot, index) => (
            <MenuItem key={index} value={index}>
              {slot}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          variant="outlined"
          helperText={locationError}
          error={locationError.length !== 0}
          label="Location of Care"
          value={location}
          onChange={handleChange(setLocation, setLocationError)}
        >
          {locationList.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}

          <MenuItem value={ADD_ID}>
            <Link to={`${CARE_RECIPIENT_URL}?rtsc=1`} className={link}>
              Add Location
            </Link>
          </MenuItem>
        </TextField>

        <TextField
          select
          variant="outlined"
          label="Caregiver"
          value={caregiver}
          helperText={caregiverError}
          error={caregiverError.length !== 0}
          onChange={handleChange(setCaregiver, setCaregiverError)}
        >
          {caregiverList.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
          <MenuItem value={ADD_ID}>
            <Link to={DASHBOARD_URL} className={link}>
              Add Caregiver
            </Link>
          </MenuItem>
        </TextField>

        <Box className={wide}>
          <Typography color="primary" className={label} variant="h6">
            How can we help?
          </Typography>

          <Paper className={textbox} elevation={2}>
            <TextareaAutosize
              className={textarea}
              rowsMin={5}
              required
              ref={instructionRef}
              placeholder="Tell us about the care you need or leave special instructions."
            />
          </Paper>
        </Box>

        <Box>
          <Typography color="primary" className={label} variant="h6">
            Referral Code
          </Typography>
          <Paper className={textbox} elevation={2}>
            <Input inputRef={referralRef} disableUnderline inputProps={{ className: input }} className={input}></Input>
          </Paper>
        </Box>
      </Box>

      <Typography color="error" align="center">
        {timeError !== "" || locationError !== "" || caregiverError !== "" ? "Please correct your inputs and try again" : ""}
      </Typography>

      <Button type="submit" className={btn}>
        Request Care
      </Button>

      <Dialog open={dialogInfo.open} data={dialogInfo} cancel={handleCancel} close={() => setDialogInfo({ open: false })} />
    </form>
  );
}

export default ScheduleCare;
