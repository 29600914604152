/* Component for rendering the appointment review panel for the visit dialog when the visit
   is upcoming or completed
*/

import React, { useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import { RATING_PRECISION } from "Constants";
import { ADD_REVIEW_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  stars: {
    fontSize: "1.9rem",
    marginBottom: theme.spacing(2)
  },
  textbox: {
    margin: "auto",
    width: "min(350px, 70vw)",
    [theme.breakpoints.up("sm")]: {
      width: "350px"
    },
    [theme.breakpoints.up("md")]: {
      width: "450px"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2)
    }
  },
  empty_paper: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  textarea: {
    border: "none",
    resize: "none",
    textAlign: "inherit",
    outline: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
    width: "100%",
    "&::placeholder": {
      color: theme.palette.text.secondary
    }
  },
  btn: {
    margin: theme.spacing(4, "auto", 2),
    width: "250px",
    display: "block"
  }
}));

function AppointmentReview(props) {
  const { labelClass, buttonClass, rating, feedback, readOnly, close, caregiverId, appointmentId, submit } = props;
  const { container, stars, textbox, paper, empty_paper, textarea, btn } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(readOnly ? Number(rating) : null);
  const textRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();

    const body = new FormData();
    body.set("client_rating", value || 0);
    body.set("client_feedback", textRef.current.value);
    body.set("id", appointmentId);
    body.set("recepient_id", caregiverId);
    body.set("client_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(ADD_REVIEW_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          enqueueSnackbar("Something went wrong, refresh the page and try again", { variant: "error" });
        } else {
          submit(value || 0, textRef.current.value);
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, refresh the page and try again", { variant: "error" });
      });
  };

  let defaultValue = "";
  let elevation = 2;
  let paperClass = "";
  let containerComponent = "form";
  let placeholder = "";
  let reviewTitle = "";
  let actionButton = "";

  if (readOnly) {
    defaultValue = feedback || "";
    elevation = 0;
    paperClass = empty_paper;
    containerComponent = "div";
    placeholder = "No review provided";
    reviewTitle = "Your Review";
    actionButton = (
      <Button onClick={close} className={`${buttonClass} ${btn}`}>
        OK
      </Button>
    );
  } else {
    paperClass = paper;
    placeholder = "Tell us about the care you received";
    reviewTitle = "Write a review";
    actionButton = (
      <Button type="submit" className={`${buttonClass} ${btn}`}>
        Submit
      </Button>
    );
  }

  return (
    <Box component={containerComponent} className={container} onSubmit={readOnly ? null : handleSubmit}>
      <Box>
        <Typography color="secondary" className={labelClass}>
          Star Rating
        </Typography>
        <Rating
          className={stars}
          name="rating"
          readOnly={readOnly}
          value={value}
          onChange={(e, newValue) => {
            setValue(newValue);
          }}
          precision={RATING_PRECISION}
        />
      </Box>
      <Box>
        <Typography color="secondary" className={labelClass}>
          {reviewTitle}
        </Typography>
        <Box className={textbox}>
          <Paper className={paperClass} elevation={elevation}>
            <TextareaAutosize
              rows={5}
              rowsMax={5}
              className={textarea}
              placeholder={placeholder}
              readOnly={readOnly}
              ref={textRef}
              defaultValue={defaultValue}
            />
          </Paper>
        </Box>
        {actionButton}
      </Box>
    </Box>
  );
}

export default AppointmentReview;
