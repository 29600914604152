/* Component for rendering the upcoming appointments in the my visits page */

import React from "react";
import Visit from "components/Visit";
import ViewDetailsButton from "components/ViewDetailsButton";
import { UPCOMING_STATUS } from "Constants";

function UpcomingVisits(props) {
  const { data, view } = props;

  return (
    <>
      {data.map(({ appointment: { id, date, start, end } }) => (
        <Visit key={id} date={date} start={start} end={end} status="Upcoming">
          <ViewDetailsButton click={() => view(id, UPCOMING_STATUS)} />
        </Visit>
      ))}
    </>
  );
}

export default UpcomingVisits;
