import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { theme } from "themes/theme";
import LandingPage from "pages/Landing";
import LoginPage from "pages/Login";
import SignupPage from "pages/Signup";
import MainPages from "pages/MainPages";
import { LOGIN_URL, SIGNUP_URL } from "Constants";

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path={LOGIN_URL} component={LoginPage} />
            <Route path={SIGNUP_URL} component={SignupPage} />
            <Route path="*" component={MainPages} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
