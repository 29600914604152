/* Component for rendering the search results page */

import React, { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchResultsText from "components/SearchResultsText";
import CaregiverList from "components/CaregiverList";
import Caregiver from "components/Caregiver";
import FavouriteDialog from "components/FavouriteDialog";
import { DASHBOARD_URL } from "Constants";
import { SEARCH_CAREGIVERS_ROUTE, CAREGIVER_NAME_ROUTE } from "Routes";

function SearchResults(props) {
  const { history } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [nameData, setNameData] = useState(null);

  // Go back to search filters
  const reset = useCallback(() => history.push(DASHBOARD_URL), [history]);
  const search = window.location.search;

  useEffect(() => {
    let active = true;

    const params = new URLSearchParams(search);
    const name = params.get("name");

    if (name) {
      const body = new FormData();
      body.set("uid", localStorage.getItem("id"));
      body.set("auth_key", localStorage.getItem("authKey"));
      body.set("name", name);

      fetch(CAREGIVER_NAME_ROUTE, { method: "POST", body })
        .then(res => res.json())
        .then(res => {
          if (res.status !== "fail") {
            active && setNameData(res.data);
          } else {
            reset();
            enqueueSnackbar(res.error, { variant: "error" });
          }
        })
        .catch(err => {
          console.error("Error:", err);
          reset();
          enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
        });
      return () => (active = false);
    }

    const lat = params.get("lat");
    const lng = params.get("lng");
    const avail = params.get("avail");
    const lang = params.get("lang");
    const gender = params.get("gender");
    const special = params.get("special");
    const start = params.get("start");
    const end = params.get("end");

    if (!lat || !lng || !avail || !start || !end) {
      reset();
      return;
    }

    const body = new FormData();
    body.set("latitude", lat);
    body.set("longitude", lng);
    if (lang && lang.length > 0) {
      body.set("lang_id", lang);
    }
    if (gender === "M" || gender === "F") {
      body.set("gender", gender);
    }
    if (special && special.length > 0) {
      body.set("specialist_id", special);
    }
    body.set("availability", avail);
    body.set("startTime", start);
    body.set("endTime", end);

    fetch(SEARCH_CAREGIVERS_ROUTE, { method: "POST", body })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            if (active) {
              setAvailabilityData(data.data);
            }
          });
        } else {
          reset();
          enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
        }
      })
      .catch(err => {
        console.error("Error:", err);
        reset();
        enqueueSnackbar("Something went wrong, please try again", { variant: "error" });
      });

    return () => (active = false);
  }, [enqueueSnackbar, reset, search]);

  if (availabilityData === null && nameData === null) {
    return (
      <Box textAlign="center">
        <Typography color="textSecondary" variant="h5" gutterBottom>
          Loading Results
        </Typography>
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  const dialogMap = {}; // Map id to profile pic and name
  let days = [];
  const avail = new URLSearchParams(search).get("avail");
  if (avail) {
    days = avail.split(",").map(day => Number(day));
  }
  const data = nameData || availabilityData;

  data.forEach(({ id, name, profile_pic }) => {
    dialogMap[id] = { name, profile_pic };
  });

  return (
    <SearchResultsText reset={reset} length={data.length}>
      {nameData ? (
        nameData.map(({ id, name, description, rating, profile_pic }) => (
          <Caregiver
            key={id}
            id={id}
            rating={rating}
            name={name}
            description={description}
            profile_pic={profile_pic}
            select={() => setId(id)}
          />
        ))
      ) : (
        <CaregiverList data={data} days={[...days.slice(1), days[0]]} select={setId} />
      )}
      {id !== null && (
        <FavouriteDialog
          id={id}
          avatar={dialogMap[id].profile_pic}
          name={dialogMap[id].name}
          close={() => setId(null)}
          ignoreFind={true}
        />
      )}
    </SearchResultsText>
  );
}

export default SearchResults;
