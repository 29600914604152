/* Component for rendering the dialog when the user favourites a caregiver */

import React, { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { DASHBOARD_URL, SCHEDULE_URL } from "Constants";
import { FAVOURITE_CAREGIVER_ROUTE, UNFAVOURITE_CAREGIVER_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 4),
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 8)
    }
  },
  img: {
    width: "110px",
    height: "110px",
    marginBottom: theme.spacing(1)
  },
  name_text: {
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
    fontSize: "1.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem"
    }
  },
  body_text: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem"
    }
  },
  btn_container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  btn: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2, 0),
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      width: "250px"
    }
  },
  find_btn: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.secondary.main
    }
  },
  request_btn: {
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bgcolor.main
    }
  },
  back_text: {
    marginTop: theme.spacing(2),
    cursor: "pointer"
  },
  close_btn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));

function FavouriteDialog(props) {
  const { id, avatar, name, close, ignoreFind, back, favouriteId, onFavourite, onUnFavourite } = props;
  const {
    container,
    img,
    name_text,
    body_text,
    btn_container,
    btn,
    find_btn,
    request_btn,
    back_text,
    close_btn,
    close_icon
  } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [isDone, setIsDone] = useState(null);

  const isFavourited = favouriteId !== null && favouriteId !== undefined;

  // Favourite caregiver
  useEffect(() => {
    if (isFavourited) {
      return;
    }
    let active = true;
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("patient_id", localStorage.getItem("id"));
    body.set("doctor_id", id);
    body.set("auth_key", localStorage.getItem("authKey"));
    fetch(FAVOURITE_CAREGIVER_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.status === "Fail") {
            // Assume Failure means already favourited
            setIsDone(false);
          } else {
            setIsDone(true);
            onFavourite && onFavourite(res.data.Favourite.id);
          }
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
      });
    return () => (active = false);
  }, [id, isFavourited, onFavourite, enqueueSnackbar]);

  // Unfavourite caregiver
  useEffect(() => {
    if (!isFavourited) {
      return;
    }
    let active = true;
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("id", favouriteId);
    fetch(UNFAVOURITE_CAREGIVER_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.status === "Fail") {
            enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
          } else {
            setIsDone(true);
            onUnFavourite && onUnFavourite();
          }
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
      });
    return () => (active = false);
  }, [id, isFavourited, favouriteId, onUnFavourite, enqueueSnackbar]);

  let msg = "";
  if (isDone === null) {
    msg = `is being ${isFavourited ? "removed from" : "added to"} your list of Caregivers`;
  } else {
    msg = `has ${isDone ? "" : "already"} been ${isFavourited ? "removed from" : "added to"} your list of Caregivers`;
  }

  const body = (
    <>
      <Typography className={body_text} gutterBottom>
        {msg}
      </Typography>
      {isDone === null && <CircularProgress />}
    </>
  );

  return (
    <Dialog open={true} onClose={close} PaperProps={{ className: container }}>
      <Avatar src={avatar} className={img} />
      <Typography className={name_text} gutterBottom>
        {name}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {body}
      </Typography>
      <Box className={btn_container}>
        {!ignoreFind && (
          <Link to={DASHBOARD_URL} style={{ textDecoration: "none" }}>
            <Button className={`${btn} ${find_btn}`}>Find another Caregiver</Button>
          </Link>
        )}
        {!ignoreFind && !isFavourited && <Typography color="secondary">OR</Typography>}
        {!isFavourited &&
          <Link to={`${SCHEDULE_URL}?cgid=${id}`} style={{ textDecoration: "none" }}>
            <Button className={`${btn} ${request_btn}`}>Request Care Now!</Button>
          </Link>
        }
      </Box>
      {back && (
        <Typography onClick={back} className={back_text}>
          Back to Previous Page
        </Typography>
      )}
      <IconButton className={close_btn} onClick={close}>
        <CloseIcon className={close_icon} />
      </IconButton>
    </Dialog>
  );
}

export default memo(FavouriteDialog, (prev, next) => prev.id === next.id && prev.avatar === next.avatar);
