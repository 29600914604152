import { withStyles, styled } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

export const GrayTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "14px"
    },
    "& .MuiOutlinedInput-root": {
      color: "#979797",
      "& fieldset": {
        borderColor: "#979797"
      },
      "&:hover fieldset": {
        borderColor: "#979797"
      },
      "&.Mui-focused": {
        color: "black"
      },
      "&.Mui-focused fieldset": {
        borderColor: "black"
      }
    }
  }
})(TextField);

export const GrayDatePicker = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "14px"
    },
    "& .MuiOutlinedInput-root": {
      color: "#979797",
      "& fieldset": {
        borderColor: "#979797"
      },
      "&:hover fieldset": {
        borderColor: "#979797"
      },
      "&.Mui-focused": {
        color: "black"
      },
      "&.Mui-focused fieldset": {
        borderColor: "black"
      }
    }
  }
})(KeyboardDatePicker);

export const Dot = styled("div")(({ theme, top, right }) => ({
  width: "8px",
  height: "8px",
  background: theme.palette.error.main,
  position: "absolute",
  top: `${top || 0}px`,
  right: `${right || 0}px`,
  borderRadius: "50%",
  boxShadow: `0 0 4px ${theme.palette.error.main}`
}));
