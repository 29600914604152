/* Component for rendering the navbar on the signup/login page */

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Logo from "assets/logo.png";
import LogoSmall from "assets/logo-small.png";

const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor: theme.palette.bgcolor.main,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 6)
    }
  },
  logo: {
    width: "220px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "initial"
    }
  },
  logo_small: {
    width: "40px",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  account_msg: {
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(2),
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  link: {
    textDecorationColor: theme.palette.secondary.dark,
    "&:active": {
      textDecorationColor: theme.palette.secondary.dark,
    }
  },
  account_link: {
    fontWeight: 600,
    fontSize: "0.9rem",
    color: theme.palette.secondary.dark,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem"
    }
  }
}));

function AccountNavbar(props) {
  const { accountMsg, linkMsg } = props;
  const { toolbar, logo, logo_small, account_msg, link, account_link } = useStyles();

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Toolbar className={toolbar}>
        <Box flexGrow={1}>
          <img src={Logo} className={logo} alt="Logo" />
          <img src={LogoSmall} className={logo_small} alt="Logo" />
        </Box>

        {accountMsg && linkMsg && (
          <>
            <Typography variant="h6" className={account_msg}>
              {accountMsg}
            </Typography>

            <Link to={props.link} className={link}>
              <Typography variant="subtitle1" className={account_link}>
                {linkMsg}
              </Typography>
            </Link>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AccountNavbar;
