/* Component for rendering the dialog when the user clicks the view details button in the my visits page */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CAREGIVER_URL, REQUESTED_STATUS, UPCOMING_STATUS, getTimeString, ADD_USER_TO_QB_URL } from "Constants";
import CalendarIcon from "assets/calendar-icon.svg";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import AppointmentReview from "components/AppointmentReview";

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "900px",
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 8)
    }
  },
  empty_paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: theme.spacing(2),
    padding: theme.spacing(4, 6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(10, 20)
    }
  },
  cancel_paper: {
    padding: theme.spacing(5, 4),
    maxWidth: "350px",
    margin: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(10, 8)
    }
  },
  main_grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(2, 6),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto auto"
    }
  },
  panel1: {
    display: "grid",
    gridTemplateColumns: "1fr",
    marginBottom: theme.spacing(1),
    gridGap: theme.spacing(1),
    justifyItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "40px 1fr",
      gridGap: theme.spacing(3),
      justifyItems: "initial",
      textAlign: "left"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0
    }
  },
  panel2: {
    display: "grid",
    gridTemplateRows: "auto",
    justifyItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      gridTemplateRows: "2fr 1fr",
      justifyItems: "initial",
      textAlign: "left"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 500,
    marginBottom: theme.spacing(4)
  },
  cancel_title: {
    fontSize: "1.2rem",
    fontWeight: 500
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 500,
    fontSize: "1.1rem"
  },
  long_text: {
    maxHeight: "150px",
    maxWidth: "320px",
    paddingRight: theme.spacing(1),
    overflowY: "auto"
  },
  img: {
    alignSelf: "center",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      alignSelf: "initial"
    }
  },
  link: {
    textDecoration: "none",
    display: "block",
    marginTop: theme.spacing(0.5)
  },
  btn: {
    width: "150px"
  },
  cancel_box: {
    display: "grid",
    width: "fit-content",
    margin: `${theme.spacing(1)}px auto`,
    gridTemplateColumns: "auto 1fr",
    gridGap: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "initial"
    }
  },
  cancel_note: {
    [theme.breakpoints.up("md")]: {
      marginLeft: `${theme.spacing(1) + 20}px`
    }
  },
  blue_btn: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.bgcolor.main
    }
  },
  white_btn: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.primary.main
    }
  },
  cancel_btn: {
    width: "45%"
  },
  ok_btn: {
    marginTop: theme.spacing(0.5),
    width: "250px",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      gridColumnStart: "2"
    }
  }
}));

function VisitDialog(props) {
  const { open, data, close, cancel, submit } = props;
  const {
    container,
    empty_paper,
    cancel_paper,
    main_grid,
    panel1,
    panel2,
    title,
    cancel_title,
    label,
    long_text,
    img,
    link,
    btn,
    cancel_box,
    cancel_note,
    cancel_btn,
    ok_btn,
    blue_btn,
    white_btn
  } = useStyles();

  // 1 = submit variant, 2 = cancel variant
  const [variant, setVariant] = useState(0);

  if (!open) {
    return <></>;
  }

  const { appointment, caregiver, location } = data;
  const { instruction, status, note, rating, feedback } = appointment;
  const date = new Date(appointment.date);
  const start = getTimeString(appointment.start.split(" ")[1]);
  const end = getTimeString(appointment.end.split(" ")[1]);
  const { street, apartment, city, province, zip } = location;
  const { avatar } = caregiver;

  const handleClose = () => {
    setVariant(0);
    close();
  };

  // When the cancel request button is clicked
  const handleClick = () => {
    setVariant(2);
  };

  // When the confirmed cancel button is clicked
  const handleCancel = () => {
    close();
    cancel(appointment.id);
    setVariant(0);
  };

  const handleSubmit = (rating, feedback) => {
    setVariant(1);
    submit && submit(appointment.id, rating, feedback);
  };

  if (variant === 1) {
    return (
      <Dialog open={true} onClose={handleClose} PaperProps={{ className: empty_paper }}>
        <Typography className={title} color="primary" gutterBottom>
          Thank you!
        </Typography>
        <Typography color="textSecondary" style={{ fontSize: "1.2rem" }}>
          Your Care Visit review
        </Typography>
        <Typography color="textSecondary" style={{ fontSize: "1.2rem", marginBottom: "32px" }}>
          has been received.
        </Typography>
        <Button className={`${btn} ${white_btn}`} onClick={handleClose}>
          CLOSE
        </Button>
      </Dialog>
    );
  } else if (variant === 2) {
    return (
      <Dialog open={true} onClose={handleClose} PaperProps={{ className: cancel_paper }}>
        <Typography className={cancel_title} color="textSecondary" align="center" gutterBottom>
          Are you sure you want to cancel your Care {status === REQUESTED_STATUS ? "Request" : "Visit"}?
        </Typography>
        <Box display="flex" justifyContent="space-evenly" marginTop={2}>
          <Button className={`${cancel_btn} ${blue_btn}`} onClick={() => setVariant(0)}>
            NO
          </Button>
          <Button className={`${cancel_btn} ${white_btn}`} onClick={handleCancel}>
            YES
          </Button>
        </Box>
      </Dialog>
    );
  }

  const isCompleted = status !== REQUESTED_STATUS && status !== UPCOMING_STATUS;
  let dialogTitle = "";
  let panel2Body = "";
  let panel1Links = "";
  let panel1Notes = "";
  let panel1Head = (
    <>
      <img src={CalendarIcon} className={img} style={{ width: "33px" }} alt="Date" />
      <Box>
        <Typography color="textSecondary">{date.toDateString()}</Typography>
        <Typography color="textSecondary">{`${start} - ${end}`}</Typography>
      </Box>
    </>
  );
  let panel2Button = (
    <Button className={`${white_btn} ${ok_btn}`} onClick={handleClose}>
      OK
    </Button>
  );

  if (isCompleted) {
    panel1Notes = (
      <>
        <ListAltRoundedIcon color="primary" className={img} style={{ fontSize: "2.5rem" }} />
        <Box>
          <Typography className={label} color="secondary">
            Caregiver Notes
          </Typography>
          <Typography color="textSecondary" variant="body2" className={long_text}>
            {note || "No notes provided"}
          </Typography>
        </Box>
      </>
    );
  } else {
    panel1Head = (
      <>
        {panel1Head}
        <RoomOutlinedIcon color="primary" className={img} style={{ fontSize: "2.5rem" }} />
        <Box>
          <Typography className={label} color="secondary">
            {location.name}
          </Typography>
          <Typography color="textSecondary">
            {street}, {apartment ? `${apartment},` : ""}
          </Typography>
          <Typography color="textSecondary">{`${city}, ${province}, ${zip}`}</Typography>
        </Box>
      </>
    );
    panel1Links = (
      <>
        <Link to={`${CAREGIVER_URL}/${caregiver.id}`} className={link}>
          <Button className={`${btn} ${blue_btn}`} startIcon={<PersonOutlineOutlinedIcon />}>
            View Profile
          </Button>
        </Link>
        <Link to={`${ADD_USER_TO_QB_URL}?cgid=${caregiver.id}`} className={link}>
          <Button className={`${btn} ${white_btn}`}>Chat</Button>
        </Link>
      </>
    );
    panel2Body = (
      <Box>
        <Typography color="secondary" className={label}>
          Special Instructions
        </Typography>
        <Typography color="textSecondary" variant="body2" className={long_text} gutterBottom>
          {`${!instruction || instruction.length === 0 ? "No instruction provided" : instruction}`}
        </Typography>
      </Box>
    );
  }

  const panel1Body = (
    <>
      <Avatar src={avatar} className={img} />
      <Box>
        <Typography color="secondary" className={label}>
          Your Caregiver
        </Typography>
        <Typography color="textSecondary">{caregiver.name}</Typography>
        {panel1Links}
      </Box>
      {panel1Notes}
    </>
  );

  if (status === REQUESTED_STATUS) {
    dialogTitle = "Requested Care Visit";
    panel2Body = (
      <Box className={panel2}>
        {panel2Body}
        <Box>
          <Typography color="secondary" variant="body2">
            Please wait for confirmation from your Caregiver
          </Typography>
          <Box className={cancel_box}>
            <CancelOutlinedIcon color="primary" style={{ cursor: "pointer" }} fontSize="small" onClick={handleClick} />
            <Typography color="textSecondary" variant="body2">
              Cancel Request
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else if (status === UPCOMING_STATUS) {
    dialogTitle = "Confirmed Care Visit";
    panel2Body = (
      <Box className={panel2}>
        {panel2Body}
        <Box>
          <Box>
            <Box className={cancel_box}>
              <CancelOutlinedIcon color="primary" style={{ cursor: "pointer" }} fontSize="small" onClick={handleClick} />
              <Typography color="textSecondary" variant="body2">
                Cancel Care Visit
              </Typography>
            </Box>
            <Typography color="textSecondary" variant="body2" className={cancel_note}>
              Note that if you provide less than 24 hrs notice, <br></br> you will be charged for the Care Visit
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else {
    const readOnly = typeof rating === "string" && rating.length !== 0;
    dialogTitle = readOnly ? "Your Review" : "Write a Review";
    panel2Button = "";
    panel2Body = (
      <AppointmentReview
        labelClass={label}
        buttonClass={white_btn}
        rating={rating}
        feedback={feedback}
        readOnly={readOnly}
        close={close}
        caregiverId={caregiver.id}
        appointmentId={appointment.id}
        submit={handleSubmit}
      />
    );
  }

  return (
    <Dialog open={true} onClose={handleClose} PaperProps={{ className: container }}>
      <Typography variant="h4" color="secondary" className={title}>
        {dialogTitle}
      </Typography>

      <Box className={main_grid}>
        <Box className={panel1}>
          {panel1Head}
          {panel1Body}
        </Box>

        {panel2Body}
        {panel2Button}
      </Box>
    </Dialog>
  );
}

export default VisitDialog;
