/* Component for rendering the body of the my visits page */

import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SCHEDULE_URL, REQUESTED_STATUS, UPCOMING_STATUS, COMPLETED_STATUS } from "Constants";
import { VISITS_ROUTE, CANCEL_CARE_ROUTE } from "Routes";
import PaginateVisits from "components/PaginateVisits";

const EMPTY_MESSAGES = [
  "You currently have no new requests",
  "You have no Upcoming Visits",
  "You have no completed Care Visits"
];

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "800px",
    margin: "auto",
    paddingBottom: theme.spacing(5)
  },
  tab_paper: {
    maxWidth: "100%",
    margin: theme.spacing(1, "auto", 3),
    borderRadius: theme.spacing(3, 3, 0, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1, "5%", 3)
    }
  },
  tab_label: {
    fontSize: "calc(12px + 0.7vw)",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem"
    }
  },
  empty_paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "300px"
  },
  btn: {
    padding: theme.spacing(1, 4),
    fontSize: "1.2rem",
    marginTop: theme.spacing(1),
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 8)
    }
  }
}));

function MyVisits() {
  const { container, tab_paper, tab_label, empty_paper, btn } = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [requested, setRequested] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [tab, setTab] = useState(REQUESTED_STATUS);
  const [dialogInfo, setDialogInfo] = useState({ open: false });
  const { enqueueSnackbar } = useSnackbar();

  // Fetch visits
  useEffect(() => {
    let active = true;
    async function getVisits(body) {
      let res = await fetch(VISITS_ROUTE, { method: "POST", body });
      if (!res.ok) {
        if (res.status === 401) {
          localStorage.clear();
          window.location.reload(); // Refresh page so that it redirects to login
        }
        return null;
      }
      res = await res.json();
      return res.data.map(({ Appointment, Receipent, Location }) => {
        const {
          id,
          appointment_date,
          appointment_status,
          start_time,
          end_time,
          client_rating,
          client_feedback,
          special_instruction,
          care_note
        } = Appointment;
        const appointment = {
          id,
          date: appointment_date,
          status: Number(appointment_status),
          start: start_time,
          end: end_time,
          rating: client_rating,
          feedback: client_feedback,
          instruction: special_instruction,
          note: care_note
        };
        const { profile_pic, first_name, last_name } = Receipent;
        const caregiver = { id: Receipent.id, avatar: profile_pic, name: `${first_name} ${last_name}` };
        const { address_label, apartment_name, city, postal_code, province, street_address } = Location;
        const location = {
          name: address_label,
          apartment: apartment_name,
          city,
          zip: postal_code,
          province,
          street: street_address
        };
        return { appointment, caregiver, location };
      });
    }

    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("client_id", localStorage.getItem("id"));
    body.set("appointment_status", REQUESTED_STATUS);

    const reqPromise = getVisits(body);
    body.set("appointment_status", UPCOMING_STATUS);
    const upPromise = getVisits(body);
    body.set("appointment_status", COMPLETED_STATUS);
    const comPromise = getVisits(body);

    Promise.all([reqPromise, upPromise, comPromise]).then(([reqResponse, upResponse, comResponse]) => {
      if (active) {
        // Something went wrong when making the API call
        if (reqResponse === null || upResponse === null || comResponse === null) {
          setLoaded(null);
        } else {
          setRequested(reqResponse);
          setUpcoming(upResponse);
          setCompleted(comResponse);
          setLoaded(true);
        }
      }
    });

    return () => (active = false);
  }, []);

  // When a user cancels a requested appointment
  const handleCancel = async appointmentId => {
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("client_id", localStorage.getItem("id"));
    body.set("id", appointmentId);

    let res = await fetch(CANCEL_CARE_ROUTE, { method: "POST", body });
    if (!res.ok) {
      console.error(res);
      enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
      return null;
    }
    res = await res.json();
    if (res.status !== "Fail") {
      const [visitList, setList] = tab === REQUESTED_STATUS ? [requested, setRequested] : [upcoming, setUpcoming];
      visitList.splice(
        visitList.findIndex(({ appointment: { id } }) => id === appointmentId),
        1
      );
      setList([...visitList]);
      enqueueSnackbar("Appointment has been cancelled", { variant: "success" });
      return true;
    }
    enqueueSnackbar("Something went wrong, Please refresh the page and try again", { variant: "error" });
    return false;
  };

  // When a user clicks the view details button
  const handleView = (id, status) => {
    let visitList = completed;
    if (status === REQUESTED_STATUS) {
      visitList = requested;
    } else if (status === UPCOMING_STATUS) {
      visitList = upcoming;
    }
    const visit = visitList.find(({ appointment }) => appointment.id === id);
    setDialogInfo({ open: true, data: visit });
  };

  // When user closes visit dialog
  const handleClose = () => setDialogInfo({ open: false });

  // When user submits a rating
  const handleSubmit = (appointmentId, rating, feedback) => {
    const { appointment } = completed.find(({ appointment: { id } }) => id === appointmentId);
    appointment.rating = String(rating);
    appointment.feedback = feedback;
    setCompleted([...completed]);
  };

  let body = "";
  if (loaded === null) {
    body = (
      <Paper className={empty_paper} elevation={2}>
        <Typography color="error" align="center" variant="h5" gutterBottom>
          Something went wrong, Please try refreshing the page
        </Typography>
      </Paper>
    );
  } else if (!loaded) {
    body = (
      <Paper className={empty_paper} elevation={2}>
        <Typography color="textSecondary" align="center" variant="h5" gutterBottom>
          Loading your Visits
        </Typography>
        <CircularProgress />
      </Paper>
    );
  } else {
    if (tab === REQUESTED_STATUS && requested.length !== 0) {
      body = (
        <PaginateVisits
          data={requested}
          variant={REQUESTED_STATUS}
          cancel={handleCancel}
          view={handleView}
          close={handleClose}
          dialogInfo={dialogInfo}
        />
      );
    } else if (tab === UPCOMING_STATUS && upcoming.length !== 0) {
      body = (
        <PaginateVisits
          data={upcoming}
          variant={UPCOMING_STATUS}
          cancel={handleCancel}
          view={handleView}
          close={handleClose}
          dialogInfo={dialogInfo}
        />
      );
    } else if (tab === COMPLETED_STATUS && completed.length !== 0) {
      body = (
        <PaginateVisits
          data={completed}
          variant={COMPLETED_STATUS}
          view={handleView}
          close={handleClose}
          submit={handleSubmit}
          dialogInfo={dialogInfo}
        />
      );
    } else {
      body = (
        <Paper className={empty_paper} elevation={2}>
          <Typography color="textSecondary" align="center" variant="h6" gutterBottom>
            {EMPTY_MESSAGES[tab]}
          </Typography>
          <Link style={{ textDecoration: "none" }} to={SCHEDULE_URL}>
            <Button className={btn}>Request Care</Button>
          </Link>
        </Paper>
      );
    }
  }
  return (
    <Box className={container}>
      <Paper elevation={3} className={tab_paper}>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          textColor="secondary"
          variant="fullWidth"
          TabIndicatorProps={{ style: { height: "3px" } }}
        >
          <Tab className={tab_label} label="Requested" />
          <Tab className={tab_label} label="Upcoming" />
          <Tab className={tab_label} label="Completed" />
        </Tabs>
      </Paper>
      {body}
    </Box>
  );
}

export default MyVisits;
