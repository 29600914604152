/* Component for rendering the body of the my caregiver page */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { DASHBOARD_URL } from "Constants";
import { MY_CAREGIVERS_ROUTE } from "Routes";
import MyCaregiver from "components/MyCaregiver";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "1000px",
    margin: "auto"
  },
  header_container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  header: {
    flexGrow: 1,
    textAlign: "center",
    fontSize: "1.75rem",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      textAlign: "left"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.125rem"
    }
  },
  sub_header: {
    fontSize: "1.1rem",
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem"
    }
  },
  paper: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(20, 0)
    }
  },
  find_btn: {
    padding: theme.spacing(1, 2),
    fontSize: "1.2rem",
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.bgcolor.main,
    "&:hover": {
      backgroundColor: theme.palette.bgcolor.main,
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 8)
    }
  },
  find_another_btn: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(2),
    display: "block",
    boxShadow: `0 0 2px ${theme.palette.secondary.dark}`,
    margin: `${theme.spacing(2)}px auto 0`,
    padding: theme.spacing(1, 2),
    fontSize: "1.1rem",
    backgroundColor: theme.palette.bgcolor.main,
    color: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.bgcolor.main
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
      padding: theme.spacing(1.75, 2)
    }
  }
}));

function MyCaregivers() {
  const { container, header_container, header, sub_header, paper, find_btn, find_another_btn } = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [caregivers, setCaregivers] = useState([]);

  // Fetch caregivers
  useEffect(() => {
    let active = true;
    const body = new FormData();
    body.set("patient_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));

    fetch(MY_CAREGIVERS_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (active) {
          if (res.data && res.data.length !== 0) {
            setCaregivers(
              res.data.map(
                ({
                  AverageRating,
                  Doctor: { id, first_name, last_name, profile_pic, interesting_facts_about_your_self }
                }) => ({
                  id,
                  name: `${first_name} ${last_name}`,
                  avatar: profile_pic,
                  description: interesting_facts_about_your_self,
                  rating: Number(AverageRating) || 0
                })
              )
            );
          }
          setLoaded(true);
        }
      })
      .catch(err => {
        console.error(err);
        setLoaded(null);
      });
    return () => (active = false);
  }, []);

  let body = "";
  if (loaded) {
    if (caregivers.length === 0) {
      body = (
        <Paper className={paper} elevation={2}>
          <Typography color="textSecondary" className={sub_header}>
            You don't have any Caregivers in your list yet
          </Typography>
          <Link style={{ textDecoration: "none" }} to={DASHBOARD_URL}>
            <Button className={find_btn} color="secondary">
              Find a Caregiver
            </Button>
          </Link>
        </Paper>
      );
    } else {
      body = caregivers.map(({ id, name, avatar, description, rating }) => (
        <MyCaregiver key={id} id={id} name={name} avatar={avatar} description={description} rating={rating} />
      ));
    }
  } else if (loaded === null) {
    body = (
      <Paper className={paper} elevation={2}>
        <Typography color="error" className={sub_header}>
          Something went wrong, please refresh the page to try again
        </Typography>
      </Paper>
    );
  } else {
    body = (
      <Paper className={paper} elevation={2}>
        <Typography color="textSecondary" className={sub_header}>
          Finding your Caregivers
        </Typography>
        <CircularProgress />
      </Paper>
    );
  }
  return (
    <Box className={container}>
      <Box className={header_container}>
        <Typography color="secondary" variant="h4" className={header}>
          My Caregivers
        </Typography>
        <Link style={{ textDecoration: "none" }} to={DASHBOARD_URL}>
          <Button className={find_another_btn}>Find another Caregiver</Button>
        </Link>
      </Box>
      {body}
    </Box>
  );
}

export default MyCaregivers;
