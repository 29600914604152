import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import ProfileHead from "components/ProfileHead";
import CareRecipientDisplay from "components/CareRecipientDisplay";
import CareRecipientEdit from "components/CareRecipientEdit";
import { SCHEDULE_URL } from 'Constants'
import { LOCATION_ROUTE, ADD_LOCATION_ROUTE, EDIT_LOCATION_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    padding: theme.spacing(4, 2, 3),
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none"
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, "auto", 0),
      padding: theme.spacing(4, 5, 3)
    }
  }
}));

function CareRecipient(props) {
  const { update, history } = props;
  const { container } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [avatar, setAvatar] = useState(null);

  const isLovedOne = localStorage.getItem("loved_first_name") !== null;

  const handleUpdate = () => {
    setEdit(false);
    setAvatar(null);
    !isLovedOne && update();
  };

  useEffect(() => {
    let active = true;

    const body = new FormData();
    body.set("user_id", localStorage.getItem("id"));
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    fetch(LOCATION_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status !== "Fail" && active) {
          setLocations(
            res.data.Location.map(({ id, street_address, apartment_name, address_label, city, province, postal_code }) => ({
              id,
              street: street_address,
              apartment: apartment_name,
              name: address_label,
              city,
              province,
              zip: postal_code
            }))
          );
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Something went wrong, try refreshing the page", { variant: "error" });
      });

    return () => (active = false);
  }, [enqueueSnackbar]);

  const updateLocations = (newLocations, redirectOnSuccess) => {
    let promises = [];

    // Determine if we need to add or edit a location and make a promise out of it
    newLocations.forEach(newLocation => {
      const { id, street, apartment, city, province, zip, name } = newLocation;
      const body = new FormData();
      body.set("user_id", localStorage.getItem("id"));
      body.set("uid", localStorage.getItem("id"));
      body.set("auth_key", localStorage.getItem("authKey"));
      body.set("street_address", street);
      body.set("apartment_name", apartment);
      body.set("city", city);
      body.set("province", province);
      body.set("postal_code", zip);
      body.set("address_label", name);
      if (id === null) {
        // Location is not in database yet

        promises.push(fetch(ADD_LOCATION_ROUTE, { method: "POST", body }));
      } else {
        // Location is already in database
        // Compare location details to see if something changed
        const oldLocation = locations.find(location => location.id === id);
        if (Object.entries(oldLocation).some(([key, value]) => newLocation[key] !== value)) {
          body.set("id", id);
          promises.push(fetch(EDIT_LOCATION_ROUTE, { method: "POST", body }));
        }
      }
    });

    if (promises.length === 0) {
      return;
    }

    Promise.all(promises)
      .then(results => {
        promises = [];
        results.forEach(result => promises.push(result.json()));
        Promise.all(promises).then(results => {
          if (results.some(result => result.data.success === "Fail")) {
            enqueueSnackbar("Location update failed, please try again", { variant: "error" });
          } else {
            enqueueSnackbar("Location(s) updated", { variant: "success" });
            if (redirectOnSuccess && new URLSearchParams(window.location.search).get("rtsc")) {
              // rtsc = redirect to schedule care
              history.push(SCHEDULE_URL);
            }
            else {
              setLocations(newLocations);
            }
          }
        });
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Location update failed, please try again", { variant: "error" });
      });
  };

  const prefix = isLovedOne ? "loved_" : "";

  const storage = {};
  storage.birth = localStorage.getItem(prefix + "birth");
  storage.gender = localStorage.getItem(prefix + "gender");
  storage.language = localStorage.getItem("language");
  storage.phone = localStorage.getItem(prefix + "phone");
  storage.note = localStorage.getItem("note");

  const head = (
    <ProfileHead edit={edit} click={() => setEdit(!edit)} isLovedOne={isLovedOne} isRecipient={true} upload={setAvatar} />
  );

  const body = edit ? (
    <CareRecipientEdit
      {...storage}
      locations={locations}
      head={head}
      isLovedOne={isLovedOne}
      updateLocations={updateLocations}
      updateProfile={handleUpdate}
      avatar={avatar}
    />
  ) : (
      <Paper className={container} elevation={2}>
        {head}
        <CareRecipientDisplay {...storage} locations={locations} />
      </Paper>
    );

  return <Box paddingBottom={4}>{body}</Box>;
}

export default CareRecipient;
