/* Component for rendering the review tab in IndividualCaregiver */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CaretakerReview from "components/CaretakerReview";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "50px 1fr"
    }
  },
  empty_container: {
    display: "flex",
    height: "100px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: "300px"
    }
  }
}));

function CaregiverReviewTab(props) {
  const { reviews, name } = props;
  const { container, empty_container } = useStyles();

  return !reviews || reviews.length === 0 ? (
    <Box className={empty_container}>
      <Typography variant="h5" align="center" color="textSecondary">
        {name} has no reviews
      </Typography>
    </Box>
  ) : (
    <Box className={container}>
      {reviews.map(({ id, avatar, name, rating, feedback }) => (
        <CaretakerReview key={id} avatar={avatar} name={name} rating={rating} description={feedback} />
      ))}
    </Box>
  );
}

export default CaregiverReviewTab;
