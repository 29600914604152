/* Component for rendering chat box in the chat page */

import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { QB, CHATS_PER_FETCH, CAREGIVER_URL } from "Constants";
import throttle from "lodash/throttle";

const MAX_TRIES = 5; // Max # of attempts to try to refetch chat messages from QuickBlox before giving up
const MAX_INPUT_ROWS = 3;
const THROTTLE_DELAY = 500;

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateRows: "60px 1fr",
    gridGap: theme.spacing(2)
  },
  chatbox: {
    padding: theme.spacing(3, 2),
    display: "grid",
    gridTemplateRows: "1fr auto",
    gridGap: theme.spacing(2)
  },
  chat: {
    overflowY: "auto",
    maxHeight: "350px",
    display: "flex",
    flexDirection: "column-reverse",
    marginTop: "auto"
  },
  img: {
    width: "60px",
    height: "60px",
    margin: theme.spacing(0, 2)
  },
  input: {
    fontSize: "0.8rem",
    flexGrow: 1,
    borderRadius: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1.75, 1)
  },
  msg: {
    width: "fit-content",
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  my_msg: {
    borderRadius: theme.spacing(1, 0, 1, 1),
    alignSelf: "flex-end"
  },
  their_msg: {
    borderRadius: theme.spacing(0, 1, 1),
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main
  },
  text: {
    wordBreak: "break-word"
  },
  heart: {
    fontSize: "1rem",
    marginRight: theme.spacing(1)
  },
  full_heart: {
    color: "#D75A4A"
  },
  empty_heart: {
    cursor: "pointer"
  },
  btn: {
    color: theme.palette.bgcolor.main,
    backgroundColor: theme.palette.primary.main,
    marginTop: "auto",
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(10),
    padding: theme.spacing(1.5, 4),
    "&:hover": {
      color: theme.palette.bgcolor.main,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

function ChatBox(props) {
  const { id, data, messages, caregivers, send, setResults, favourite, read } = props;
  const {
    container,
    chatbox,
    chat,
    img,
    input,
    msg,
    my_msg,
    their_msg,
    text,
    heart,
    full_heart,
    empty_heart,
    btn
  } = useStyles();
  const { caregiverId, name, avatar, receiver_id } = data;

  const [hasMore, setHasMore] = useState(true);

  const bottomRef = useRef();
  const scrollRef = useRef();
  const inputRef = useRef();

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView();
  };

  const handleClick = () => {
    if (inputRef.current.value === "") {
      return;
    }
    const message = {
      type: "chat",
      body: inputRef.current.value,
      extension: {
        save_to_history: 1,
        dialog_id: id
      },
      markable: 1
    };
    // Send the message
    const messageId = QB.chat.send(receiver_id, message);
    // Update state
    send(id, messageId, inputRef.current.value);
    inputRef.current.value = "";
    scrollToBottom();
  };

  const handleScroll = (tries = 0) => {
    if (tries >= MAX_TRIES) {
      // Failed to get messages from QuickBlox after MAX_TRIES attempts
      setHasMore(true);
      return;
    }
    if (scrollRef.current.scrollTop === 0 && hasMore) {
      const params = { chat_dialog_id: id, sort_desc: "date_sent", limit: CHATS_PER_FETCH, skip: messages.length };

      QB.chat.message.list(params, (error, results) => {
        if (error) {
          console.log(error);
          setHasMore(false);
        } else if (!results || !results.items) {
          // 502 error
          handleScroll(tries + 1);
        } else {
          if (results.items.length !== 0) {
            setResults(id, results.items);
            setHasMore(true);
          }
          setHasMore(false);
        }
      });
    }
  };

  // Mark most recent message as read
  useEffect(() => {
    const first = messages[0];
    if (first && !first.isMyself && !first.read) {
      read(first.id);
      first.read = true;
    }
  }, [messages, read]);

  return (
    <Box className={container}>
      <Box display="flex">
        <Link to={`${CAREGIVER_URL}/${caregiverId}`} style={{ textDecoration: "none" }}>
          <Avatar src={avatar} className={img} />
        </Link>
        <Box>
          <Typography color="secondary" variant="h6">
            {name}
          </Typography>
          <Box display="flex" alignItems="center">
            {caregivers[caregiverId] ? (
              <FavoriteIcon style={{ color: "#D75A4A" }} className={`${heart} ${full_heart}`} />
            ) : (
                <FavoriteBorderIcon color="disabled" className={`${heart} ${empty_heart}`} onClick={favourite(caregiverId)} />
              )}
            <Typography color="textSecondary" variant="caption">
              {caregivers[caregiverId] ? "Already Added to Favourites" : "Add to Favourites"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Paper elevation={2} className={chatbox}>
        <Box className={chat} onScroll={throttle(() => handleScroll(), THROTTLE_DELAY)} ref={scrollRef}>
          <Box ref={bottomRef}></Box>
          {messages.map(({ id, message, isMyself }) => (
            <Paper key={id} className={`${msg} ${isMyself ? my_msg : their_msg}`} elevation={2}>
              <Typography className={text}>{message}</Typography>
            </Paper>
          ))}
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Input
            className={input}
            color="primary"
            inputRef={inputRef}
            multiline
            rowsMax={MAX_INPUT_ROWS}
            placeholder="Type Here..."
            disableUnderline
          />
          <Button className={btn} size="small" onClick={handleClick}>
            SEND
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ChatBox;
