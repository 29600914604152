/* Component for rendering the requested appointments in the my visits page */

import React from "react";
import Visit from "components/Visit";
import ViewDetailsButton from "components/ViewDetailsButton";
import { REQUESTED_STATUS } from "Constants";

function RequestedVisits(props) {
  const { data, view } = props;

  return data.map(({ appointment: { id, date, start, end } }) => (
    <Visit key={id} date={date} start={start} end={end} status="Requested">
      <ViewDetailsButton click={() => view(id, REQUESTED_STATUS)} />
    </Visit>
  ));
}

export default RequestedVisits;
