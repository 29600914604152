/* Component for rendering a single visit in the my visit page*/
import React from "react";
import { DAYS, MONTHS } from "Constants";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CalendarIcon from "assets/calendar-icon.svg";
import { getTimeString } from "Constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    textAlign: "center",
    gridGap: theme.spacing(2),
    padding: theme.spacing(3, 4, 4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gridGap: 0,
      justifyItems: "initial",
      textAlign: "left",
      gridTemplateColumns: "100px 1fr auto"
    }
  },
  icon: {
    color: theme.palette.primary.main,
    width: "33px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4)
    }
  },
  title: {
    fontSize: "1rem",
    fontWeight: 500
  }
}));

function Visit(props) {
  const { status, children } = props;
  const { container, title, icon } = useStyles();

  const date = new Date(props.date.replace(" ", "T"));
  const start = getTimeString(props.start.split(" ")[1]);
  const end = getTimeString(props.end.split(" ")[1]);

  return (
    <Paper className={container} elevation={2}>
      <img src={CalendarIcon} className={icon} alt="Date" />
      <Box>
        <Typography color="secondary" gutterBottom className={title}>
          {status}
        </Typography>
        <Typography color="textSecondary">
          {DAYS[date.getDay()]}, {MONTHS[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
        </Typography>
        <Typography color="textSecondary">
          {start} - {end}
        </Typography>
      </Box>
      {children}
    </Paper>
  );
}

export default Visit;
