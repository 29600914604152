/* Page for creating a QuickBlox account for a user.
   If there is a cgid url parameter then it will create an account for the caregiver
   with that id and create a dialog between user and caregiver  */

import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { QB, QB_ID, QB_KEY, QB_SECRET, QB_PASSWORD, CHAT_URL } from "Constants";
import { INDIVIDUAL_CAREGIVER_ROUTE, UPDATE_QB_ID_ROUTE, ADD_CHAT_ROUTE } from "Routes";

const useStyles = makeStyles(theme => ({ backdrop: { backgroundColor: theme.palette.bgcolor.main } }));

function AddQBUsers(props) {
  const { history } = props;
  const { backdrop } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const caregiverVidalId = new URLSearchParams(window.location.search).get("cgid");

  const [success, setSuccess] = useState(false);
  const [userId, setUserId] = useState(null);
  const [caregiverId, setCaregiverId] = useState(null);
  const [dialogId, setDialogId] = useState(null);

  const handleFail = useCallback(() => {
    enqueueSnackbar("Chat connection failed, please try again", { variant: "error" });
    history.goBack();
  }, [enqueueSnackbar, history]);

  const createAccount = useCallback(
    (login, setId) => {
      QB.init(QB_ID, QB_KEY, QB_SECRET);
      QB.createSession((err, res) => {
        if (err) {
          console.log(err);
          handleFail();
          return;
        }
        QB.users.create({ login, password: QB_PASSWORD }, (err, res) => {
          if (err) {
            // User already created
            if (err.code === 422) {
              QB.users.get({ login }, (err, user) => {
                if (err) {
                  handleFail();
                } else {
                  setId(user.id);
                }
              });
            } else {
              handleFail();
            }
          } else {
            setId(res.id);
          }
        });
      });
    },
    [handleFail]
  );

  const updateQBId = (id, qb_id, shouldRedirect = false) => {
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("id", id);
    body.set("qb_id", qb_id);

    fetch(UPDATE_QB_ID_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (shouldRedirect) {
          setSuccess(true);
        }
      })
      .catch(err => console.error(err));
  };

  // Create user
  useEffect(() => {
    createAccount(localStorage.getItem("email"), setUserId);
  }, [createAccount]);

  // Update user's qb id
  useEffect(() => {
    if (userId === null) {
      return;
    }
    updateQBId(localStorage.getItem("id"), userId, caregiverVidalId === null);
  }, [userId, caregiverVidalId]);

  // Create caregiver if there is a url parameter
  useEffect(() => {
    if (userId === null || caregiverVidalId === null) {
      return;
    }
    fetch(`${INDIVIDUAL_CAREGIVER_ROUTE}/${caregiverVidalId}`, {
      headers: { Uid: localStorage.getItem("id"), AuthKey: localStorage.getItem("authKey") }
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === "fail") {
          handleFail();
        } else {
          const { email, qb_id } = res.data.User;
          // caregiver already created
          if (Number(qb_id)) {
            setCaregiverId(Number(qb_id));
            return;
          }
          createAccount(email, setCaregiverId);
        }
      })
      .catch(err => {
        console.error(err);
        handleFail();
      });
  }, [userId, caregiverVidalId, createAccount, handleFail]);

  // Update caregiver's qb id
  useEffect(() => {
    if (caregiverId === null) {
      return;
    }
    updateQBId(caregiverVidalId, caregiverId);
  }, [caregiverId, caregiverVidalId]);

  // Create dialog between user and caregiver
  useEffect(() => {
    if (caregiverId === null) {
      return;
    }

    // Need to login first
    QB.login({ login: localStorage.getItem("email"), password: QB_PASSWORD }, function (err, result) {
      if (err) {
        console.log(err);
        handleFail();
        return;
      }
      QB.chat.dialog.create({ type: 3, occupants_ids: [caregiverId] }, (err, dialog) => {
        if (err) {
          console.log(caregiverId);
          console.log(err);
          handleFail();
          return;
        }
        setDialogId(dialog._id);
      });
    });
  }, [caregiverId, handleFail]);

  // Update dialog in database
  useEffect(() => {
    if (dialogId === null) {
      return;
    }
    const body = new FormData();
    body.set("uid", localStorage.getItem("id"));
    body.set("auth_key", localStorage.getItem("authKey"));
    body.set("user_id", localStorage.getItem("id"));
    body.set("doctor_id", caregiverVidalId);
    body.set("dialog_id", dialogId);
    body.set("qb_id", userId);

    fetch(ADD_CHAT_ROUTE, { method: "POST", body })
      .then(res => res.json())
      .then(res => {
        if (res.status === "Fail") {
          handleFail();
        }
        setSuccess(true);
      })
      .catch(err => {
        console.error(err);
        handleFail();
      });
  }, [dialogId, userId, caregiverVidalId, handleFail]);

  if (success) {
    return <Redirect to={CHAT_URL} />;
  }

  return (
    <Backdrop open={true} className={backdrop}>
      <CircularProgress size={50} />
    </Backdrop>
  );
}

export default AddQBUsers;
